<!-- <mat-dialog-content class="mat-typography"> -->
<div class="d-none d-sm-block">
    <div class="container">
        <div class="col-md-12">
            <div class="wp-outer" style="height: 450px;overflow-x: hidden;overflow:scroll">
                <h2 class="text-center instStuDetailsTitle">MIC Calendar Activity</h2>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row btmLine">
                            <div class="col-md-2">
                                <p class="anaTitle">Title</p>
                            </div>
                            <div class="col-md-10">
                                <p class="anaContent">{{activity?.title}}</p>
                            </div>
                        </div>
                        <div class="row btmLine">
                            <div class="col-md-2">
                                <p class="anaTitle">Description</p>
                            </div>
                            <div class="col-md-10">
                                <p class="anaContent">{{activity?.description}}</p>
                            </div>
                        </div>
                        <div class="row btmLine">
                            <div class="col-md-2">
                                <p class="anaTitle">Quarter</p>
                            </div>
                            <div class="col-md-10">
                                <p class="anaContent">{{activity?.quarter}}</p>
                            </div>
                        </div>
                        <div class="row btmLine">
                            <div class="col-md-2">
                                <p class="anaTitle">Basic Type</p>
                            </div>
                            <div class="col-md-10">
                                <p class="anaContent">{{activity?.basicType}}</p>
                            </div>
                        </div>
                        <div class="row btmLine">
                            <div class="col-md-2">
                                <p class="anaTitle">Duration </p>
                            </div>
                            <div class="col-md-10">
                                <p class="anaContent">{{activity?.startDate}} to {{activity?.endDate}}</p>
                            </div>
                        </div>
                        <div class="row btmLine" *ngIf="activity.attachmentPath">
                            <div class="col-md-2">
                                <p class="anaTitle">Activity Guide </p>
                            </div>
                            <div class="col-md-10">
                                <a href="{{baseUrl + activity?.attachmentPath}}" target="_blank">
                                    <button type="button" class="btn btn-success">Click Here</button>
                                </a>
                            </div>
                        </div>
                        <div mat-dialog-actions align="end" class="mt-3">
                            <button type="button" class="btn btn-danger instituteBtnClose" (click)="onNoClick()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </mat-dialog-content> -->