<section class="activityReportFormBox">
    <div class="container">
        <form [formGroup]="activityForm" (ngSubmit)="onSubmit(activityForm.value)">

            <div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <div class="portlet-title">
                        <div class="caption">
                            <span> Add New Self Driven Activity report </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="social">
                <mat-card>
                    <div class="overview">
                        <b>
                            <h3 style="color:#E26A6A;margin-bottom:10px;">Promotion in Social Media</h3>
                        </b>
                        <h6 style="color:green">Promotion in any one social media.</h6>
                        <br>
                    </div>
                    <table>
                        <!-- <thead>
        <tr>
            <th  style="text-align: center;"></th>
            <th   style="text-align: center;">Social Media</th>
            <th style="text-align: center;" >Url</th>
        </tr>
            </thead> -->
                        <thead class="semesterTable">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Social Media</th>
                                <th scope="col">Url</th>

                                <!-- <th scope="col">Edit</th> -->
                                <!-- <th scope="col">Delete</th> -->
                            </tr>
                        </thead>

                        <tr>
                            <td align="center">
                                <!-- <input type="checkbox" name="twitter_checkbox"> -->
                                <mat-checkbox name="twitter_checkox" [value]=1 formControlName="twitter_checkbox"
                                    (change)="checkSocial($event,'twitter')"></mat-checkbox>
                            </td>
                            <td align="center">Twitter</td>

                            <td align="center">
                                <input matInput type="text" [readonly]="showTwitterURL" formControlName="twitter_url"
                                    placeholder="Twitter URL" name="twitter_url" style="width: 399px;">
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <!-- <input type="checkbox" name="socialmedia2"> -->
                                <mat-checkbox name="fb_checkox" formControlName="fb_checkbox" [value]=2
                                    (change)="checkSocial($event,'facebook')"></mat-checkbox>
                            </td>
                            <td align="center">Facebook</td>
                            <td align="center">
                                <input matInput type="text" [readonly]="showFacebookURL" formControlName="fb_url"
                                    placeholder="Facebook URL" name="fb_url" style="width: 399px;">
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <mat-checkbox formControlName="instagram_checkbox" name="instagram_checkbox" [value]=3
                                    (change)="checkSocial($event,'instagram')"></mat-checkbox>
                            </td>
                            <td align="center">Instagram</td>

                            <td align="center">
                                <input matInput type="text" [readonly]="showInstaURL" formControlName="instagram_url"
                                    placeholder="Instagram URL" name="instagram_url" style="width: 399px;">
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                <mat-checkbox formControlName="linkedIn_checkbox" name="linkedIn_checkbox" [value]=4
                                    (change)="checkSocial($event,'linkedIn')"></mat-checkbox>
                            </td>
                            <td align="center">LinkedIn</td>

                            <td align="center">
                                <input matInput type="text" [readonly]="showLinkedInURL" formControlName="linkedin_url"
                                    placeholder="LinkedIn URL" name="linkedin_url" style="width: 399px;">
                            </td>
                        </tr>
                    </table>
                </mat-card>
            </div>
            <div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="row">
                            <!-- <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <mat-select placeholder="Academic Year" formControlName="academic" required>    -->
                                        <!-- <mat-option value="2019-20">2019-20</mat-option> -->
									<!--	<mat-option value="2020-21" selected >2020-21</mat-option>
                                        <mat-option value="2021-22" selected >2021-22</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="activityForm.controls['academic'].hasError('required')">
                                        Academic Year is required</mat-error>
                                </mat-form-field>
                            </div> -->

                            <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <mat-label>Academic Year</mat-label>
                                    <mat-select formControlName="academic" disabled>
                                        <mat-option>Select</mat-option>
                                        <!-- <mat-option value="2020-21">2020-21</mat-option> -->
                                        <!-- <mat-option value="2022-23">2022-23</mat-option> -->
                                        <mat-option value="2023-24">2023-24</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <mat-label>Program driven by</mat-label>
                                    <mat-select formControlName="program" disabled>
                                        <mat-option>Select</mat-option>
                                        <mat-option value="3">Self-driven Activity</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">

                                <mat-form-field style="width: 400px;">
                                    <mat-select placeholder="Quarter" formControlName="quater" required>
                                        <mat-option value="Quarter I">Quarter I</mat-option>
                                        <mat-option value="Quarter II">Quarter II</mat-option>
                                        <mat-option value="Quarter III">Quarter III</mat-option>
                                        <mat-option value="Quarter IV">Quarter IV</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="activityForm.controls['quater'].hasError('required')">Quater is
                                        required</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <mat-label>Program/Activity Name</mat-label>
                                    <!-- <mat-select formControlName="program_name">
                                <mat-option></mat-option>
                            </mat-select> -->

                                    <input type="type" matInput formControlName="program_name"
                                        placeholder="Program Name" required>

                                    <mat-error *ngIf="activityForm.controls['program_name'].hasError('required')">
                                        Program name is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-6">
                                <mat-form-field style="width: 400px;" class="wp-tip-outer">
                                    <div class="wp-tooltip">
                                        <button type="button" class="btn btn-secondary btn-tooltip" data-bs-toggle="tooltip" data-bs-placement="top">
                                       <!--  <i class="fa fa-info-circle" aria-hidden="true"></i> -->
                                       <i class="fa fa-info" aria-hidden="true"></i>
                                        </button>
                                        <div class="wp-toolDesc">
                                            <p>
                    <span><strong>Level 1:</strong> Expert Talks/ Mentoring Sessions/Exposure Visits</span>

                    <span><strong>Duration of the activity:</strong> 2 to 4 contact hours/ Less than half a day</span>

                    <span><strong>Level 2:</strong> Workshop/ Seminar/Conference/ Exposure Visits</span>

                    <span><strong>Duration of the activity:</strong> 5 to 8 contact hours/Less than a day</span>

                    <span><strong>Level 3:</strong> Workshop/ Boot camps/Exhibitions/Demo Day / Competitions</span>

                    <span><strong>Duration of the activity:</strong> 9 to18 contact hours: 1 -2 days</span>

                    <span><strong>Level 4:</strong> Tech Fest/Challenges/Hackathons</span>

                    <span><strong>Duration of the activity:</strong> Greater than 18 contact hours: More than 2 days</span>
                </p>
                <div class="wp-sq"></div>
                                        </div>
                                    </div>                                    
                                    <mat-label>Program Type*</mat-label>
                                    <mat-select placeholder="Program Type*" (ngModelChange)="validateOtherField($event,'program_type')"
                                        formControlName="program_type">
                                        <mat-option>Select</mat-option>
                                        <mat-option value="{{activity}}" *ngFor="let activity of programType">
                                            {{activity}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="activityForm.controls['program_type'].hasError('required')">
                                        Program Type is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <!-- <mat-form-field class="example-full-width"> -->
                                    <mat-label>Other</mat-label>
                                    <input matInput formControlName="program_other" [readonly]="program_type_other_flg"
                                        placeholder="Other Program Type">
                                    <mat-error *ngIf="activityForm.controls['program_other'].hasError('required')">Other
                                        program is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <mat-label>Program Theme *</mat-label>
                                    <mat-select  placeholder="Program Theme"
                                        formControlName="program_theme">
                                        <mat-option value="{{theme}}" *ngFor="let theme of programTheme">{{theme}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="activityForm.controls['program_theme'].hasError('required')">
                                        program theme is
                                        required</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field style="width: 400px;">


                                    <!-- <mat-form-field class="example-full-width"> -->
                                    <mat-label>Duration of the activity (in hours)*</mat-label>
                                    <input type="number" matInput formControlName="activity_duration"
                                       placeholder="Duration of the activity (in hours)" required>
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                    <mat-error *ngIf="activityForm.controls['activity_duration'].hasError('required')">
                                        Duration of the activity (in hours) is
                                        required</mat-error>
                                </mat-form-field>



                            </div>
                        </div>



                        <div class="row">
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Start Date</mat-label>
                                    <input matInput readonly [matDatepicker]="picker1" placeholder="Start Date"
                                        formControlName="start_date" [(ngModel)]="start_date" required>
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>End Date</mat-label>
                                    <input matInput readonly [min]="start_date" [matDatepicker]="picker2"
                                        placeholder="End Date" formControlName="end_date" required>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <!-- <mat-error
                *ngIf="declareSemesterForm.controls['duration_semester_first_to'].hasError('required')">
                End Date is
                required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>




                        <div class="row">
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Number of Student Participants (Minimum 40 Students)</mat-label>
                                    <input type="number" matInput formControlName="student_participants"
                                        placeholder="Member" min="40" required>
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Number of Faculty Participants</mat-label>
                                    <input type="number" matInput formControlName="faculty_participants"
                                        placeholder="Member" required>
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Number of External Participants, If any</mat-label>
                                    <input type="number" matInput formControlName="external_participants"
                                        placeholder="Member">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>


                            <div class="col-6">

                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Expenditure Amount, If any</mat-label>
                                    <input type="number" matInput formControlName="expenditure_amount"
                                        placeholder="Member">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>

                            <!-- <div class="col-6">
                        <mat-form-field class="example-full-width" style="width: 400px;">
                            <mat-label>Expenditure Amount, If any</mat-label>
                            <input type="number" formControlName="expenditure_amount" placeholder="Expenditure Amount">
                        </mat-form-field>
                    </div> -->
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Remark</mat-label>
                                    <input matInput formControlName="remark" placeholder="Remark">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Mode of Session delivery*</mat-label>
                                    <mat-select formControlName="session">
                                        <mat-option value="">Select</mat-option>
                                        <mat-option value="online">Online</mat-option>
                                        <mat-option value="offline">Offline</mat-option>
                                        <!-- <mat-option value="{{activity}}" *ngFor="let activity of programType">{{activity}}</mat-option> -->
                                    </mat-select>
                                    <!-- <mat-error *ngIf="activityForm.controls['session'].hasError('required')">Program Type is
                                required</mat-error> -->
                                    <mat-error *ngIf="activityForm.controls.session.errors?.required">Mode of Session delivery is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>



                    </mat-card>
                </div>
            </div>


            <div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Overview</h3>
                        </div>
                        <div class="row">

                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Objective *</mat-label>
                                    <input matInput formControlName="objective" maxlength="100" placeholder="Objective">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                    <span style="font-size: 10px">{{100 - activityForm.controls.objective.value.length}}
                                        of 100 left</span>
                                    <mat-error *ngIf="activityForm.controls.objective.errors?.maxLength">Max characters
                                        100
                                    </mat-error>
                                    <!-- <mat-error *ngIf="activityForm.controls['objective'].hasError('maxLength')">Max characters 100</mat-error> -->
                                    <mat-error *ngIf="activityForm.controls.objective.errors?.required">Overview is required</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Benefit in terms of learning/Skill/Knowledge obtained* </mat-label>
                                    <input matInput formControlName="benefit_learning" maxlength="150"
                                        placeholder="Benefit in terms of learning/Skill/Knowledge obtained*">
                                    <span
                                        style="font-size: 10px">{{150 - activityForm.controls.benefit_learning.value.length}}
                                        of 150 left</span>
                                    <mat-error *ngIf="activityForm.controls['benefit_learning'].hasError('maxLength')">
                                        Max characters 150</mat-error>
                                    <mat-error *ngIf="activityForm.controls['benefit_learning'].hasError('required')">
                                        Benefit in term field is required</mat-error>
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>
                        </div>

                    </mat-card>
                </div>
            </div>


            <!-- <div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Star Performer</h3>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <mat-label>Faculty Name</mat-label>
                                    <mat-select formControlName="facultyMember">
                                        <mat-option>Select</mat-option>
                                        <mat-option value="{{faculty.key}}"
                                            *ngFor="let faculty of facultyList | keyvalue">{{faculty.value}}
                                        </mat-option>   -->
                                        <!-- <mat-option *ngFor="let type of Type" [value]="type">
            {{type}}
        </mat-option> [(ngModel)]="TypeModel"-->
                            <!--        </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field style="width: 400px;">
                                    <mat-label>Student Name</mat-label>
                                    <mat-select formControlName="studentMember">
                                        <mat-option>select</mat-option>
                                        <mat-option value="{{student.key}}"
                                            *ngFor="let student of studentList | keyvalue">{{student.value}}
                                        </mat-option>   -->
                                        <!-- <mat-option *ngFor="let type of Type" [value]="type">
            {{type}}
        </mat-option> -->
                            <!--        </mat-select>
                                </mat-form-field>
                            </div>
                        </div>




                    </mat-card>
                </div>
            </div> -->




            <div class="row dashboardChartRowMargin">
                <div class="col-md-12">
                    <mat-card>
                        <div class="overview">
                            <h3>Attachments</h3>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <!-- <mat-form-field class="col-lg-6 col-sm-12"> -->
                                <mat-form-field class="example-full-width" style="width: 400px;">
                                    <mat-label>Video Url </mat-label>
                                    <input matInput formControlName="video" placeholder="Video Url">
                                    <!-- <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error> -->
                                </mat-form-field>
                            </div>


                            <div class="col-6">
                                <div class="col-lg-8 col-md-10">
                                    <label>Photograph1 <span *ngIf="action != 'edit'" style="color:red">*</span></label>
                                    <mat-error>image(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-4 col-md-2 float-right">
                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="!activityForm.get('photograph1').value">Choose file</label>
                                    <label class="custom-file-label float-right" for="customFile"
                                        *ngIf="activityForm.get('photograph1').value">
                                        Image Selected</label>

                                    <input #photograph1Ref type="file" accept="image/*" name="photograph1"
                                        formControlName="photograph1" (change)="onFileChange($event,'photograph1')"
                                        class="custom-file-input float-right">

                                    <!-- <mat-error *ngIf="activityForm.controls['photograph1'].hasError('required')">Photograph is required</mat-error> -->
                                    <a *ngIf="action == 'edit' && allUrls.photog1 != ''" target="_blank"
                                        href="{{allUrls.photog1}}">Preview</a>
                                </div>
                            </div>


                        </div>
                        <div class="row">



                            <div class="col-6">
                                <div class="col-lg-8 col-md-10">
                                    <label>Photograph2 </label>
                                    <mat-error>image(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-4 col-md-2 float-right">
                                    <label class="custom-file-label float-right"
                                        *ngIf="!activityForm.get('photograph2').value" for="customFile">Choose
                                        file</label>
                                    <label class="custom-file-label float-right"
                                        *ngIf="activityForm.get('photograph2').value"
                                        for="customFile">Image Selected</label>
                                    <input #photograph2Ref type="file" accept="image/jpg, image/jpeg, image/png" name="photograph2"
                                        formControlName="photograph2" (change)="onFileChange($event,'photograph2')"
                                        class="custom-file-input float-right">
                                    <a *ngIf="action == 'edit' && allUrls.photograph2 != ''" target="_blank"
                                        href="{{allUrls.photograph2}}">Preview</a>
                                </div>
                            </div>


                            <div class="col-6">
                                <div class="col-lg-8 col-md-10">
                                    <label> Overall report of the activity*
                                    </label>
                                    <mat-error>pdf(max 2MB)</mat-error>
                                </div>

                                <div class="col-lg-4 col-md-2 float-right">
                                    <label class="custom-file-label float-right"
                                        *ngIf="!activityForm.get('brochure').value" for="customFile">Choose file</label>
                                    <label class="custom-file-label float-right"
                                        *ngIf="activityForm.get('brochure').value"
                                        for="customFile">File Selected</label>
                                    <input #brochureRef type="file" accept="application/pdf" name="brochure"
                                        formControlName="brochure" (change)="onFileChange($event,'brochure')"
                                        class="custom-file-input float-right">
                                    <a *ngIf="action == 'edit' && allUrls.brochure != ''" target="_blank"
                                        href="{{allUrls.brochure}}">Preview</a>
                                </div>
                            </div>

                        </div>

                    </mat-card>
                    <mat-dialog-actions align="end" class="reportFormActivityButton">
                        <button mat-raised-button type="submit" class="btn btn-success">Submit</button>
                        <!-- <button mat-button mat-dialog-close>Close</button> -->

                    </mat-dialog-actions>
                </div>
            </div>




        </form>

<p style="color: crimson;">Note: To View/Preview the latest uploaded file, click the Submit button</p>

    </div>
</section>
