<div class="container">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="newsForm" (ngSubmit)="onSubmit(newsForm.value)">

      <div class="row">
        <div fxFlex="50" class="m-2">
          <mat-form-field appearance="outline">
            <mat-label>Activity Type</mat-label>
            <mat-select formControlName="activity_type" placeholder="Activity Type" required>
              <mat-option>Select</mat-option>
              <mat-option value="{{activity}}" *ngFor="let activity of activityType">{{activity}}</mat-option>
            </mat-select>
            <mat-error *ngIf="newsForm.controls['activity_type'].hasError('required')">Activity Type is required
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2" *ngIf="!showDiv1">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Added to Calendar</mat-label>
            <mat-select formControlName="added_calendar" placeholder="Added to Calendar" required>
              <mat-option>Select</mat-option>
              <mat-option value="{{addedCalender?.value}}" *ngFor="let addedCalender of addedCalenderList">
                {{addedCalender?.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="newsForm.controls['added_calendar'].hasError('required')">Added to Calendar is required
            </mat-error>

          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field color="primary">
            <mat-label>Date from</mat-label>
            <input matInput formControlName="fromDate" [matDatepicker]="picker1" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field color="primary">
            <mat-label>Date to</mat-label>
            <input matInput formControlName="toDate" [min]="minDate" [matDatepicker]="picker2" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field appearance="outline">
            <mat-label>Expected No. of participants</mat-label>
            <input type="number" matInput formControlName="no_of_participant" placeholder="Expected No. of participants"
              required>
            <mat-error *ngIf="newsForm.controls['no_of_participant'].hasError('required')">Expected No. of
              participants
              is required
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>URL of the Event</mat-label>
            <input matInput formControlName="event_url" placeholder="URL of the Event" required>
            <mat-error *ngIf="newsForm.controls['event_url'].hasError('required')">URL of the Event is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field appearance="outline">
            <mat-label>Participants</mat-label>
            <mat-select formControlName="participants" placeholder="Participants" required>
              <mat-option>Select</mat-option>
              <mat-option value="Student">Student</mat-option>
              <mat-option value="Faculty">Faculty</mat-option>
              <mat-option value="Both">Both</mat-option>
            </mat-select>
            <mat-error *ngIf="newsForm.controls['participants'].hasError('required')">Participants are required
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Activity Title</mat-label>
            <input matInput formControlName="event_title" placeholder="Activity title" required>
            <mat-error *ngIf="newsForm.controls['event_title'].hasError('required')">Title is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field appearance="outline">
            <mat-label>Activity Theme</mat-label>
            <mat-select formControlName="event_theme" placeholder="Activity Theme" required>
              <mat-option>Select</mat-option>
              <mat-option value="IPR">IPR</mat-option>
              <mat-option value="Innovation">Innovation</mat-option>
              <mat-option value="Startup">Startup</mat-option>
            </mat-select>
            <mat-error *ngIf="newsForm.controls['event_theme'].hasError('required')">Activity Theme is required
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50" class="m-2" *ngIf="showDiv">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Target Audience</mat-label>
            <input matInput formControlName="Target_Aud" placeholder="Target Audience" required>
            <mat-error *ngIf="newsForm.controls['Target_Aud'].hasError('required')">Target Audience is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="showDiv">
        <div class="w-100 m-2">
          <mat-form-field style="width: 330px !important">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Description" formControlName="description" required></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="showDiv">
        <div fxFlex="50" class="m-2">
          <div class="col-lg-8 col-md-10">
            <label>Program brief/Brochure/Banner:</label>
            <mat-error>PDF(max 2MB)</mat-error>
          </div>
          <br />
          <div class="col-lg-4 col-md-2 float-left" *ngIf="!s">
            <label class="custom-file-label float-right" for="customFile" *ngIf="!bannerUpload_Logo">Choose file</label>
            <label class="custom-file-label float-right" for="customFile"
              *ngIf="bannerUpload_Logo">{{bannerUpload_Logo[0]['name']}}</label>
            <input #bannerUpload_LogoRef type="file" accept="application/pdf" name="logoAttachmentsubfile"
              (change)="( bannerUpload_Logo = bannerUpload_LogoRef.files )" class="custom-file-input float-right"
              required>
          </div>

          <button *ngIf="s" (click)="s=!s" mat-raised-button class="btn btn-success mat-elevation-z6 mt-3 float-right" color="primary">Reupload File</button>

        </div>




        <!-- <div class="CardBoxContent">
          <a href="{{baseUrl + faculty?.quarterly_plan}}" target="_blank">
            <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG">Download Quaterely Plan</button>
          </a>
        </div> -->



        <div *ngIf="s">
          <a href="{{baseUrl + this.brochure}}" style="vertical-align: -webkit-baseline-middle;
        float: left;font-size:14px;" target="_blank">
            <font color="#b13c52"><b><u>Preview</u></b></font>
          </a>
        </div>
      </div>


      <button mat-raised-button type="button" class="btn btn-danger instituteBtnClose mat-elevation-z6 mt-3"
        color='warn' (click)='onNoClick()'>Close</button>
      <button mat-raised-button class="btn btn-success mat-elevation-z6 mt-3 float-right" color="primary"
        [disabled]='newsForm.invalid'>Submit</button>
    </form>
  </mat-dialog-content>
</div>
