import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstituteMyCouncilService {
  private baseUrl = myGlobals.baseUrl;

  toggle = new BehaviorSubject<boolean>(false);
  menuToggle = this.toggle.asObservable();

  dataUpdated = new BehaviorSubject<boolean>(false);
  updateData = this.dataUpdated.asObservable();

  constructor(private http: HttpClient) { }

  updateToggle(flag) {
    this.toggle.next(flag);
  }

  checkRole() {
    return this.http.get(`${this.baseUrl}/checkRole`);
  }

  getInstituteDetails() {
    return this.http.get(`${this.baseUrl}/get-institute-details`);
  }

  getFaculties(per_page = 10, pageNo) {
    return this.http.get(
      `${this.baseUrl}/faculty/details?per_page=` + per_page + `&page=` + pageNo
    );
  }

  getFacultiesRoles() {
    return this.http.get(`${this.baseUrl}/faculty-role-details`);
  }

  submitFaculties(data) {
    return this.http.post(`${this.baseUrl}/faculty/submit`, data);
  }

  updateFaculty(data) {
    return this.http.post(`${this.baseUrl}/faculty/update/` + data, data);
  }

  EditFaculty(data) {
    return this.http.post(`${this.baseUrl}/editnominee/` + data, data);
  }

  deleteFaculty(data) {
    return this.http.post(`${this.baseUrl}/faculty/removeMember/` + data, data);
  }

  getRoles() {
    return this.http.get(`${this.baseUrl}/getRoles`);
  }

  getCouncil(pageNo) {
    return this.http.get(
      `${this.baseUrl}/council/details?per_page=10&page=` + pageNo
    );
  }

  submitCouncil(data) {
    return this.http.post(`${this.baseUrl}/council/submit`, data);
  }

  deleteCouncil(data) {
    return this.http.post(
      `${this.baseUrl}/council/removeCouncil/` + data,
      data
    );
  }

  getSocial(pageNo) {
    return this.http.get(
      `${this.baseUrl}/social/details?per_page=10&page=` + pageNo
    );
  }

  updateSocial(data) {
    return this.http.post(`${this.baseUrl}/social/update`, data);
  }

  submitSocial(data) {
    return this.http.post(`${this.baseUrl}/social/submit`, data);
  }

  getHOI(pageNo) {
    return this.http.get(`${this.baseUrl}/get-hoi-details` + pageNo);
  }

  updateHOI(data) {
    return this.http.post(`${this.baseUrl}/update-hoi-details`, data);
  }

  getPresident(pageNo) {
    return this.http.get(`${this.baseUrl}/get-president-details` + pageNo);
  }

  updatePresident(data) {
    return this.http.post(`${this.baseUrl}/update-president-details`, data);
  }

  getExternal(per_page = 10, pageNo) {
    return this.http.get(
      `${this.baseUrl}/faculty/external_members?per_page=` + per_page + `&page=` + pageNo
    );
  }

  submitExternal(data) {
    return this.http.post(
      `${this.baseUrl}/faculty/externalMembersSubmit`,
      data
    );
  }

  deleteExternal(data) {
    return this.http.post(
      `${this.baseUrl}/faculty/removeExternalMembers/` + data,
      data
    );
  }

  getStudents(per_page = 10, pageNo) {
    return this.http.get(
      `${this.baseUrl}/students/details?per_page=` + per_page + `&page=` + pageNo
    );
  }
  deleteStudents(data) {
    return this.http.post(
      `${this.baseUrl}/students/removeStudent/` + data,
      data
    );
  }

  updateStudent(data) {
    return this.http.post(`${this.baseUrl}/students/update/` + data, data);
  }

  updateExternal(data, id) {
    return this.http.post(
      `${this.baseUrl}/faculty/externalMembersUpdate/` + id,
      data
    );
  }
  updateDeclareSemester(data) {
    return this.http.post(
      `${this.baseUrl}/declareSemestars/update/` + data,
      data
    );
  }

  updateCouncil(data, id) {
    return this.http.post(`${this.baseUrl}/council/update/` + id, data);
  }

  getDeclareSemesters(pageNo) {
    return this.http.get(
      `${this.baseUrl}/declareSemestars/details?per_page=10&page=` + pageNo
    );
  }

  deleteDeclareSemesters(data) {
    return this.http.post(
      `${this.baseUrl}/declareSemestars/removeDeclareSemestars/` + data,
      data
    );
  }

  submitDeclareSemesters(data) {
    return this.http.post(`${this.baseUrl}/declareSemestars/submit`, data);
  }

  addStudent(data) {
    return this.http.post(`${this.baseUrl}/students/submit`, data);
  }

  instituteDetailsDropdown() {
    return this.http.get(`${this.baseUrl}/institute-details-dropdown`);
  }

  submitInstituteDetails(data) {
    return this.http.post(`${this.baseUrl}/submit-institute-details`, data);
  }

  getInstituteRating() {
    return this.http.get(`${this.baseUrl}/get-rating`);
  }
  getInstituteRating1920() {
    return this.http.get(`${this.baseUrl}/certificate-rating-status-1920`);
  }



  getCurrentAssociation() {
    return this.http.get(`${this.baseUrl}/students/studentCurrenAssociation`);
  }

  searchStudent(data) {
    return this.http.get(
      `${this.baseUrl}/students/details?per_page=10&page=1&filter=` + data
    );
  }
  searchExternal(data) {
    return this.http.get(
      `${this.baseUrl}/faculty/external_members?per_page=10&page=1&filter=` + data
    );
  }

  searchFaculty(data) {
    return this.http.get(
      `${this.baseUrl}/faculty/details?per_page=10&page=1&filter=` + data
    );
  }

  getInfoDetails() {
    return this.http.get(
      `${this.baseUrl}/getUserLogDetails?per_page=10&page=1`
    );
  }

  getEstablismentCertificate() {
    return this.http.get(`${this.baseUrl}/certificate-establisment/details`, {
      responseType: 'arraybuffer',
    });
  }

  getEstablismentCertificate2() {
    return this.http.get(`${this.baseUrl}/certificate-establisment/details2`, {
      responseType: 'arraybuffer',
    });
  }

  getRatingCertificate() {
    return this.http.get(`${this.baseUrl}/certificate-rating/details`, {
      responseType: 'arraybuffer',
    });
  }

  getRatingCertificate1920() {
    return this.http.get(`${this.baseUrl}/certificate-rating-19-20/details`, {
      responseType: 'arraybuffer',
    });
  }

  getRatingCertificate2021(data) {
    return this.http.get(`${this.baseUrl}/certificate-rating-20-21?year=`+data, {
      responseType: 'arraybuffer',
    });
  }

  getLetterofAppriciation2122(data) {
    return this.http.get(`${this.baseUrl}/getletterofappriciation-21-22?year=`+data, {
      responseType: 'arraybuffer',
    });
  }
  
  getLetterofAppriciation2223(data) {
    return this.http.get(`${this.baseUrl}/getletterofappriciation-22-23?year=`+data, {
      responseType: 'arraybuffer',
    });
  }


  getRewardPoints() {
    return this.http.get(`${this.baseUrl}/instituteRewardPoint`);
  }

  getRewardPoints2019_20() {
    return this.http.get(`${this.baseUrl}/instituteRewardPoint201920?type=prev`);
  }

  getRewardPointsCurrent() {
    return this.http.get(`${this.baseUrl}/instituteRewardPoint201920?type=current`);
  }

  getRewardPointsCurrentAyear(year) {
    return this.http.get(`${this.baseUrl}/instituteRewardPointAyear?year=`+year);
  }

  resetPassword(data) {
    return this.http.post(`${this.baseUrl}/reset-password`, data);
  }

  checkMyCouncil() {
    return this.http.get(`${this.baseUrl}/checkMyCouncil`);
  }

  checkPerformance201819() {
    return this.http.get(`${this.baseUrl}/check201819data`);
  }


  checkPerformance201920() {
    return this.http.get(`${this.baseUrl}/check201920data`);
  }

  checkRatingStatus() {
    return this.http.get(`${this.baseUrl}/certificate-rating-status`);
  }

  getRoleStudent(data) {
    return this.http.get(`${this.baseUrl}/getStudentRoles/` + data, data);
  }

  getRoleFaculty(data) {
    return this.http.get(`${this.baseUrl}/getFacultyRoles/` + data, data);
  }
  
  getCoordinatorRoles() {
    return this.http.get(`${this.baseUrl}/getCoordinatorRoles`);
  }
  getRoleCoordinator(data) {
    return this.http.get(`${this.baseUrl}/getNominationRoles/` + data, data);
  }

  getCertificateList() {
    return this.http.get(`${this.baseUrl}/getMembers1819Details`);
  }


  getmemberCertificate(data){
    return this.http.get(`${this.baseUrl}/member-certificate/details/`+ data,
    {
      responseType: 'arraybuffer',
    });


  }

  getQRCode(){
    return this.http.get(`${this.baseUrl}/getResionWisewhatsAppCode`);

  }


  MakeVisibleDropdown(){
    return this.http.get(`${this.baseUrl}/checkIncubationInnovationFacilities`);

  }

  // getRatingCertificate1920() {
  //   return this.http.get(`${this.baseUrl}/certificate-rating-19-20/details`, {
  //     responseType: 'arraybuffer',
  //   });
  // }
  getRating(){
    return this.http.get(`${this.baseUrl}/get-rating`);
  }

  getAllActivity(){
    return this.http.get(`${this.baseUrl}/activity/allReport1920`);
  }

  getInstituteMenteeStatus() {
    return this.http.get(`${this.baseUrl}/get-mentee-institute-status`);
  }

  getInstituteMentorMembers() {
    return this.http.get(`${this.baseUrl}/get-mentor-members-list`);
  }

  getInstituteMembersData(id) {
    return this.http.get(`${this.baseUrl}/get-mentor-members-data?id=`+id);
  }

  submitStudentPresident(data) {
    return this.http.post(`${this.baseUrl}/students/studentPresidentSubmit`,data);
  }

  submitConsentStatus(data) {
    return this.http.post(`${this.baseUrl}/update-granted-status`,data);
  }
  
  addPresidentStudent(data) {
    return this.http.post(`${this.baseUrl}/students/add-student-president`,data);
  }

}
