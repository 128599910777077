<div class="container-fluid">
  <div *ngIf="application_status=='0' && app_status==0">
    <mat-card style="
  margin-right: 33px;
  margin-left: 20px;
  margin-bottom: 50px;">
      Thank you for registering for the Institution's Innovation Council of MoE’s Innovation Cell.
      Update Institute/President details under My Council Menu to proceed further.
      Based on the institute details you have submitted the application will be reviewed by IIC Zonal In-charge.
      All other tabs under My Council will be enabled for the approved institute.
    </mat-card>
  </div>

  <div *ngIf="application_status=='0' && app_status==2">
    <mat-card style="
  margin-right: 33px;
  margin-left: 20px;
  margin-bottom: 50px; color: #FF0000;">
      Your institute registration to establish Institution's Innovation Council is “Disapproved”.  Kindly contact your zonal in charge for further process.  Zonal in charges details are available at <a href="https://iic.mic.gov.in/institute/contact-us">https://iic.mic.gov.in/institute/contact-us</a>.
    </mat-card>
  </div>


  <mat-tab-group mat-align-tabs="center">

    <mat-tab>
      <ng-template mat-tab-label>
        Institute Details
        <mat-icon *ngIf="data?.institutesDetailsStatus == '1'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="data?.institutesDetailsStatus == '0'" style="color: red">cancel</mat-icon>
      </ng-template>
      <app-institute-details></app-institute-details>
    </mat-tab>

    <mat-tab [disabled]="data?.applicationStatus=='0'">
      <ng-template mat-tab-label>
        Council Meeting
        <mat-icon *ngIf="data?.checkCouncilMeeting1 == '1'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="data?.checkCouncilMeeting1 == '0'" style="color: red">cancel</mat-icon>
      </ng-template>
      <app-institute-council-meeting></app-institute-council-meeting>
    </mat-tab>

    <mat-tab [disabled]="data?.applicationStatus=='0'">
      <ng-template mat-tab-label>
        Add Teaching/Non teaching Members
        <mat-icon *ngIf="data?.facultyStatus == '1'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="data?.facultyStatus == '0'" style="color: red">cancel</mat-icon>
      </ng-template>
      <app-institute-faculty></app-institute-faculty>
    </mat-tab>

    <mat-tab [disabled]="data?.applicationStatus=='0'">
      <ng-template mat-tab-label>
        Student Members
        <mat-icon *ngIf="data?.studentStatus == '1'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="data?.studentStatus == '0'" style="color: red">cancel</mat-icon>
      </ng-template>
      <app-institute-student></app-institute-student>
    </mat-tab>

    <mat-tab [disabled]="data?.applicationStatus=='0'">
      <ng-template mat-tab-label>
        External Member
        <mat-icon *ngIf="data?.externalMembersStatus == '1'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="data?.externalMembersStatus == '0'" style="color: red">cancel</mat-icon>
      </ng-template>
      <app-institute-external></app-institute-external>
    </mat-tab>

    

    <!-- <mat-tab [disabled]="data?.applicationStatus=='0'">
      <ng-template mat-tab-label>
        Declare Semesters
        <mat-icon *ngIf="data?.checkCalendarSemesters1 == '1'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="data?.checkCalendarSemesters1 == '0'" style="color: red">cancel</mat-icon>
      </ng-template>
      <app-institute-declare-semesters></app-institute-declare-semesters>
    </mat-tab> -->

    <mat-tab [disabled]="data?.applicationStatus=='0'">
      <ng-template mat-tab-label>
        Social Media
        <!-- <mat-icon *ngIf="data?.socialMediaStatus1 == '1'" style="color: green">check_circle</mat-icon>
        <mat-icon *ngIf="data?.socialMediaStatus1 == '0'" style="color: red">cancel</mat-icon> -->
      </ng-template>
      <app-institute-social-media></app-institute-social-media>
    </mat-tab>
  </mat-tab-group>

</div>
