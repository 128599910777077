<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Add new Academic Course</h2>
    </div>
	<div class="card text-right">
        <a href="/institute/teaching-learning"><button type="button" class="btn btn-success">Back</button></a>
	</div>
	<div class="card">
        <div class="card-title"><p>Details of Academic Course</p></div>
	</div>

    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">

		<div class="row formrow">
			<div class="col-md-4">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Title of the Credit Course</mat-label>
				<input matInput autocomplete="off" placeholder="Title of the Credit Course" formControlName="title" type="text"   required>        
				</mat-form-field>
			</div>
		
			<div class="col-md-4">

				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Course Code</mat-label>
					<input matInput autocomplete="off" placeholder="Course Code" formControlName="course_code" type="text"   required>        
					</mat-form-field>   
		
			  </div>
			<div class="col-md-4">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Select the Academic Year, the Course was Offered</mat-label>
				<mat-select autocomplete="off" placeholder="" formControlName="academic_year"  required  >
				<mat-option value="2020-21">2020-21</mat-option>
				<mat-option value="2021-22">2021-22</mat-option>
				<mat-option value="2022-23">2022-23</mat-option>
				<mat-option value="2023-24">2023-24</mat-option>
				</mat-select>				
			</mat-form-field>			  
			</div>
		
		  </div>
		
		  <!-- ROW Ends  -->

		     
		  <div class="row formrow">
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Type of Course</mat-label>
					<mat-select autocomplete="off" placeholder="" formControlName="course_type"  required  >
					<mat-option value="Elective Course">Elective Course</mat-option>
					<mat-option value="Core Course">Core Course</mat-option>
					</mat-select>				
				</mat-form-field>
			</div>
		
			<div class="col-md-4">		
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Level of the Course</mat-label>
					<mat-select autocomplete="off" placeholder="" formControlName="course_level"  required  >
					<mat-option value="Diploma">Diploma</mat-option>
					<mat-option value="Bachelor">Bachelor</mat-option>
					<mat-option value="Master">Master</mat-option>
					<mat-option value="PhD">PhD</mat-option>
					</mat-select>				
				</mat-form-field>		
			</div>

			<div class="col-md-4">

				
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Course Offering Department/ Centre/ Unit Name</mat-label>
					<input matInput autocomplete="off" placeholder="Course Offering Department/ Centre/ Unit Name" formControlName="offering_department" type="text"   required>        
					</mat-form-field>			  
			</div>
		
		  </div>
		
		  <!-- ROW Ends  -->


		  <div class="row formrow">
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Total Credit of the Course</mat-label>
					<mat-select autocomplete="off" placeholder="" formControlName="credit_score"  required  >
					<mat-option value="1">1</mat-option>
					<mat-option value="2">2</mat-option>
					<mat-option value="3">3</mat-option>
					<mat-option value="4">4</mat-option>
					<mat-option value="5">5</mat-option>
					<mat-option value="6">6</mat-option>
					</mat-select>				
				</mat-form-field>
			</div>
		
			<div class="col-md-4">
		
			  <mat-form-field class="example-full-width" style="width: 100%;">
				<mat-label>Start Date <span class="redMendate">*</span></mat-label>
				<input matInput [matDatepicker]="start_date" readonly formControlName="start_date" [min]="minDate" [max]="maxDate">
				<mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
				<mat-datepicker #start_date color="primary"></mat-datepicker>
			  </mat-form-field>
		
			</div>
			<div class="col-md-4">
				<mat-form-field class="example-full-width" style="width: 100%;">
					<mat-label>End Date <span class="redMendate">*</span></mat-label>
					<input matInput [matDatepicker]="end_date" readonly formControlName="end_date" [min]="minDate" [max]="maxDate">
					<mat-datepicker-toggle matSuffix [for]="end_date"></mat-datepicker-toggle>
					<mat-datepicker #end_date color="primary"></mat-datepicker>
				  </mat-form-field>
			  
			</div>
		
		  </div>
		
		  <!-- ROW Ends  -->

		  <div class="row formrow">
			<div class="col-md-4">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Total Duration of the Course (in Days)</mat-label>
				<input matInput autocomplete="off" placeholder="Total Duration of the Course (in Days)" formControlName="course_duration" type="number"   required>        
				</mat-form-field>
			</div>
		
			<div class="col-md-4">

				 
				
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Total Numbers of Contact Hours of Course (Minimum 10 Contact hours) of the course.</mat-label>
					<input matInput autocomplete="off" placeholder="Total Numbers of Contact Hours of Course (Minimum 10 Contact hours) of the course." formControlName="contact_hours" type="number"   required>        
					</mat-form-field>
		
			</div>
			<div class="col-md-4">
			  <mat-form-field appearance="outline" class="w-100">
						<mat-label> Mode of Delivery of Course</mat-label>
						<mat-select autocomplete="off" placeholder="" formControlName="course_delivery_mode"  required  >
						<mat-option value="Online Mode">Online Mode</mat-option>
						<mat-option value="Offline Mode">Offline Mode</mat-option>
						<mat-option value="Blended Mode">Blended Mode</mat-option>
						</mat-select>				
					</mat-form-field>
			  
			</div>
		
		  </div>
		
		  <!-- ROW Ends  -->

		  <div class="row formrow">
			<div class="col-md-4">
			  <mat-form-field appearance="outline" class="w-100">
				<mat-label>Total Numbers of Beneficiaries/Participants Undergone the Course</mat-label>
				<input matInput autocomplete="off" placeholder="Total Numbers of Beneficiaries/Participants Undergone the Course" formControlName="no_of_participants" type="number"   required>        
				</mat-form-field>
			</div>
		
			<div class="col-md-4">		
			 &nbsp;		
			</div>
			<div class="col-md-4">
				&nbsp;			  
			</div>
		
		  </div>
		
		  <!-- ROW Ends  -->

		  <div class="row formrow">
			<div class="col-md-12">
			  <button mat-raised-button color='primary' class="btn btn-success float-right"
				  [disabled]='iicImpactQuestions.invalid'>Submit</button>
			</div>
		  </div>
	</form>
</div>
