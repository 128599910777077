import { Component, OnInit, Inject } from '@angular/core';
import { InstituteActivityService } from 'src/app/Services/institute-activity.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as myGlobals from '../../../../../../app/app.component';


@Component({
  selector: 'app-institute-celebration',
  templateUrl: './institute-celebration.component.html',
  styleUrls: ['./institute-celebration.component.css']
})
export class InstituteCelebrationComponent implements OnInit {
  reportsClosed: boolean = false
  currentPageNo: number = 1;
  firstPage = true;
  lastPage: boolean = false;
  micActivity: InstitueMICActivity[] = [];
  imagePath: any;
  // baseUrl = "https://mic.gov.in/images/events/";
  quarter_filter: string = "";
  total_pages: any;
  frontCurrentPageNo: number = 0;
  pageSizeOptions: number[] = [12, 25, 50, 100];
  per_page: number = 12;
  searched: string;
  search_keyword: string;
  title1 = "";
  quarter1:any;
  quarter2:any;
  quarter3:any;
  quarter4:any;

  constructor(private activityService: InstituteActivityService,
    public dialog: MatDialog, private toastr: ToastrService) { }
  showAddCalendar: boolean;
  showRemoveCalendar: boolean;
  allowRemove: boolean;
  ngOnInit(): void {
    this.getMICDetails(this.currentPageNo);
  }

  nextPage() {
    this.currentPageNo++;
    this.getMICDetails(this.currentPageNo);
  }

  previousPage() {
    this.currentPageNo--;
    this.getMICDetails(this.currentPageNo);
  }

  getMICDetails(pageNo) {
    this.activityService.getCelebrationDetails(this.per_page, pageNo, this.quarter_filter, this.title1).subscribe(
      data => this.handleResponse(data)
    );
  }
  getMICDetails1() {
    this.activityService.getCelebrationDetails(this.per_page, this.currentPageNo, this.quarter_filter, this.title1).subscribe(
      data => this.handleResponse(data)
    );
  }

  quarterFilter() {
    this.currentPageNo = 1;
    this.getMICDetails(this.currentPageNo);
  }
  nextPage1(e: any) {
    if (this.per_page != e.pageSize) {

      this.currentPageNo = (e.pageIndex + 1);
    } else {
      this.currentPageNo = (e.pageIndex + 1);
    }
    this.per_page = e.pageSize;
    this.getMICDetails1();
  }


  filter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searched = filterValue;
  }

  search() {
    this.search_keyword = this.searched;
    this.activityService.getCelebrationDetails(this.per_page, this.currentPageNo, this.quarter_filter, this.search_keyword).subscribe(
      data => this.handleResponse(data)
    )
  }


  handleResponse(data) {
    this.micActivity = [];

    this.quarter1 = data['quater1'];
    this.quarter2 = data['quater2'];
    this.quarter3 = data['quater3'];
    this.quarter4 = data['quater4'];

    data['data'].forEach((element) => {
      this.micActivity.push(element);
    });
    this.total_pages = data.meta.pagination.total;


    if (
      data['meta']['pagination']['current_page'] ==
      data['meta']['pagination']['total_pages']
    ) {
      this.firstPage = false;
      this.lastPage = true;
    } else {
      this.lastPage = false;
    }

    if (data['meta']['pagination']['current_page'] == 1) {
      this.firstPage = true;
      this.lastPage = false;
    } else {
      this.firstPage = false;
    }
  }

  openDialog(row) {
    const dialogRef = this.dialog.open(InstituteCelebrationDialog, {
      data: { activity: row }
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  removeCalendar(eventId, status) {
    if (status !== "Submitted") {
      this.activityService.removeMicCalendar(eventId).subscribe(
        data => this.ngOnInit()
      );
      alert('Are you sure you want to delete ?')
      this.toastr.success(" Activity Removed Successfully");
    }
    else alert('The activity is already submitted, hence it cannot be deleted!')
  }
  addCalendar(eventId) {
    this.activityService.addMicCalendar(eventId).subscribe(
      data => this.ngOnInit()
    );
    alert('Are you sure you want to add ?')
    this.toastr.success(" Activity Added Successfully");
  }

}


@Component({
  selector: 'app-institute-celebration-dialog',
  templateUrl: 'institute-celebration-dialog.html',
})

export class InstituteCelebrationDialog implements OnInit {

  id: any;
  activity: any;

  // baseUrl = "https://mic.gov.in/images/events/";
  baseUrl = myGlobals.baseImageUrl;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InstituteCelebrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.activity = this.data['activity'];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(values) {

  }
}

export class InstitueMICActivity {
  id: number;
  title: string;
  description: string;
  attachmentPath: string;
  quarter: string;
  endDate: string;
  startDate: string;
}


