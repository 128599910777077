<div class="container">
  <div class="row" color="primary">
    <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;"> YUKTI Innovator's Login Creation</h2>
  </div>

	<!-- <div class="card">
    <div class="card-title"><p>Details of yukti challenge to be organized</p></div>
	</div> -->

    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
      <table class="table table-hover">
        <tr>
          <td>
            Name
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Name</mat-label>
              <input matInput autocomplete="off" placeholder="Name" formControlName="name" type="text" required>
              <mat-error *ngIf="iicImpactQuestions.controls.name.errors?.required">Name
                is required
              </mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            Email Id
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email Id</mat-label>
              <input matInput autocomplete="off" placeholder="Email Id" formControlName="email" type="text" required>
              <mat-error *ngIf="iicImpactQuestions.controls.email.errors?.required">Email Id
                is required
              </mat-error>
              <mat-error *ngIf="iicImpactQuestions.controls.email.hasError('invalidEmail')">Invalid
                email address</mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            Mobile Number
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mobile Number</mat-label>
              <input matInput autocomplete="off" placeholder="Mobile Number" formControlName="mobile_number" type="text" minlength="10" maxlength="10" required (keypress)="isNumKey($event)">
              <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.required">Mobile Number is required </mat-error>
              <mat-error *ngIf="iicImpactQuestions.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits </mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            Gender
          </td>
          <td>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="gender" required>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
              <mat-error *ngIf="iicImpactQuestions.controls.gender.errors?.required"> Gender is required </mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            Current Engagement with the Institute
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label> Current Engagement with the Institute</mat-label>
              <mat-select autocomplete="off" placeholder="Current Engagement with the Institute" formControlName="user_type"  required>
              <mat-option value="Student">Student</mat-option>
              <mat-option value="Faculty">Faculty/Staff</mat-option>
              <mat-option value="Alumni">Graduated/Alumni Member</mat-option>
              <mat-option value="Entrepreneur">Entrepreneur at Incubation Unit/Institute</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <!-- <tr>
          <td>
            Type
          </td>
          <td>
            <mat-radio-group formControlName="type" required aria-label="Type of Courses Offered by the Institute/University:" (change)="radioChange($event.value)">
              <mat-radio-button value="transfer" class="radioButSignUp" >Transfer</mat-radio-button>
              <mat-radio-button value="new" class="radioButSignUp">New</mat-radio-button>
              <mat-error *ngIf="iicImpactQuestions.controls.type.errors?.required">
                Type is required
              </mat-error>
            </mat-radio-group>
          </td>
        </tr>
        <tr *ngIf="isTransfer">
          <td>
            Choose the Innovations that are all needed to be transferred to the above mentioned Innovator
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Choose the Innovations for transfer</mat-label>
              <mat-select autocomplete="off" formControlName="prototype" multiple>
                
                <mat-option value="{{value.id}}" *ngFor="let value of protoTypeList">{{value.team_id}} - {{value.idea_title}}, {{value.submission_type}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr> -->
        <tr *ngIf="isNew">
          <td>
            Prototype
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Prototype</mat-label>
              <mat-select autocomplete="off" formControlName="proto_type" >
                <!-- <mat-option>Select</mat-option> -->
                <mat-option value="1">Idea</mat-option>
                <mat-option value="2">Prototype</mat-option>
                <mat-option value="3">Startup</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr *ngIf="isNew">
          <td>
            Title
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Title</mat-label>
              <input matInput autocomplete="off" placeholder="Title" formControlName="title" type="text" >
              <mat-error *ngIf="iicImpactQuestions.controls.title.errors?.required">Title is required </mat-error>
            </mat-form-field>
          </td>
        </tr>
        <tr *ngIf="isNew">
          <td>
            Developed as part of
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Developed as part of</mat-label>
              <mat-select autocomplete="off" placeholder="Developed as part of" formControlName="developed_as_part_of" >
                <mat-option value="Academic Requirement/Study Project">Academic Requirement/Study Project</mat-option>
                <mat-option value="Academic Research Assignment/Industry Sponsored Project">Academic Research Assignment/Industry Sponsored Project</mat-option>
                <mat-option value="Independent Assignment/Non-academic Study Project">Independent Assignment/Non-academic Study Project</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr *ngIf="isNew">
          <td>
            Choose the Financial Year, during the Idea-PoC/Innovation Developed
          </td>
          <td>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Academic Year</mat-label>
              <mat-select formControlName="academic_year" placeholder="Academic Year" >
                <mat-option value="2019-20">2019-20</mat-option>
                <mat-option value="2020-21">2020-21</mat-option>
                <mat-option value="2021-22">2021-22</mat-option>
                <mat-option value="2022-23">2022-23</mat-option>
                <mat-option value="2023-24">2023-24</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            &nbsp;
          </td>
          <td>
            <button mat-raised-button color='primary' class="btn btn-success float-right"
            [disabled]='iicImpactQuestions.invalid'>Submit</button>
          </td>
        </tr>
	   </table>
	</form>
</div>
