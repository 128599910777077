<div class="container">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="socialForm" (ngSubmit)="onSubmit(socialForm.value)">

      <div class="row">
        <div class="col-4">
          Number of Students in the Current Academic Year:
        </div>
        <div class="col-3">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
            <mat-label>Boys</mat-label>
            <input matInput formControlName="boys" placeholder="Boys">
            <mat-error *ngIf="socialForm.controls['boys'].hasError('required')">Boys is
              required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
            <mat-label>Girls</mat-label>
            <input matInput formControlName="girls" placeholder="Girls">
            <mat-error *ngIf="socialForm.controls['girls'].hasError('required')">Girls is
              required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-2">
          <p>Total no. of Students:{{totalStudentCount}}</p>
        </div>

      </div>


      <div class="row">
        <div class="col-4">
          Number of Faculties in the Current Academic Year:
        </div>
        <div class="col-3">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
            <mat-label>Male</mat-label>
            <input matInput formControlName="male" placeholder="Male">
            <mat-error *ngIf="socialForm.controls['male'].hasError('required')">Male is
              required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
            <mat-label>Female</mat-label>
            <input matInput formControlName="female" placeholder="Female">
            <mat-error *ngIf="socialForm.controls['female'].hasError('required')">Female is
              required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-2">
          <p>Total no. of Faculties:{{totalFacultyCount}}</p>
        </div>
      </div>

      <h1 class="c">Social media information of institute/iic:</h1>

      <div class="row">

        <div class="col-2">
          <span class="fa fa-facebook-square" style="font-size:40px;float:right">
          </span>
        </div>
        <div class="col-10">
          <input matInput type="text" class="form-control" id="facebook" required style="float:right"
            formControlName="facebook">
        </div>
      </div>

      <div class="row">
        <div class="col-2">
          <span class="fa fa-twitter-square" style="font-size:40px;float:right">
          </span>
        </div>
        <div class="col-10">
          <input matInput type="text" class="form-control" id="twitter" required style="float:right"
            formControlName="twitter">
        </div>
      </div>


      <div class="row">
        <div class="col-2">
          <span class="fa fa-instagram" style="font-size:40px;float:right">
          </span>
        </div>
        <div class="col-10">
          <input matInput type="text" class="form-control" id="instagram" required style="float:right"
            formControlName="instagram">
        </div>
      </div>

      <div class="row">
        <div class="col-6"><label>Upload Logo:</label>
          <mat-error>File Must Be Type Of JPEG,PNG,JPG</mat-error>
        </div>
        <div class="col-6" *ngIf="!logoUploaded">
          <label class="custom-file-label float-right" for="customFile" *ngIf="!logoFile">Choose
            file</label>
          <label class="custom-file-label float-right" for="customFile" *ngIf="logoFile">{{logoFile[0]['name']}}</label>
          <input #logoAttachmentsubfileRef type="file" accept="image/*" name="logoAttachmentsubfile"
            (change)="( logoFile = logoAttachmentsubfileRef.files )" class="custom-file-input float-right" required>
        </div>

        <button mat-raised-button type="button" (click)="logoUploaded=!logoUploaded" color='primary' class="btn btn-success float-right"
        *ngIf="logoUploaded">Reupload</button>
      </div>

      <div>
        <button type="button" mat-raised-button color='warn' (click)='onNoClick()' style="margin-right: 450px;
            " class="btn btn-danger instituteBtnClose">Close</button>
        <button mat-raised-button color='primary' class="btn btn-success float-right"
          *ngIf="fbl.length=='0'">Submit</button>

        <button mat-raised-button color='primary' class="btn btn-success float-right"
          *ngIf="fbl.length>0">Update</button>

      </div>

    </form>
  </mat-dialog-content>
</div>
