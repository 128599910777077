<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Add New Collaboration</h2>
    </div>

	<div class="card">
        <div class="card-title"><p>Details of Collaboration</p></div>
	</div>

    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)" style="padding:10px 30px;">
		<h3>I&E Collaboration</h3>
		<div class="row formrow">
		  <div class="col-md-4">
			<mat-form-field appearance="outline" class="w-100">
			  <mat-label>Collaborated Agency Name</mat-label>
			  <input matInput autocomplete="off" placeholder="Collaborated Agency Name" formControlName="agency_name" type="text"   required>        
			  </mat-form-field>
		  </div>
	  
		  <div class="col-md-4">
	  
			<mat-form-field class="example-full-width" style="width: 100%;">
			  <mat-label>Date of MoU/Agreement Signed on <span class="redMendate">*</span></mat-label>
			  <input matInput [matDatepicker]="dateofmou" readonly formControlName="dateofmou">
			  <mat-datepicker-toggle matSuffix [for]="dateofmou"></mat-datepicker-toggle>
			  <mat-datepicker #dateofmou color="primary"></mat-datepicker>
			</mat-form-field>
	  
		  </div>
		  <div class="col-md-4">
			<mat-form-field appearance="outline" class="w-100">
					  <mat-label> Collaborated Agency Type</mat-label>
					  <mat-select autocomplete="off" placeholder="" formControlName="agency_type"  required  >
					  <mat-option value="Govt. Agency (Central)">Govt. Agency (Central)</mat-option>
			  <mat-option value="Govt. Agency (State)">Govt. Agency (State)</mat-option>
					  <mat-option value="Corporate/Industry Association">Corporate/Industry Association</mat-option>
			  <mat-option value="Knowledge/Mentoring Agency">Knowledge/Mentoring Agency</mat-option>
					  <mat-option value="Non Govt. Agency">Non Govt. Agency</mat-option>
					  <mat-option value="International Agency">International Agency</mat-option>
			  <mat-option value="Incubation Unit">Incubation Unit</mat-option>
					  <mat-option value="Research Park">Research Park</mat-option>
			  <mat-option value="Angel/VC Firm">Angel/VC Firm</mat-option>
					  <mat-option value="IPR Law Firms/Startup">IPR Law Firms/Startup</mat-option>
			  <mat-option value="Atal Tinkering Lab (ATL) in School">Atal Tinkering Lab (ATL) in School</mat-option>
					  </mat-select>				
				  </mat-form-field>
			
		  </div>
	  
		</div>
	  
		<!-- ROW Ends  -->
	  
		<div class="row formrow">
		  <div class="col-md-4">
			<mat-form-field class="example-full-width" style="width: 100%;">
			  <mat-label>Collaboration MoU Valid upto <span class="redMendate">*</span></mat-label>
			  <input matInput [matDatepicker]="period_of_collaboration" readonly formControlName="period_of_collaboration">
			  <mat-datepicker-toggle matSuffix [for]="period_of_collaboration"></mat-datepicker-toggle>
			  <mat-datepicker #period_of_collaboration color="primary"></mat-datepicker>
			</mat-form-field>
		  </div>
	  
		  <div class="col-md-4">      
			<mat-form-field appearance="outline" class="w-100">
	  
					  <mat-label> Current Status Of Collaboration</mat-label>
	  
					  <mat-select autocomplete="off" placeholder="" formControlName="collaboration_status"  required>
					  <mat-option value="Active">Active</mat-option>
			  <mat-option value="Inactive">Inactive</mat-option>
					  <mat-option value="Closed">Closed</mat-option>
					  </mat-select>
				  </mat-form-field>
		  </div>
	  
		  <div class="col-md-4">
			Upload of the Copy of MOU /Agreement
			<div class="col-12">
			  <label class="custom-file-label float-right" for="customFile"
								*ngIf="!ipr_document">Choose file</label>
	  
			  <label class="custom-file-label float-right" for="customFile"
				  *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>
	  
			  <input #ipr_documentRef type="file" accept="application/pdf"  name="ipr_document"
				  (change)="( ipr_document = ipr_documentRef.files )"
				  class="custom-file-input float-right" required>
			</div>
			<div class="col-12">
				<p *ngIf="document!=''"><a style="display:inline-block;" href="{{baseUrl + document}}" target="_blank">View File</a></p>
			</div>

				
		  </div>
	  
		</div>
	  
		<div class="row formrow">
		  <div class="col-md-12">
			<button mat-raised-button color='primary' class="btn btn-success float-right"
				[disabled]='iicImpactQuestions.invalid'>Submit</button>
		  </div>
		</div>
	  
	  </form>
</div>
