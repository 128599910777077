<div class="container">
  <div>

    <mat-dialog-content class="mat-typography">
      <form [formGroup]="instituteForm" (ngSubmit)="onSubmit(instituteForm.value)">
        <h1 style="text-align:center;">More information about Institute</h1>
        <div class="row">


          <mat-card style="background-color:	#DCDCDC;" class="col-sm-12">
            <h2>Incubation & Innovation facilities in Campus:</h2>
            <div *ngIf="!noneChecked">
              <!-- <div *ngFor="let facility of incubationInnovationFacilities">
                <mat-checkbox color="primary" class="example-margin"
                  (change)="onChangeIncubationList($event)" [value]="facility">{{facility}}
                </mat-checkbox>
              </div> -->

              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Incubation & Pre-incubation Units'" [checked]='incubationFacility'>Incubation &
                  Pre-incubation Units
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Research Park'" [checked]='researchParkFacility'>
                  Research Park
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Innovation & Entrepreneurship Development Cell (IEDC'" [checked]='entrepreneurshipFacility'>
                  Innovation & Entrepreneurship Development Cell(IEDC)
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'EDE'" [checked]='EDEkFacility'>Entrepreneurship Development Cell(EDC)
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Design Centre/Maker\'s Space/Arts and Craft making Design'" [checked]='designFacility'>
                  Design
                  Centre/Maker's Space/Arts and Craft making Design
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Start up cell under TEQUIP II'" [checked]='startupFacility'>Start up cell under TEQUIP II
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'New gen IEDC'" [checked]='newGenFacility'>New gen
                  IEDC
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Innovation Cell'" [checked]='innovationFacility'>
                  Innovation Cell
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Tinkering Labs/Centre'" [checked]='tinkeringFacility'>Tinkering Labs/Centre
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'IPR/Technology Transfer Cell/Unit'" [checked]='IPRFacility'>IPR/Technology Transfer
                  Cell/Unit
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Industry-Institute Interface Unit/Centre'" [checked]='industryFacility'>Industry-Institute
                  Interface Unit/Centre
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Idea Clubs/Innovation Clubs/Startup Clubs/IP Clubs lead by Student Bodies'"
                  [checked]='ideaFacility'>Idea Clubs/Innovation Clubs/Startup Clubs/IP Clubs lead by Student Bodies
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Centre of Excellence/Skill Development Labs at Institute for Practical Learning'"
                  [checked]='centreFacility'>Centre of Excellence/Skill Development Labs at Institute for Practical
                  Learning
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'Internship Centre for Students /Short-term Placement in Startups'"
                  [checked]='internshipFacility'>Internship Centre for Students /Short-term Placement in Startups
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox color="primary" class="example-margin" (change)="onChangeIncubationList($event)"
                  [value]="'None'" [checked]='noneFacility'>None
                </mat-checkbox>
              </div>

            </div>
            <div *ngIf="noneChecked">
              <mat-checkbox color="primary" class="example-margin" [checked]='noneChecked'
                (change)="noneChecked=!noneChecked">None</mat-checkbox>
            </div>

          </mat-card>

        </div>
        <div class="row mt-4">
          <mat-card class="col-6" style="background-color:	#DCDCDC">
            <h2>Accreditation:(Upload accreditation certificate of both NBA and NAAC)(Optional)</h2>
            <mat-checkbox color="primary" class="example-margin" formControlName="nba" [(ngModel)]="nbaChecked">NBA
            </mat-checkbox>
            <div class="col-sm-12">
              <!-- NBA File starts-->
              <div>
                <div class="row" *ngIf=!nba_file_flag>
                  <div class="col-lg-8 col-md-10">
                    <label>Upload NBA:</label>
                    <mat-error>PDF(max 2MB)</mat-error>
                  </div>
                  <div class="col-lg-4 col-md-2 float-right">
                    <label class="custom-file-label float-right" for="customFile" *ngIf="!nba_certificate">Choose
                      file</label>
                    <label class="custom-file-label float-right" for="customFile"
                      *ngIf="nba_certificate">{{nba_certificate[0]['name']}}</label>
                    <input #nba_certificateRef type="file" accept="application/pdf" name="nba_certificate"
                      (change)="( nba_certificate = nba_certificateRef.files )" class="custom-file-input float-right"
                      required>
                  </div>
                </div>
              </div>
              <!-- show succesfull file -->
              <div *ngIf=nba_file_flag>
                <div class="row">
                  <!-- <div class="col-2 col-md-10">
                  </div> -->
                  <div class="col-lg-2 col-md-10">
                    <a href="{{baseUrl + NBA_File}}" target="_blank">Preview</a>
                  </div>

                  <div class="col-5"></div>
                  <div class="col-lg-4 col-md-2 float-right">
                    <button type="button" mat-raised-button color="warn" class="float-right" (click)="setFlag1()">
                      Edit File
                    </button>
                  </div>
                </div>
              </div>
              <!-- document upload end -->
              <!-- NBA File ends -->
            </div>
            <br />
            <mat-checkbox color="primary" class="example-margin" formControlName="naac" [(ngModel)]="naacChecked">NAAC
            </mat-checkbox>
            <div class="col-12">
              <div>
                <div class="row" *ngIf=!naac_flag>
                  <div class="col-lg-8 col-md-10">
                    <label>Upload NAAC:</label>
                    <mat-error>PDF(max 2MB)</mat-error>
                  </div>
                  <div class="col-lg-4 col-md-2 float-right">
                    <label class="custom-file-label float-right" for="customFile" *ngIf="!naac_certificate">Choose
                      file</label>
                    <label class="custom-file-label float-right" for="customFile"
                      *ngIf="naac_certificate">{{naac_certificate[0]['name']}}</label>
                    <input #naac_certificateRef type="file" accept="application/pdf" name="naac_certificate"
                      (change)="(naac_certificate = naac_certificateRef.files )" class="custom-file-input float-right"
                      required>
                  </div>
                </div>
              </div>

              <!-- show succesfull file -->
              <div *ngIf=naac_flag>
                <div class="row">
                  <div class="col-lg-2 col-md-10">
                    <a href="{{baseUrl + NAAC_File}}" target="_blank">Preview</a>

                  </div>
                  <div class="col-5"></div>
                  <div class="col-lg-4 col-md-2 float-right">
                    <button type="button" mat-raised-button color="warn" class="float-right" (click)="setFlag2()">
                      Edit File
                    </button>
                  </div>
                </div>
              </div>
              <!-- document upload end -->
            </div>
          </mat-card>
          <div class="col-1">
          </div>
          <mat-card class="col-5" style="background-color:	#DCDCDC">
            <div class="m-4">
              <h2>National Ranking(Optional):</h2>
              <mat-checkbox color="primary" class="example-margin" formControlName="ariia" [(ngModel)]="ariiaChecked"
                (change)="checkstate(1)">ARIIA Ranking
              </mat-checkbox>
              <div class="col-12" style="background-color:white;">
                <div>
                  <div *ngIf=ariiaChecked class="m-4">
                    <mat-form-field class="example-full-width">
                      <mat-label>ARIIA Ranking</mat-label>
                      <input matInput formControlName="ariiaField" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <br />
              <mat-checkbox color="primary" class="example-margin" formControlName="nirf" (change)="checkstate(2)"
                [(ngModel)]="nirfChecked">NIRF Ranking
              </mat-checkbox>
              <div class="col-12" style="background-color:white;">
                <div>
                  <div *ngIf=nirfChecked class="m-4">
                    <mat-form-field class="example-full-width">
                      <mat-label>NIRF Ranking</mat-label>
                      <input matInput formControlName="nirfField" placeholder="">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <br />
        <div class="row">
          <mat-card class="col-sm-6">

            <div *ngIf="nispNominatedFlag">
              <h4>Appointed coordinator for implementation of NISP : {{nispNominated}}</h4>
              <h4>Faculty Name: {{nispDetails?.faculty_name}}</h4>
              <h4>Faculty Contact: {{nispDetails?.faculty_contact}}</h4>
              <h4>Faculty Email: {{nispDetails?.faculty_email}}</h4>
            </div>

            <div *ngIf="!nispNominatedFlag">
              <h4>Appointed coordinator for implementation of NISP : No</h4>
            </div>
          </mat-card>
          <mat-card class="col-sm-6">
            <h4>Participated in IIC Innovation Ambassador Training Program : {{isIAParticipated}}</h4>
          </mat-card>
        </div>
        <br />
        <h1 style="color:red;">Nominate President for IIC</h1>
        <div class="row">
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput formControlName="spoc_name" placeholder="Name" required (keypress)=isCharKey($event)>
              <mat-error *ngIf="instituteForm.controls.spoc_name.errors?.required">Institute Name is
                required
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="spoc_email" placeholder="Email" required (keypress)=isSpace($event)>
              <mat-error *ngIf="instituteForm.controls.spoc_email.errors?.required">Email is required
              </mat-error>
              <mat-error *ngIf="instituteForm.controls.spoc_email.hasError('invalidEmail')">Invalid email
                address
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Designation</mat-label>
              <input matInput formControlName="spoc_designation" placeholder="Designation" required
                (keypress)=isCharKey($event)>
              <mat-error *ngIf="instituteForm.controls.spoc_designation.errors?.required">Designation is
                required
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Contact Number</mat-label>
              <input matInput formControlName="spoc_contact" placeholder="Contact Number" placeholder="Contact Number"
                minlength="10" maxlength="10" required (keypress)=isNumberKey($event)>
              <mat-error *ngIf="instituteForm.controls.spoc_contact.errors?.required">Contact Number is
                required
              </mat-error>
            </mat-form-field>
          </div>


          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Gender</mat-label>
              <mat-select placeholder="Gender" formControlName="gender" required>
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
              <mat-error *ngIf="instituteForm.controls['gender'].hasError('required')">Faculty gender is
                required</mat-error>

            </mat-form-field>
          </div>
        </div>
        <h1>Institute Details</h1>
        <div class="row">
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="col-12 example-full-width" appearance="outline">
              <mat-label>Select Approval Type</mat-label>
              <mat-select formControlName="approvals" multiple [(ngModel)]="ApprovalModel"
                (selectionChange)="getSelectedApprovalType()">
                <app-select-check-all [model]="instituteForm.get('approvals')" [values]="ApprovalType">
                </app-select-check-all>
                <mat-option *ngFor="let ApprovalType of ApprovalType" [value]="ApprovalType">
                  {{ApprovalType}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="instituteForm.controls.approvals.errors?.required">Approval Type is
                required
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>Institute Type</mat-label>
              <mat-select formControlName="institute_type" [(ngModel)]="instituteModel">
                <mat-option value="1">University
                </mat-option>
                <mat-option value="2">College
                </mat-option>
                <mat-option value="3">Standalone
                </mat-option>
              </mat-select>
              <mat-error *ngIf="instituteForm.controls.institute_type.errors?.required">Institute Type is
                required
              </mat-error>
            </mat-form-field>
          </div>


          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Sub Type</mat-label>
              <mat-select formControlName="institute_sub_type" placeholder="Sub Type" required
                (keypress)=isCharKey($event) [(ngModel)]="institueSubTypeModel">
                <mat-option *ngFor="let type of instituteSubType" value="{{type.id}}">
                  {{type?.type_subtype}}</mat-option>
              </mat-select>
              <mat-error *ngIf="instituteForm.controls.institute_sub_type.errors?.required">Institute Sub
                Type is required
              </mat-error>
            </mat-form-field>
          </div>


          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="col-12" appearance="outline">
              <mat-label>Select Courses</mat-label>
              <mat-select formControlName="courses_offered" multiple [(ngModel)]="CoursesModel"
                (selectionChange)="getSelectedCourses()">
                <app-select-check-all [model]="instituteForm.get('courses_offered')" [values]="Courses">
                </app-select-check-all>
                <mat-option *ngFor="let Courses of Courses" [value]="Courses">
                  {{Courses}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="instituteForm.controls.courses_offered.errors?.required">Courses is
                required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Website</mat-label>
              <input matInput formControlName="website" placeholder="Webiste" required>
              <mat-error *ngIf="instituteForm.controls.website.errors?.required">Website is required
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>AICTE Approved Programme</mat-label>
              <mat-select formControlName="aicte_approved_program" required>
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
              <mat-error *ngIf="instituteForm.controls.aicte_approved_program.errors?.required">AICTE
                Approved Programme is
                required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="uploadDiv2">
            <mat-form-field class="w-100 ml-3" appearance="outline">
              <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
              <mat-label>Institute Permanent ID</mat-label>
              <input matInput placeholder="Institute Permanent ID" formControlName="pid">
              <mat-error *ngIf="instituteForm.controls.pid.errors?.required">Institute Permanent ID is
                required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-8 mt-3">
            <h4>Faculties Received Training on Entrepreneurship/Startup and IPR conducted by any national or
              State Govt. agencies during last 1-year time period (AY 2023-24).</h4>
          </div>

          <div class="col-4">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Select</mat-label>
              <mat-select placeholder="Select" formControlName="faculties_received_training">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="uploadDiv">
          <div *ngIf=!institute_letter_head_flag class="row">
            <div class="col-8" style="text-align: end">
              <label>Upload File: <mat-error>PDF(max 2MB)</mat-error></label>
            </div>

            <div class="col-4 float-right" style="max-width: 30%">
              <label class="custom-file-label float-right" for="customFile" *ngIf="!institute_letter_head">Choose
                file</label>
              <label class="custom-file-label float-right" for="customFile"
                *ngIf="institute_letter_head">{{institute_letter_head[0]['name']}}</label>
              <input #institute_letter_headRef type="file" accept="application/pdf" name="institute_letter_head"
                (change)="( institute_letter_head = institute_letter_headRef.files )"
                class="custom-file-input float-right">
            </div>
          </div>

          <!-- show succesfull file -->

          <div class="row" *ngIf=institute_letter_head_flag>
            <div class="col-8"></div>
            <div class="col-2" style="text-align: end">
              <a href="{{baseUrl + institute_Letter_Head}}" target="_blank">Preview</a>
            </div>
            <div class="col-2 float-right">
              <button type="button" mat-raised-button color="warn" class="float-right" (click)="setFlag3()">
                Edit File
              </button>
            </div>
          </div>

          <!-- document upload end -->
        </div>
        <div class="row">
          <div class="col-8 mt-3">
            <h4>Offering Academic Credit Core/Elective Subject on Entrepreneurship/Innovation/IPR Program
              at Diploma/UG/PG/Ph.D. Level:</h4>
          </div>

          <div class="col-4">
            <mat-form-field class="example-full-width col-12" appearance="outline">
              <mat-label>Select</mat-label>
              <mat-select placeholder="Select" formControlName="academic_credit_status">
                <mat-option value="Yes">Yes</mat-option>
                <mat-option value="No">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="uploadDiv1">
          <div *ngIf=!academic_credit_core_flag class="row">
            <div class="col-8" style="text-align: end">
              <label>Upload File: <mat-error>PDF(max 2MB)</mat-error></label>
            </div>

            <div class="col-4 float-right" style="max-width: 30%">
              <label class="custom-file-label float-right" for="customFile"
                *ngIf="!offering_academic_credit_core">Choose
                file</label>
              <label class="custom-file-label float-right" for="customFile" *ngIf="offering_academic_credit_core">{{
                offering_academic_credit_core[0]['name']}}</label>
              <input #offering_academic_credit_coreRef type="file" accept="application/pdf"
                name="offering_academic_credit_core"
                (change)="( offering_academic_credit_core = offering_academic_credit_coreRef.files )"
                class="custom-file-input float-right">
            </div>
          </div>
          <!-- show succesfull file -->

          <div class="row" *ngIf=academic_credit_core_flag>
            <div class="col-8"></div>
            <div class="col-2" style="text-align: end">
              <a href="{{baseUrl + offering_Academic_Credit_core}}" target="_blank">Preview</a>

            </div>
            <div class="col-2 float-right" style="max-width: 30%">
              <button type="button" mat-raised-button color="warn" class="float-right" (click)="setFlag4()">
                Edit File
              </button>
            </div>
          </div>

          <!-- document upload end -->


        </div>

        <br />
        <button type="button" mat-raised-button color='warn' (click)='closeDialog()'
          class="btn btn-danger instituteBtnClose">Close</button>
        <button mat-raised-button class="btn btn-success float-right" color="primary">Update</button>

      </form>
    </mat-dialog-content>
  </div>
</div>
