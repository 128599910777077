import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JarwisService } from 'src/app/Services/jarwis.service';

@Component({
  selector: 'app-teaching-learning-add',
  templateUrl: './teaching-learning-add.component.html',
  styleUrls: ['./teaching-learning-add.component.css']
})
export class TeachingLearningAddComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;
  month:any;

  minDate = "2023-01-01";
  maxDate = "2024-12-31";

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService, private jarwis:JarwisService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });
  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'title': [null, Validators.required],
      'course_code':[null, Validators.required,],
      'academic_year': [null, Validators.required],
      'course_type': [null, Validators.required],
      'course_level': [null, Validators.required],
      'offering_department': [null, Validators.required],
      'credit_score': [null, Validators.required],
      'start_date': [null, Validators.required],
      'end_date': [null, Validators.required],
      'course_duration': [null, Validators.required],
      'contact_hours': [null, Validators.required],
      'course_delivery_mode': [null, Validators.required],
      'no_of_participants': [null, Validators.required]
    });

    this.jarwis.teachingLearningData(this.rid,'Academic').subscribe(
      (data) => this.handleResponse(data)
    );
    
  }

  changeDate(values) {
    var newMonth = new Date(values).getMonth() + 1;
    if(newMonth == 10 || newMonth == 11 || newMonth == 12){
      this.month = newMonth;
    }else{
      this.month = '0'+newMonth;
    }
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (this.month);
    value = value + '-' + new Date(values).getDate().toString();
    values = value;
    return values;
  }


  handleResponse(data) {   

    if(data['totalcount'] > 0){
    this.iicImpactQuestions.patchValue({
      title: data['title'],
      course_code: data['course_code'],
      academic_year: data['academic_year'],
      course_type: data['course_type'],
      course_level: data['course_level'],
      offering_department: data['offering_department'],
      credit_score: data['credit_score'],
      start_date: data['start_date'],
      end_date: data['end_date'],
      course_duration: data['course_duration'],
      contact_hours: data['contact_hours'],
      course_delivery_mode: data['course_delivery_mode'],
      no_of_participants: data['no_of_participants']
    });   

  }
    this.totalcount = data['totalcount'];

  }

  handleResponseList(data) {
    this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {
    if(this.changeDate(this.iicImpactQuestions.get('start_date').value) > this.changeDate(this.iicImpactQuestions.get('end_date').value)){
      this.toastr.error('End date not greater than start date.');
      return false;
    }
    var formData = new FormData();

    formData.append('rid', this.rid);

    formData.append('title', this.iicImpactQuestions.get('title').value);
    formData.append('course_code', this.iicImpactQuestions.get('course_code').value);
    formData.append('academic_year', this.iicImpactQuestions.get('academic_year').value);
    formData.append('course_type', this.iicImpactQuestions.get('course_type').value);
    formData.append('course_level', this.iicImpactQuestions.get('course_level').value);
    formData.append('offering_department', this.iicImpactQuestions.get('offering_department').value);
    formData.append('credit_score', this.iicImpactQuestions.get('credit_score').value);
    formData.append('start_date', this.changeDate(this.iicImpactQuestions.get('start_date').value));
    formData.append('end_date', this.changeDate(this.iicImpactQuestions.get('end_date').value));
    formData.append('course_duration', this.iicImpactQuestions.get('course_duration').value);
    formData.append('contact_hours', this.iicImpactQuestions.get('contact_hours').value);
    formData.append('course_delivery_mode', this.iicImpactQuestions.get('course_delivery_mode').value);
    formData.append('no_of_participants', this.iicImpactQuestions.get('no_of_participants').value);
    
    this.instituteActivityService.submitTeacherlearningRepository(formData).subscribe(
     data => this.handleResponseData(data));    
    }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/teaching-learning';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

}
