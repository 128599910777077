<div class="container-fluid">

  <div class="row addTeachingTopRow addTeachingTopRowExterMember">
    <div class="col-4">
      <!-- <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)="openDialog()">
        <mat-icon>add</mat-icon> Add External Member Details
      </button> -->
    </div>
	<div class="col-4">
      <!-- <div class="row">
    <div class="col-2" *ngIf="!firstPage">
      <button type="button" (click)='previousPage()' class="btn btn-success">Previous</button>
    </div>
    <div class="col-2" *ngIf="!lastPage">
      <button type="button" (click)='nextPage()' class="btn btn-success">Next</button>
    </div>
	</div> -->
	</div>
	<div class="col-4">
      <!--<mat-form-field class="example-full-width" appearance="outline" style="color: black; width: 330px;">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search..." [(ngModel)]="filterData" (keyup)=search($event)>
      </mat-form-field> -->
    </div>
  </div>



  <!-- <div class="row">
        <div class="col-sm-6 col-md-3" *ngFor="let faculty of external;let i = index">
            <div class="card text-center text-white bg-info mb-4 card-shadow">
                <div class="card-body">
                    <h4 class="card-title text-white">Name: {{faculty?.name}}</h4>
                    <p class="card-text text-white">Email: {{faculty?.email}}</p>
                    <p class="card-text text-white">Contact: {{faculty?.mobile}}</p>
                    <span class="collapse" id="viewDetails">
                        <p class="card-text text-white">Organization: {{faculty?.organisation}}</p>
                        <p class="card-text text-white">Member: {{faculty?.members}}</p>
                        <p class="card-text text-white">Experience: {{faculty?.experience_in_years}}</p>
                    </span>
                    <a class="float-right" data-toggle="collapse" data-target="#viewDetails"
                        style="cursor: pointer;"><i>Read More... &raquo;</i></a>
                </div>
            </div>
        </div> -->

  <div class="row">
    <!-- <div class="col-xl-4 col-md-3 mb-4" *ngFor="let faculty of external;let i = index">
      <div class="aheto-pricing aheto-pricing--default  mat-elevation-z8">
        <div class="aheto-pricing__content">
          <h4 class="aheto-pricing__title t-bold">Name: {{faculty?.name}}</h4>
          <div class="aheto-pricing__description">
            <p class="aheto-heading__desc2Home">Email: {{faculty?.email}}</p>
          </div>
          <div class="aheto-pricing__description">
            <p class="aheto-heading__desc2Home">Contact: {{faculty?.mobile}}</p>
          </div>
          <div class="aheto-pricing__description">
            <p class="aheto-heading__desc2Home">Organization: {{faculty?.organisation}}</p>
          </div>
          <div class="aheto-pricing__description">
            <p class="aheto-heading__desc2Home">Member: {{faculty?.members}}</p>
          </div>
          <div class="aheto-pricing__description">
            <p class="aheto-heading__desc2Home">Experience: {{faculty?.experience_in_years}}</p>
          </div>
          <div>
            <button type="button" class="btn btn-secondary" (click)="openEditDialog(faculty)">Edit</button>
          </div>
          <div>
            <button type="button" class="btn btn-secondary" (click)="deleteDialog(faculty)">Delete</button>
          </div>
        </div>
      </div>
    </div> -->





    <!-- <div class="col-md-4" *ngFor="let faculty of external;let i = index">
      <div class="CardBox">
        <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg">
        <img src="assets/customCard/card-top-banner.png" class="CardBoxContentTopBanner">
        <div class="CardBoxContentTopName">

          <h3>{{faculty?.name}}</h3>
        </div>
        <div class="CardBoxContent">
          <h3>Email</h3>
          <p>{{faculty?.email}}</p>
        </div>

        <div class="CardBoxContent">
          <h3>Contact</h3>
          <p>{{faculty?.mobile}}</p>
        </div>

        <div class="CardBoxContent">
          <h3>Organization</h3>
          <p>{{faculty?.organisation}}</p>
        </div>
        <div class="CardBoxContent">
            <h3>Role: </h3>
            <p>{{faculty?.role}}</p>
          </div>
        <div id="InstExternID" class="collapse">

          <div class="CardBoxContent">
            <h3>Member</h3>
            <p>{{faculty?.members}}</p>
          </div>

          <div class="CardBoxContent">
            <h3>Experience</h3>
            <p>{{faculty?.experience_in_years}}</p>
          </div>

          <div class="CardBoxContent">
            <h3>Qualification</h3>
            <p>{{faculty?.qualification}}</p>
          </div>

          <div *ngIf="faculty?.CV_upload != '0' || faculty?.CV_upload != null" class="CardBoxContent">
            <a href="{{baseUrl + faculty?.CV_upload}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG">Download CV</button>
            </a>
          </div>

        </div> -->

        <!-- <div class="CardBoxContent">
          <div class="row">
            <div class="col-md-6">
              <div class="cardEditIcon">
                <a class="cardEditIconEdit" (click)="openEditDialog(faculty)"> <i class="fa fa-pencil-square-o"
                    aria-hidden="true"></i> Edit </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="cardEditIcon">
                <a class="cardEditIconDelete" (click)="deleteDialog(faculty)"> <i class="fa fa-trash-o"
                    aria-hidden="true"></i> Delete </a>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="CardBoxContent">
          <div class="row">
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="openEditDialog(faculty)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-primary btn-lg edit1" data-original-title="Edit Task">
                  <i class="fa fa-edit"> Edit</i>
                </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="deleteDialog(faculty)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-danger deleteInst1" data-original-title="Remove">
                  <i style="
                  font-size: 20px !important;
              " class="fa fa-times"> Delete</i>
                </a>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="cardReadMore">

          <button data-toggle="collapse" data-target="#InstExternID" *ngIf="readMore" (click)="readMore=!readMore">Read
            More</button>
          <button data-toggle="collapse" data-target="#InstExternID" *ngIf="!readMore" (click)="readMore=!readMore">Read
            Less</button>

        </div> -->


      <!--</div>
    </div> -->



  </div>
  
  <mat-card class="mat-elevation-z6 proInstCardMat">
	<div class="row">
		<div class="col-md-4">			
			<button mat-raised-button color='primary' class="schDetBut" (click)="openDialog()"><mat-icon>add</mat-icon> Add External Member Details</button>
		</div>
		<div class="col-md-4">
		
		</div>
		<div class="col-md-4">						
			<mat-form-field class="example-full-width instiFacSearch" appearance="outline" style="color: black; width: 330px;">
				<mat-label>Search</mat-label>
				<input matInput placeholder="Search..." [(ngModel)]="filterData" (keyup)=search($event)>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="tableBody">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
                <th><p class="anaTitle">Role</p></th>
								<th><p class="anaTitle">Name</p></th>
								<th><p class="anaTitle">Email</p></th>
								<th><p class="anaTitle">Contact</p></th>
								<th><p class="anaTitle">Organization</p></th>								
								<th><p class="anaTitle">Member</p></th>
								<th><p class="anaTitle">Experience</p></th>
								<th><p class="anaTitle">Qualification</p></th>
								<th><p class="anaTitle">Download</p></th>
								<th><p class="anaTitle">Action</p></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let faculty of external;let i = index">
                <td><p class="anaContent">{{faculty?.role}}</p></td>
								<td><p class="anaContent">{{faculty?.name}}</p></td>
								<td><p class="anaContent">{{faculty?.email}}</p></td>
								<td><p class="anaContent">{{faculty?.mobile}}</p></td>
								<td><p class="anaContent">{{faculty?.organisation}}</p></td>								
								<td><p class="anaContent">{{faculty?.members}}</p></td>
								<td><p class="anaContent">{{faculty?.experience_in_years}}</p></td>
								<td><p class="anaContent">{{faculty?.qualification}}</p></td>
								<td>
									<div *ngIf="faculty?.CV_upload != '0' || faculty?.CV_upload != null || faculty?.CV_upload != ''" class="">
										<a href="{{baseUrl + faculty?.CV_upload}}" target="_blank">
										  <button type="button" class="schDetDown">Download CV</button>
										</a>
									</div>
								</td>
								<td>
									<!-- <div class="form-button-action">
										<a (click)="openEditDialog(faculty)" data-toggle="tooltip" title=""
										  class="btn btn-link btn-primary btn-lg edit1" data-original-title="Edit Task">
										  <i class="fa fa-edit"> Edit</i>
										</a>
									</div> -->
									
									<button mat-icon-button="" class="btn-tbl-edit" (click)="openEditDialog(faculty)"><span class="mat-button-wrapper"><mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">edit</mat-icon></span><span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round"></span><span class="mat-button-focus-overlay"></span></button>
									
									<!-- <div class="form-button-action">
										<a (click)="deleteDialog(faculty)" data-toggle="tooltip" title=""
										  class="btn btn-link btn-danger deleteInst1" data-original-title="Remove">
										  <i class="fa fa-times"> Delete</i>
										</a>
									</div> -->
									
									<button mat-icon-button="" class="btn-tbl-delete" aria-label="delete" (click)="deleteDialog(faculty)">
									  <mat-icon>delete</mat-icon>
									</button>

								</td>
							</tr>				
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	
	<div class="row">
    <div class="col-md-12">
      <mat-paginator #paginator
              showFirstLastButtons
              (page)="nextPage1($event)"
              [length]="total_pages"
              [pageIndex]="frontCurrentPageNo"
              [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
            </mat-paginator>

    </div>
  </div>
</mat-card> 
<!--
  <div *ngIf="external.length<1">
    <div class="card text-center text-white bg-info mb-4 card-shadow">
      <div class="card-body">
        <p class="card-text text-white"><i>No External Member added yet!</i></p>
      </div>
    </div>
  </div> -->
  <!-- </div> -->

  
</div>
