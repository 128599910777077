<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            Add new e-Learning Sessions Organized</h2>
    </div>

	<div class="card">
        <!-- <div class="card-title"><p>Details of e-Learning Sessions Organized</p></div> -->
	</div>

    <form class="instiBankForm" [formGroup]="iicImpactQuestions" (ngSubmit)="onSubmit(iicImpactQuestions.value)">
       <table class="table table-hover">
			<tr>
				<td>
					Title
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
					<mat-label>Session Title</mat-label>

					<input matInput autocomplete="off" placeholder="Title" formControlName="title" type="text"   required>
					<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
					</mat-form-field>
				</td>
			</tr>

			<tr>
				<td>
					Description
				</td>
				<td>
					<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Description</mat-label>

					<textarea rows="2" maxlength="1000" matInput placeholder="Description" formControlName="description" required></textarea>
					<!-- <mat-error *ngIf="mandateForm.controls.inst_address.errors?.required">Address</mat-error> -->
					</mat-form-field>
				</td>
			</tr>

      <tr>
				<td>
				 Session Presentation/Document, If Any
				</td>
				<td>
					<div class="col-12">
            <label class="custom-file-label float-right" for="customFile"
                              *ngIf="!ipr_document">Choose file</label>

            <label class="custom-file-label float-right" for="customFile"
                *ngIf="ipr_document">{{ipr_document[0]['name']}}</label>

            <input #ipr_documentRef type="file" accept="application/pdf"  name="ipr_document"
                (change)="( ipr_document = ipr_documentRef.files )"
                class="custom-file-input float-right" required>
              </div>

				</td>
			</tr>

      <tr *ngIf="document">
        <th>&nbsp;</th>
        <td colspan="3">
            <a href="https://api.mic.gov.in{{document}}" target="_blank">View File</a>
        </td>
      </tr>

			<tr>
				<td>
					Session Theme
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">

                  <mat-label> Theme</mat-label>

                  <mat-select autocomplete="off" placeholder="Theme" formControlName="theme"  required multiple>
                    <mat-option>Select</mat-option>
                    <mat-option value="{{theme.name}}" *ngFor="let theme of programTheme">{{theme.name}}</mat-option>
                  </mat-select>
                  <!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
              </mat-form-field>

				</td>
			</tr>

      <tr>
				<td>
					Organised on
				</td>
				<td>

          <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>Organised on <span class="redMendate">*</span></mat-label>
            <input matInput [matDatepicker]="organised_on" readonly formControlName="organised_on" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="organised_on"></mat-datepicker-toggle>
            <mat-datepicker #organised_on color="primary"></mat-datepicker>
          </mat-form-field>

				</td>
			</tr>

		<tr>
			<td>
				Organised as
			</td>
			<td>
				<mat-form-field appearance="outline" class="w-100">

				<mat-label> Organised as</mat-label>

				<mat-select autocomplete="off" placeholder="" formControlName="iic_activity"  required  (selectionChange)="onIICStatusChange($event)">
				<mat-option value="IIC">IIC Calendar Activity 3.0</mat-option>
        		<mat-option value="IIC4">IIC Calendar Activity 4.0</mat-option>
        		<mat-option value="IIC5">IIC Calendar Activity 5.0</mat-option>
        		<mat-option value="IIC6">IIC Calendar Activity 6.0</mat-option>
				<mat-option value="MIC">MIC Calendar Activity</mat-option>
				<mat-option value="Celebration">Celebration Day Activity</mat-option>
				<mat-option value="SELF">Self Calendar Activity</mat-option>
				</mat-select>
				<!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
			</mat-form-field>
			</td>
		</tr>

		<tr class="ifYes" *ngIf="requireField==1">
			<td>
				Select the activity
			</td>
			<td>
		{{activity_id}}
				<mat-form-field appearance="outline" class="w-100">

				<mat-label> Select the activity </mat-label>

				<mat-select autocomplete="off" placeholder="Select the activity" formControlName="activity_id"  required>
				<mat-option>Select</mat-option>
				<mat-option value="{{activity.id}}" *ngFor="let activity of iicActivity">{{activity.title}}</mat-option>
				</mat-select>
				<!-- <mat-error *ngIf="mandateForm.controls.inst_type.errors?.required">theme is required</mat-error> -->
			</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				Keywords
			</td>
			<td>
				<mat-form-field appearance="outline" class="w-100">
				<mat-label>tags</mat-label>
				<input matInput autocomplete="off" placeholder="tags" formControlName="tags" type="text"   required>
				<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
				</mat-form-field>
			</td>
		</tr>
		<tr>
			<td>
				Video Url
			</td>
			<td>
				<mat-form-field appearance="outline" class="w-100">
				<mat-label>Video Url</mat-label>

				<input matInput autocomplete="off" placeholder="video" formControlName="video" type="text"   required>
				<!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
				</mat-form-field>
			</td>
		</tr>
      	<tr>
				<td>
					Lead Expert's Name
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
            <mat-label>Lead Expert's Name</mat-label>

            <input matInput autocomplete="off" placeholder="Lead Expert's Name" formControlName="expert_name" type="text"   required>
            <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            </mat-form-field>
				</td>
		</tr>

      	<tr>
				<td>
					Lead Expert's Email
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
            <mat-label>Lead Expert's Email</mat-label>

            <input matInput type="email" autocomplete="off" placeholder="Lead Expert's Email" formControlName="expert_email" type="text"   required>
            <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            </mat-form-field>
				</td>
			</tr>

      <tr>
				<td>
					Lead Expert's Contact
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
            <mat-label>Lead Expert's Contact</mat-label>

            <input matInput autocomplete="off" placeholder="Lead Expert's Contact" formControlName="expert_contact" type="text"   required minlength="10"
						maxlength="10" required (keypress)="isNumberKey($event)">
            <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            </mat-form-field>
				</td>
			</tr>

      <tr>
				<td>
					Lead Expert's Designation
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
            <mat-label>Lead Expert's Designation</mat-label>

            <input matInput autocomplete="off" placeholder="Lead Expert's Designation" formControlName="expert_designation" type="text"   required>
            <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            </mat-form-field>
				</td>
			</tr>

      <tr>
				<td>
					Lead Expert's Organization
				</td>
				<td>
					<mat-form-field appearance="outline" class="w-100">
            <mat-label>Lead Expert's Organization</mat-label>
            <input matInput autocomplete="off" placeholder="Lead Expert's Organization" formControlName="expert_org" type="text" required>
            <!-- <mat-error *ngIf="mandateForm.controls.instname.errors?.required">Title is required</mat-error> -->
            </mat-form-field>
				</td>
			</tr>

      <tr>
				<td colspan="2">
          <mat-checkbox class="example-margin" formControlName="agree" value="1">I agree to publish the video for public access.</mat-checkbox>
				</td>
			</tr>

      <tr>
				<td>
					&nbsp;
				</td>
				<td>
					<button mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='iicImpactQuestions.invalid'>Submit</button>
				</td>
			</tr>


	   </table>
	</form>
</div>
