import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';

@Component({
  selector: 'app-evaluator',
  templateUrl: './evaluator.component.html',
  styleUrls: ['./evaluator.component.css']
})
export class InstituteEvaluatorComponent implements OnInit {

  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  iicevaluator:any;

  constructor(private fb: FormBuilder, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.requireField=0;

    this.instituteActivityService.getIicEvaluatorDetails('all').subscribe(
      (data) => this.handleResponse(data)
    );
  }

  handleResponse(data) {
    this.iicevaluator = data['data'];
  }

  onClickEvent(data,type){

    // alert(data);

    if(type=="Edit")
    {
      window.location.href="/institute/evaluator-edit/"+data;
    }
    else if(type=="Email")
    {
      if (confirm("Are you sure you want to send invitation once again?")) {
        this.instituteActivityService.sendEvaluatorDetails(data).subscribe(
          data => this.handleResponse2(data)
        );
      }
    }    
    else
    {
      if (confirm("Are you sure you want to delete?")) {
        this.instituteActivityService.deleteEvaluatorDetails(data).subscribe(
          data => this.handleResponse1(data)
        );
      }
    }
  }

  handleResponse1(data)
  {
    this.toastr.success("Evaluator has been removed successfully.");
    this.instituteActivityService.getIicEvaluatorDetails('all').subscribe(
      (data) => this.handleResponse(data)
    );

  }

  handleResponse2(data)
  {
    this.toastr.success("Invitation has been sent successfully.");
    this.instituteActivityService.getIicEvaluatorDetails('all').subscribe(
      (data) => this.handleResponse(data)
    );

  }

}
