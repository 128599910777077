<div class="row exterCenter" color="primary">
  <h2 class="text-center instStuDetailsTitle"> Add External Member Details</h2>
</div>


<div class="container">
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="externalForm" (ngSubmit)="onSubmit(externalForm.value)">

      <div class="row">

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Assign Roles</mat-label>
            <mat-select formControlName="externalRoles" required (selectionChange)="onMemberRoleChange($event)">
              <mat-option value="Vice President">Vice President</mat-option>
              <mat-option value="Members">Members</mat-option>
              <mat-option *ngIf="this.menteeStatus=='1'" value="From_Mentor_institute">
                From Mentor institute
              </mat-option>
            </mat-select>
            <mat-error *ngIf="externalForm.controls.externalRoles.errors?.required">Role is required</mat-error>
            <!-- <mat-error *ngIf="externalForm.controls.assign_roles.errors?.required">Role is required</mat-error> -->
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <span *ngIf="this.nameField==0">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="externalName" required (keypress)=isCharKey($event)>
            <mat-error *ngIf="externalForm.controls.externalName.errors?.required">Name is required</mat-error>
          </mat-form-field>
        </span>

          <span *ngIf="this.nameField==1">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Name</mat-label>
            <mat-select placeholder="Name" formControlName="externalName" required (selectionChange)="onMemberdataChange($event)">
              <mat-option *ngFor="let mdata of MentorList" [value]="mdata?.Id">
                {{mdata?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="externalForm.controls.externalName.errors?.required">Name is required
            </mat-error>

          </mat-form-field>
        </span>
        </div>


      </div>

      <div class="row">

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="studentsEmail" placeholder="Email" required (keypress)=isSpace($event)>
            <mat-error *ngIf="externalForm.controls.studentsEmail.errors?.required">Email is required</mat-error>
            <mat-error *ngIf="externalForm.controls.studentsEmail.errors?.studentsEmail">Invalid email address
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Contact</mat-label>
            <input matInput formControlName="studentsContact" placeholder="Contact" minlength="10" maxlength="10"
              required (keypress)=isNumberKey($event)>

            <mat-error *ngIf="externalForm.controls.studentsContact.errors?.required">Contact is required</mat-error>
            <mat-error *ngIf="externalForm.controls.studentsContact.errors?.minlength">10 digits mobile number only
            </mat-error>
            <mat-error *ngIf="externalForm.controls.studentsContact.errors?.maxlength">10 digits mobile number only
            </mat-error>
            <mat-error *ngIf="externalForm.controls.studentsContact.errors?.pattern">Enter number only</mat-error>

          </mat-form-field>
        </div>


      </div>


      <div class="row">

        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Designation</mat-label>
            <input matInput formControlName="externalDesignation" placeholder="Designation" required
              (keypress)=isCharKey($event)>
            <mat-error *ngIf="externalForm.controls.externalDesignation.errors?.required">Designation is required
            </mat-error>
          </mat-form-field>
        </div>


        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Organization</mat-label>
            <input matInput formControlName="externalOrganisation" placeholder="Organization" required
              (keypress)=isCharKey($event)>
            <mat-error *ngIf="externalForm.controls.externalOrganisation.errors?.required">Organization is required
            </mat-error>
          </mat-form-field>
        </div>


      </div>

      <div class="row">

        <div fxFlex="50" class="m-2">


          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Qualification</mat-label>
            <mat-select placeholder="Qualification" formControlName="externalQualification" required
              [(ngModel)]="qualificationModel">
              <mat-option value="1">Post Graduate</mat-option>
              <mat-option value="2">Doctorate</mat-option>
              <mat-option value="3">Post Doctorate</mat-option>
              <mat-option value="4">Graduate</mat-option>
              <mat-option value="5">Diploma</mat-option>
            </mat-select>
            <mat-error *ngIf="externalForm.controls.externalQualification.errors?.required">Qualification is required
            </mat-error>

          </mat-form-field>

        </div>



        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Experience in Years</mat-label>
            <input matInput formControlName="externalyears" placeholder="Experience in Years" required
              (keypress)=isDecimal($event)>
            <mat-error *ngIf="externalForm.controls.externalyears.errors?.required">Experience is required</mat-error>
          </mat-form-field>
        </div>



      </div>


      <div class="row">



        <div fxFlex="50" class="m-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Member</mat-label>
            <mat-select formControlName="member" placeholder="Member" required>
              <mat-option value="Bank/Investor">Bank/Investor</mat-option>
              <mat-option value="Expert from nearby Industry/Industry association/ Ecosystem Enablers">Expert from
                nearby Industry/Industry association/ Ecosystem Enablers</mat-option>
              <mat-option value="Start up/ Alumni Entrepreneur">Start up/ Alumni Entrepreneur</mat-option>
              <mat-option value="Patent expert">Patent expert</mat-option>
              <mat-option value="Incubation Centre">Incubation Centre</mat-option>
            </mat-select>
            <mat-error *ngIf="externalForm.controls.member.errors?.required">Member is required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="m-2">
          <mat-form-field class="gender example-full-width" appearance="outline">
            <mat-icon matSuffix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Gender</mat-label>
            <mat-select placeholder="Gender" formControlName="gender" required>
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="externalForm.controls['gender'].hasError('required')">Faculty gender is
              required</mat-error>

          </mat-form-field>
        </div>
      </div>


      <div *ngIf="!showCV">
        <div class="row">
          <div class="col-lg-8 col-md-10">
            <label>CV Upload:</label>
            <mat-error>PDF(max 2MB)</mat-error>
          </div>

          <div class="col-lg-4 col-md-2 float-right">
            <label class="custom-file-label float-right" for="customFile" *ngIf="!externalCV">Choose file</label>
            <label class="custom-file-label float-right" for="customFile"
              *ngIf="externalCV">{{externalCV[0]['name']}}</label>
            <input #externalCVRef type="file" accept="application/pdf" name="externalCV"
              (change)="( externalCV = externalCVRef.files )" class="custom-file-input float-right" required>
          </div>

        </div>
      </div>

      <div *ngIf="showCV">
        <div class="row">
          <a href="{{baseImageUrl + data.CV_upload}}" target="_blank">
            <button type="button" class="btn btn-danger mt-2 mb-2">Download CV</button>
            <button type="button" mat-raised-button color="warn" class="ml-3" (click)="showCV = !showCV">Upload
              CV</button>
          </a>
        </div>
      </div>


      <div>

        <button type="button" mat-raised-button color='warn' (click)='close()'
          class="btn btn-danger instituteBtnClose">Close</button>

        <button *ngIf="show1" mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='externalForm.invalid'>Submit</button>

        <button *ngIf="show" mat-raised-button color='primary' class="btn btn-success float-right"
          [disabled]='externalForm.invalid'>Update</button>
      </div>

    </form>
  </mat-dialog-content>
</div>
