import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

import { NavbarComponent } from './components/shared/navbar/navbar.component';
// import { IicWebNavbarComponent } from './components/shared/iic-web-navbar/iic-web-navbar.component';
import { LoginComponent } from './components/login/login.component';
import { AtlVerificationComponent } from './components/atlverification/atlverification.component';
import { SignupComponent } from './components/signup/signup.component';
import { AtlSignupComponent } from './components/atl-signup/atl-signup.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { JarwisService } from './Services/jarwis.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ToastrModule } from 'ngx-toastr';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { httpInterceptorProviders } from './http-interceptors';
import { PagesComponent } from './components/pages/pages.component';
import { LandingComponent, LandingDialogComponent } from './components/pages/landing/landing.component';
import { FooterComponent } from './components/shared/footer/footer.component';
// import { IicWebFooterComponent } from './components/shared/iic-web-footer/iic-web-footer.component';
import { UsersComponent } from './components/users/users.component';
import { InstituteComponent, InstituteDialog } from './components/users/institute/institute.component';
import { InstituteMyCouncilComponent } from './components/users/institute/institute-my-council/institute-my-council.component';
import { InstituteDetailsComponent } from './components/users/institute/institute-my-council/institute-details/institute-details.component';
import { InstituteFacultyComponent, InstituteDeleteFacultyComponent } from './components/users/institute/institute-my-council/institute-faculty/institute-faculty.component';
import {
  InstituteStudentComponent,
  InstituteStudentDialog,
  InstituteStudentPresidentDialog,
  InstituteDeleteDialogComponent,
} from './components/users/institute/institute-my-council/institute-student/institute-student.component';
import { InstituteExternalComponent, InstituteExternalDeleteComponent } from './components/users/institute/institute-my-council/institute-external/institute-external.component';
import { InstituteCouncilMeetingComponent, InstituteCouncilDeleteComponent } from './components/users/institute/institute-my-council/institute-council-meeting/institute-council-meeting.component';
import {
  InstituteDeclareSemestersComponent,
  InstituteDeclareSemesterDialog,
  InstituteDeclareSemestersDeleteDialogComponent
} from './components/users/institute/institute-my-council/institute-declare-semesters/institute-declare-semesters.component';
import { InstituteSocialMediaComponent } from './components/users/institute/institute-my-council/institute-social-media/institute-social-media.component';
import { MatTabsModule } from '@angular/material/tabs';
import { InstituteIncubationComponent } from './components/users/institute/institute-incubation/institute-incubation.component';
import { InstituteIncubationCentersComponent } from './components/users/institute/institute-incubation/institute-incubation-centers/institute-incubation-centers.component';
import { InstitutePreIncubationCentersComponent } from './components/users/institute/institute-incubation/institute-pre-incubation-centers/institute-pre-incubation-centers.component';
import { InstituteIPRTTOCentersComponent } from './components/users/institute/institute-incubation/institute-ipr-tto-centers/institute-ipr-tto-centers.component';
import { InstituteAllDetailsComponent } from './components/users/institute/institute-incubation/institute-all-details/institute-all-details.component';
import { InstituteInnovationContestComponent } from './components/users/institute/institute-innovation-contest/institute-innovation-contest.component';
import { InstituteActivityComponent } from './components/users/institute/institute-activity/institute-activity.component';
import { InstituteIicImpactComponent } from './components/users/institute/institute-iic-impact/institute-iic-impact.component';
import { InstituteDownloadComponent } from './components/users/institute/institute-download/institute-download.component';
import {
  InstituteNewsCornerComponent,
  InstituteNewsCornerComponentDialog,
  InstituteNewsCornerDeleteDialogComponent,
} from './components/users/institute/institute-news-corner/institute-news-corner.component';
import { InstituteAnnouncementComponent } from './components/users/institute/institute-announcement/institute-announcement.component';
import { InstituteContactUsComponent } from './components/users/institute/institute-contact-us/institute-contact-us.component';
import { InstituteIdeaSubmissionComponent } from './components/users/institute/institute-innovation-contest/institute-idea-submission/institute-idea-submission.component';
import { InstituteIdeaSubmission21Component } from './components/users/institute/institute-yic2021/institute-idea-submission/institute-idea-submission.component';
import { InstituteYuktiRegistrationsComponent } from './components/users/institute/institute-yic2021/institute-yukti-registrations/institute-yukti-registrations.component';
import { InstitutePocSubmissionComponent } from './components/users/institute/institute-innovation-contest/institute-poc-submission/institute-poc-submission.component';
import { InstitutePocSubmission21Component } from './components/users/institute/institute-yic2021/institute-poc-submission/institute-poc-submission.component';
import { InstitutePrototypeSubmission21Component } from './components/users/institute/institute-yic2021/institute-prototype-submission/institute-prototype-submission.component';


import { InstitutePrototypeSubmissionComponent } from './components/users/institute/institute-innovation-contest/institute-prototype-submission/institute-prototype-submission.component';
import {
  InstituteIicCalendarComponent,
  InstituteIicCalendarDialog,
} from './components/users/institute/institute-activity/institute-iic-calendar/institute-iic-calendar.component';
import {
  InstituteMicDrivenComponent,
  InstituteMicCalendarDialog,
} from './components/users/institute/institute-activity/institute-mic-driven/institute-mic-driven.component';

import {
  InstituteCelebrationComponent,
  InstituteCelebrationDialog,
} from './components/users/institute/institute-activity/institute-celebration/institute-celebration.component';

import {
  InstituteSelfDrivenComponent,
  InstituteSelfDrivenDialog,
  InstituteActivitySelfAnnouncmentForm,
  InstituteActivitySelfEditForm
} from './components/users/institute/institute-activity/institute-self-driven/institute-self-driven.component';
import {
  InstituteMyActivityComponent,
  InstituteMyActivityDialog,
} from './components/users/institute/institute-activity/institute-my-activity/institute-my-activity.component';
import { InstituteActivityReportComponent } from './components/users/institute/institute-activity/institute-my-activity/institute-activity-report/institute-activity-report.component';
import { InstituteActivityPrototypeComponent } from './components/users/institute/institute-activity/institute-my-activity/institute-activity-prototype/institute-activity-prototype.component';
import { InstituteActivityTestimonialComponent } from './components/users/institute/testimonial/institute-testimonial-add/institute-testimonial-add.component';
import { InstituteTestimonialComponent } from './components/users/institute/testimonial/testimonial.component';
import { CoordinatorComponent } from './components/users/institute/institute-announcement/coordinator/coordinator.component';
import { NominateCoordinatorComponent } from './components/users/institute/institute-announcement/nominate-coordinator/nominate-coordinator.component';
import { CoordinatorAddComponent } from './components/users/institute/institute-announcement/coordinator/coordinator-add/coordinator-add.component';
import { CoordinatorDialogComponent }from './components/users/institute/institute-announcement/coordinator/coordinator-dialog/coordinator-dialog.component';
import { InstituteDetailsDialogComponent } from './components/users/institute/institute-my-council/institute-details/institute-details-dialog/institute-details-dialog.component';
import { InstituteFacultyDialogComponent } from './components/users/institute/institute-my-council/institute-faculty/institute-faculty-dialog/institute-faculty-dialog.component';
import { InstitutePreIncubationCentersDialogComponent } from './components/users/institute/institute-incubation/institute-pre-incubation-centers/institute-pre-incubation-centers-dialog/institute-pre-incubation-centers-dialog.component';
import { InstituteIPRTTOFacilitiesDialogComponent } from './components/users/institute/institute-incubation/institute-ipr-tto-centers/institute-ipr-tto-centers-dialog/institute-ipr-tto-centers-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { InstituteExternalDialogComponent } from './components/users/institute/institute-my-council/institute-external/institute-external-dialog/institute-external-dialog.component';
import { InstituteSocialMediaDialogComponent } from './components/users/institute/institute-my-council/institute-social-media/institute-social-media-dialog/institute-social-media-dialog.component';
import { InstituteCouncilMeetingDialogComponent } from './components/users/institute/institute-my-council/institute-council-meeting/institute-council-meeting-dialog/institute-council-meeting-dialog.component';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import {
  InstituteRequestLogoComponent,
  InstituteRequestLogoDialog,
} from './components/users/institute/institute-request-logo/institute-request-logo.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InnerNavbarComponent } from './components/shared/inner-navbar/inner-navbar.component';
import { InnerFooterComponent } from './components/shared/inner-footer/inner-footer.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AboutMHRDnnovationComponent } from './components/pages/about-us/about-mhrdnnovation/about-mhrdnnovation.component';
import { AboutMessageComponent } from './components/pages/about-us/about-message/about-message.component';
import { LeadershipTeamComponent } from './components/pages/about-us/leadership-team/leadership-team.component';
import { ContactUsComponent } from './components/pages/about-us/contact-us/contact-us.component';
import { InnovationCouncilComponent } from './components/pages/innovation-council/innovation-council.component';
import { IicComponent } from './components/pages/innovation-council/iic/iic.component';
import { WhyHeisComponent } from './components/pages/innovation-council/why-heis/why-heis.component';
import { IicAdvisoryCommitteeComponent } from './components/pages/innovation-council/iic-advisory-committee/iic-advisory-committee.component';
import { InnovationcontestComponent } from './components/pages/innovation-council/innovationcontest/innovationcontest.component';
import { InnovationAmbassadorComponent } from './components/pages/innovation-council/innovation-ambassador/innovation-ambassador.component';
import { IicRatingComponent } from './components/pages/innovation-council/iic-rating/iic-rating.component';
import { IicTeamComponent } from './components/pages/innovation-council/iic-team/iic-team.component';
import { IicwebinarComponent } from './components/pages/innovation-council/iicwebinar/iicwebinar.component';
import { InnovationRankingComponent } from './components/pages/innovation-ranking/innovation-ranking.component';
import { HackathonComponent } from './components/pages/hackathon/hackathon.component';
import { SihComponent } from './components/pages/hackathon/sih/sih.component';
import { SingaporeIndiaHackathonComponent } from './components/pages/hackathon/singapore-india-hackathon/singapore-india-hackathon.component';
import { StartUpPolicyComponent } from './components/pages/start-up-policy/start-up-policy.component';
import { StartupPolicyComponent } from './components/pages/start-up-policy/startup-policy/startup-policy.component';
import { StartupPolicyCommitteeComponent } from './components/pages/start-up-policy/startup-policy-committee/startup-policy-committee.component';
import { StartupApplicationComponent } from './components/pages/start-up-policy/startup-application/startup-application.component';
import { NominateFacultyForNispComponent } from './components/pages/start-up-policy/nominate-faculty-for-nisp/nominate-faculty-for-nisp.component';
import { LeadershipTalkComponent } from './components/pages/leadership-talk/leadership-talk.component';
import { IfltsUpcomingComponent } from './components/pages/leadership-talk/iflts-upcoming/iflts-upcoming.component';
import { IfltsPastComponent } from './components/pages/leadership-talk/iflts-past/iflts-past.component';
import { MicOutcomeComponent, MicOutcomeDialogComponent } from './components/pages/mic-outcome/mic-outcome.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { GalleryVideoComponent } from './components/pages/gallery/gallery-video/gallery-video.component';
import { GalleryPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo.component';
import { NewsComponent } from './components/pages/gallery/news/news.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InstitutePresidentComponent } from './components/users/institute/institute-my-council/institute-president/institute-president.component';
import { InstituteHoiComponent } from './components/users/institute/institute-my-council/institute-hoi/institute-hoi.component';
import { InstituteNavbarComponent, InstituteMenteeInfoDialogComponent, InstituteMentorInfoDialogComponent } from './components/shared/institute-navbar/institute-navbar.component';
import { InstituteFooterComponent } from './components/shared/institute-footer/institute-footer.component';
import { AnnouncementsComponent } from './components/pages/announcements/announcements.component';
import { AnnouncementsNewComponent } from './components/pages/announcements/announcements-new/announcements-new.component';
import { AnnouncementsOldComponent } from './components/pages/announcements/announcements-old/announcements-old.component';
import { SamadhanComponent } from './components/pages/hackathon/samadhan/samadhan.component';
import { InstituteInnverNvavbarComponent } from './components/shared/institute-innver-nvavbar/institute-innver-nvavbar.component';
import { InstituteInnerFooterComponent } from './components/shared/institute-inner-footer/institute-inner-footer.component';
import { HoiDetailsComponent } from './components/users/institute/institute-profile/hoi-details/hoi-details.component';
import { PresidentDetailsComponent } from './components/users/institute/institute-profile/president-details/president-details.component';
import { InstituteProfileComponent } from './components/users/institute/institute-profile/institute-profile.component';
import { InstitutePocSubmissionDialogComponent } from './components/users/institute/institute-innovation-contest/institute-poc-submission/institute-poc-submission-dialog/institute-poc-submission-dialog.component';
import { InstitutePocSubmissionDialog21Component } from './components/users/institute/institute-yic2021/institute-poc-submission/institute-poc-submission-dialog/institute-poc-submission-dialog.component';
import { InstitutePrototypeSubmissionDialog21Component } from './components/users/institute/institute-yic2021/institute-prototype-submission/institute-prototype-submission-dialog/institute-prototype-submission-dialog.component';
import { InstituteIdeaSubmissionDialogComponent } from './components/users/institute/institute-innovation-contest/institute-idea-submission/institute-idea-submission-dialog/institute-idea-submission-dialog.component';
import { InstitutePrototypeSubmissionDialogComponent } from './components/users/institute/institute-innovation-contest/institute-prototype-submission/institute-prototype-submission-dialog/institute-prototype-submission-dialog.component';
import { InstituteSuggestionComponent } from './components/users/institute/institute-suggestion/institute-suggestion.component';
import { InstituteSuggestionDialogComponent } from './components/users/institute/institute-suggestion/institute-suggestion-dialog/institute-suggestion-dialog.component';
import { ReplyDialogComponent } from './components/users/institute/institute-suggestion/reply-dialog/reply-dialog.component';
import { InstitutePresidentDialogComponent } from './components/users/institute/institute-my-council/institute-president/institute-president-dialog/institute-president-dialog.component';
import { InstituteHoiDialogComponent } from './components/users/institute/institute-my-council/institute-hoi/institute-hoi-dialog/institute-hoi-dialog.component';
import { ReplyListComponent } from './components/users/institute/institute-suggestion/reply-list/reply-list.component';
import { InstituteActivitySecondFormComponent } from './components/users/institute/institute-activity/institute-my-activity/institute-activity-second-form/institute-activity-second-form.component';
import { ReportFormComponent } from './components/users/institute/institute-activity/institute-my-activity/institute-activity-report-form/report-form.component';
import { ResetPasswordDialogComponent } from './components/users/institute/institute-profile/reset-password-dialog/reset-password-dialog.component';
import { InstituteNominationComponent } from './components/users/institute/institute-innovation-contest/institute-nomination/institute-nomination.component';
import { InstituteDashboardComponent,DashboardDialogComponent} from './components/users/institute/institute-dashboard/institute-dashboard.component';
import { DashboardChartsComponent } from './components/users/institute/institute-dashboard/dashboard-charts/dashboard-charts.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { GaugeChartModule } from 'angular-gauge-chart'
import { DashboardCardsComponent } from './components/users/institute/institute-dashboard/dashboard-cards/dashboard-cards.component';
import { InstituteDashboard1819Component } from './components/users/institute/institute-dashboard1819/institute-dashboard1819.component';
import { Dashboard1819CardsComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-cards/dashboard1819-cards.component';
import { Dashboard1819ChartsComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-charts/dashboard1819-charts.component';
import { Dashboard1819ActivityListComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-activity-list/dashboard1819-activity-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Dashboard1819AnnouncementsComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-announcements/dashboard1819-announcements.component';
import { Dashboard1819MyRankingComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-my-ranking/dashboard1819-my-ranking.component';
import { Dashboard1819NotificationsComponent, NotificationDialog } from './components/users/institute/institute-dashboard1819/dashboard1819-notifications/dashboard1819-notifications.component';
import { Dashboard1819StarPerformerComponent } from './components/users/institute/institute-dashboard1819/dashboard1819-star-performer/dashboard1819-star-performer.component';
import { DashboardActivityListComponent } from './components/users/institute/institute-dashboard/dashboard-activity-list/dashboard-activity-list.component';
import { IncubationInchargeDialogComponent } from './components/users/institute/institute-incubation/institute-incubation-centers/incubation-incharge-dialog/incubation-incharge-dialog.component';
import { MatChipsModule } from '@angular/material/chips';
import { BifurcationScoreComponent } from './components/users/institute/institute-profile/bifurcation-score/bifurcation-score.component';
import { CertificatesComponent } from './components/users/institute/institute-profile/certificates/certificates.component';
import { AfterLoginAmbassadorService } from './Services/after-login.service';
import { InstituteActivityCountDialogComponent } from './components/users/institute/institute-activity/institute-activity-count-dialog/institute-activity-count-dialog.component';
import { InstituteAmbassadorComponent } from './components/users/institute/institute-ambassador/institute-ambassador.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { LastLoginDialogComponent } from './components/users/institute/institute-profile/last-login-dialog/last-login-dialog.component';
import { OnlineRegistrationComponent } from './components/pages/online-registration/online-registration.component';
import { RegistrationOnlineSessionComponent } from './components/pages/registration-online-session/registration-online-session.component';
import { OnlineSessionLoginComponent } from './components/pages/online-session-login/online-session-login.component';
import { OnlineSessionDetailsComponent } from './components/pages/online-session-details/online-session-details.component';
import { OnlineSessionVideoComponent, OnlineSessionVideoDialogComponent } from './components/pages/online-session-video/online-session-video.component';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { IicWebNavbarComponent } from './components/shared/iic-web-navbar/iic-web-navbar.component';
import { IicWebFooterComponent } from './components/shared/iic-web-footer/iic-web-footer.component';
import { IicaboutMicComponent } from './components/pages/about-us/iicabout-mic/iicabout-mic.component';
import { IicadvisoryCommitteeComponent } from './components/pages/about-us/iicadvisory-committee/iicadvisory-committee.component';
import { IicImplementationTeamComponent } from './components/pages/about-us/iic-implementation-team/iic-implementation-team.component';
import { IicNationalInnovationContestComponent } from './components/pages/iic-national-innovation-contest/iic-national-innovation-contest.component';
import { PocContest2019Component } from './components/pages/iic-national-innovation-contest/poc-contest2019/poc-contest2019.component';
import { InnovationConest2020Component } from './components/pages/iic-national-innovation-contest/innovation-conest2020/innovation-conest2020.component';
import { IicInnovationAmbassadorComponent } from './components/pages/iic-innovation-ambassador/iic-innovation-ambassador.component';
import { IicIncubatorstabComponent } from './components/pages/iic-incubatorstab/iic-incubatorstab.component';
import { IncubatorsComponent } from './components/pages/iic-incubatorstab/incubators/incubators.component';
import { PreincubatorsComponent } from './components/pages/iic-incubatorstab/preincubators/preincubators.component';
import { IicOnlineSessionsComponent } from './components/pages/iic-online-sessions/iic-online-sessions.component';
import { IicOnlineSessionsDetailsComponent } from './components/pages/iic-online-sessions-details/iic-online-sessions-details.component';
import { IicAmbassadorProfileComponent } from './components/pages/iic-ambassador-profile/iic-ambassador-profile.component';
import { ImpactLectureSeriesComponent } from './components/pages/impact-lecture-series/impact-lecture-series.component';
import { Iic201920Component } from './components/pages/impact-lecture-series/iic201920/iic201920.component';
import { Iic202021Component } from './components/pages/impact-lecture-series/iic202021/iic202021.component';
import { Iic202021CalenderComponent } from './components/pages/iic202021-calender/iic202021-calender.component';
import { InternationalExposureComponent } from './components/pages/international-exposure/international-exposure.component';
import { IndiaCanadaComponent } from './components/pages/international-exposure/india-canada/india-canada.component';
import { IndiaSouthKoreaComponent } from './components/pages/international-exposure/india-south-korea/india-south-korea.component';
import { MaterialTableComponent } from './components/pages/material-table/material-table.component';
import { IicDashboard1819Component } from './components/pages/iic-dashboard1819/iic-dashboard1819.component';
import { IicDashboard1819ChartsComponent } from './components/pages/iic-dashboard1819/iic-dashboard1819-charts/iic-dashboard1819-charts.component';
import { IicDashboard1819CardsComponent } from './components/pages/iic-dashboard1819/iic-dashboard1819-cards/iic-dashboard1819-cards.component';
import { IicDashboard1920Component } from './components/pages/iic-dashboard1920/iic-dashboard1920.component';
import { IicDashboard1920ChartsComponent } from './components/pages/iic-dashboard1920/iic-dashboard1920-charts/iic-dashboard1920-charts.component';
import { IicContactUsComponent } from './components/pages/iic-contact-us/iic-contact-us.component';
import { IicDownloadComponent } from './components/pages/iic-download/iic-download.component';
import { IicNotificationComponent } from './components/pages/iic-notification/iic-notification.component';
import { IicRatings1819Component } from './components/pages/iic-ratings1819/iic-ratings1819.component';
import { MentorMenteeProgramComponent } from './components/pages/mentor-mentee-program/mentor-mentee-program.component';
import { IicRatings1920Component } from './components/pages/iic-ratings1920/iic-ratings1920.component';
import { IicRatings1920ListComponent } from './components/pages/iic-ratings1920-list/iic-ratings1920-list.component';
import { OnlineSessionNavbarComponent } from './components/pages/online-session-navbar/online-session-navbar.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { RepositoryDialogComponent } from './components/users/institute/institute-activity/institute-my-activity-2021-22/repository-dialog/repository-dialog.component';
import { ReferralInstitutesDialogComponent } from './components/users/institute/institute-activity/institute-my-activity-2021-22/referral-institutes-dialog/referral-institutes-dialog.component';
import { OsiaRequestResetComponent } from './components/password/osia-request-reset/osia-request-reset.component';
import { InstitutesListDialogComponent } from './components/pages/iic-national-innovation-contest/innovation-conest2020/institutes-list-dialog/institutes-list-dialog.component';
import { InstituteMentoringSessionComponent } from './components/users/institute/institute-innovation-contest/institute-mentoring-session/institute-mentoring-session.component';
import { IicWebMainNavbarComponent } from './components/shared/iic-web-main-navbar/iic-web-main-navbar.component';
import { MemberCertificateListComponent } from './components/users/institute/institute-profile/certificates/member-certificate-list/member-certificate-list.component';
import { Members1819Component } from './components/users/institute/institute-profile/certificates/members1819/members1819.component';
import { MembersCertificates1819Component } from './components/shared/members-certificates1819/members-certificates1819.component';
import { InstituteListComponent } from './components/pages/institute-list/institute-list.component';
import { CouncilDetailComponent } from './components/pages/institute-list/council-detail/council-detail.component';
import { SesssionListComponent } from './components/pages/sesssion-list/sesssion-list.component';
import { InstituteSchemesComponent } from './components/users/institute/institute-schemes/institute-schemes.component';
import { NominationsComponent } from './components/users/institute/institute-schemes/nominations/nominations.component';
import { ImpactLecturesComponent } from './components/users/institute/institute-schemes/impact-lectures/impact-lectures.component';
import { AddDialogImpactComponent } from './components/users/institute/institute-schemes/impact-lectures/add-dialog-impact/add-dialog-impact.component';
import { ExportFileComponent } from './components/users/institute/institute-my-council/export-file/export-file.component';
import { InstituteMentorMenteeComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/institute-mentor-mentee.component';
import { MentorNavbarComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/mentor-navbar.component';
import { SelectMenterMenteeFormComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/select-menter-mentee-form/select-menter-mentee-form.component';
import { InstituteSuggestionNewComponent } from './components/users/institute/institute-suggestion-new/institute-suggestion-new.component';
import { Dashboard2021Component } from './components/pages/dashboard2021/dashboard2021.component';
import { InstituteDashboard2021Component } from './components/users/institute/institute-dashboard2021/institute-dashboard2021.component';
import { Cards2021Component } from './components/users/institute/institute-dashboard2021/cards2021/cards2021.component';
import { Charts2021Component } from './components/users/institute/institute-dashboard2021/charts2021/charts2021.component';
import { Downloads2021Component } from './components/users/institute/downloads2021/downloads2021.component';
import { SuggestDialogComponent } from './components/users/institute/institute-suggestion-new/suggest-dialog/suggest-dialog.component';
import { ActivityProposalComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-proposal.component';
import { ActivityProposalDialogComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-proposal-dialog/activity-proposal-dialog.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
//import { InnovationComponent } from './components/innovation/innovation.component';
import { MentorMenteeProgramNewComponent } from './components/pages/mentor-mentee-program-new/mentor-mentee-program-new.component';
import { NominateForm2DialogComponent } from './components/users/institute/institute-schemes/impact-lectures/nominate-form2/nominate-form2-dialog/nominate-form2-dialog.component';
import { NominationsDialogComponent } from './components/users/institute/institute-schemes/nominations/nominations-dialog/nominations-dialog.component';
// import { TimePickerComponent } from '../time-picker/time-picker.component';
// import { MatTimePickerDirective } from './time-picker/mat-timepicker.directive';
// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {NominateForm1Component} from './components/users/institute/institute-schemes/impact-lectures/nominate-form1/nominate-form1.component'
import{NominateForm2Component} from './components/users/institute/institute-schemes/impact-lectures/nominate-form2/nominate-form2.component';
import{NominateForm3Component} from './components/users/institute/institute-schemes/impact-lectures/nominate-form3/nominate-form3.component';
import{ImpactLecturesReportFormComponent} from './components/users/institute/institute-schemes/impact-lectures/impact-lectures-report-form/impact-lectures-report-form.component';
import{ImpactSoeFormComponent} from './components/users/institute/institute-schemes/impact-lectures/impact-soe-form/impact-soe-form.component';
import { IicInnovationAmbassadorNewComponent } from './components/pages/iic-innovation-ambassador-new/iic-innovation-ambassador-new.component';
import { IicImpactLectureSeriesNewComponent } from './components/pages/iic-impact-lecture-series-new/iic-impact-lecture-series-new.component';
import { InstituteStageIEvaluationComponent } from './components/users/institute/institute-innovation-contest/institute-stage-i-evaluation/institute-stage-i-evaluation.component';
import { InstituteStageIIEvaluationComponent } from './components/users/institute/institute-innovation-contest/institute-stage-ii-evaluation/institute-stage-ii-evaluation.component';
import { InstituteYic2021Component } from './components/users/institute/institute-yic2021/institute-yic2021.component';
import { InstituteEvaluatorComponent } from './components/users/institute/institute-yic2021/evaluator/evaluator.component';
import { InstituteEvaluatorAddComponent } from './components/users/institute/institute-yic2021/evaluator/evaluator-add/evaluator-add.component';
import { InstituteIdeaSubmissionDialog21Component } from './components/users/institute/institute-yic2021/institute-idea-submission/institute-idea-submission-dialog/institute-idea-submission-dialog.component';
import { InstituteEvaluatorAssignDialogComponent } from './components/users/institute/institute-yic2021/institute-evaluator-assign-dialog/institute-evaluator-assign-dialog.component';
import { InstituteBankFormComponent } from './components/users/institute/institute-bank-form/institute-bank-form.component';
import { ActivityForm1Component } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-form1/activity-form1.component';
import { ActivityReportForm1Component } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-report-form1/activity-report-form1.component';
import { ActivityReportForm2Component } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-report-form2/activity-report-form2.component';
import { ActivityForm2Component } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-form2/activity-form2.component';
import { ActivityForm3Component } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-form3/activity-form3.component';
import { ActivityForm4Component } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-form4/activity-form4.component';
import { ActivityForm5Component } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-form5/activity-form5.component';
import { InstitutesListComponent } from './components/users/institute/institutes-list/institutes-list.component';
import { ActivityScheduleComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-schedule/activity-schedule.component';
import { NewIaRequestComponent } from './components/users/institute/institute-schemes/new-ia-request/new-ia-request.component';
import { CurrentYearPerformanceComponent } from './components/users/institute/institute-profile/bifurcation-score/current-year-performance/current-year-performance.component';
import { PreviousYearPerformanceComponent } from './components/users/institute/institute-profile/bifurcation-score/previous-year-performance/previous-year-performance.component';
import { AcademicYearPerformance202122 } from './components/users/institute/institute-profile/bifurcation-score/academic-year-performance-2021-22/academic-year-performance-2021-22.component';
import { OnlineSessionListComponent } from './components/users/institute/online-session-list/online-session-list.component';
import { JoinUsWhatsappComponent } from './components/users/institute/join-us-whatsapp/join-us-whatsapp.component';
import { InstituteIicSubmissionComponent } from './components/users/institute/institute-iic-submission/institute-iic-submission.component';
import { IicOutcomeComponent } from './components/pages/iic-outcome/iic-outcome.component';
import { IicImpactDashboardComponent } from './components/pages/iic-impact-dashboard/iic-impact-dashboard.component';
import { Iic202122CalenderComponent } from './components/pages/iic202122-calender/iic202122-calender.component';
import { InstituteStageIIIEvaluationComponent } from './components/users/institute/institute-innovation-contest/institute-stage-iii-evaluation/institute-stage-iii-evaluation.component';
import { Iic4ODialogComponent } from './components/users/institute/institute-dashboard2021/iic4o-dialog/iic4o-dialog.component';
import { Iic4ODialogComponent2 } from './components/users/institute/institute-dashboard2021/iic4o-dialog2/iic4o-dialog2.component';
import { IicImpactDashboardStateWiseComponent } from './components/pages/iic-impact-dashboard-statewise/iic-impact-dashboard-statewise.component';
import { InstituteIicAkamComponent } from './components/users/institute/institute-iic-akam/institute-iic-akam.component';
import { InstituteIicAkamDialogComponent } from './components/users/institute/institute-iic-akam/institute-iic-akam-dialog/institute-iic-akam-dialog.component';


import {
  InstituteMyActivity202122Component,
  InstituteMyActivity202122Dialog,
} from './components/users/institute/institute-activity/institute-my-activity-2021-22/institute-my-activity-2021-22.component';

import {
  InstituteMyActivity202223Component,
  InstituteMyActivity202223Dialog,
} from './components/users/institute/institute-activity/institute-my-activity-2022-23/institute-my-activity-2022-23.component';

import { 
  InstituteMyActivity202324Component,
  InstituteMyActivity202324Dialog
 } from './components/users/institute/institute-activity/institute-my-activity202324/institute-my-activity202324.component';
import {
  InstituteAtalSchoolComponent,
  InstituteAtalSchoolDialog,
} from './components/users/institute/institute-atal-school/institute-atal-school.component';

import {
  InstituteAtalMentorScheduleComponent
} from './components/users/institute/institute-atal-mentor-schedule/institute-atal-mentor-schedule.component';

import { RepositoryComponent } from './components/users/institute/repository/repository.component';
import { IicRatings2021Component } from './components/pages/iic-ratings2021/iic-ratings2021.component';
import { IicRatings2022Component } from './components/pages/iic-ratings2022/iic-ratings2022.component';
import { IicRatings2021DetailsComponent } from './components/pages/iic-ratings2021-details/iic-ratings2021-details.component';
import { IicRatingDialogComponent } from './components/pages/iic-ratings2021-details/iic-rating-dialog/iic-rating-dialog.component';
import { IicRating2022DialogComponent } from './components/pages/iic-ratings2022/iic-rating-2022-dialog/iic-rating-2022-dialog.component';
import { IicRatings2021Details1Component } from './components/pages/iic-ratings2021-details1/iic-ratings2021-details1.component';
import { IicRatings2021Details2Component } from './components/pages/iic-ratings2021-details2/iic-ratings2021-details2.component';
import { IicRatings2021Details3Component } from './components/pages/iic-ratings2021-details3/iic-ratings2021-details3.component';
import { RepositoryAddComponent } from './components/users/institute/repository/repository-add/repository-add.component';
import { IicRepositoryListComponent } from './components/pages/iic-repository-list/iic-repository-list.component';
import { IicMentorMenteeListComponent } from './components/pages/iic-mentor-mentee-list/iic-mentor-mentee-list.component';
import { IicVolunteerMentorMenteeListComponent } from './components/pages/iic-volunteer-mentor-mentee-list/iic-volunteer-mentor-mentee-list.component';
import { ActivityExpensesComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-expenses/activity-expenses.component';

import { IicRatings2022Details1Component } from './components/pages/iic-ratings2022-details1/iic-ratings2022-details1.component';
import { IicRatings2022Details2Component } from './components/pages/iic-ratings2022-details2/iic-ratings2022-details2.component';
import { IicRatings2022Details3Component } from './components/pages/iic-ratings2022-details3/iic-ratings2022-details3.component';
import { IicRatings2022Details4Component } from './components/pages/iic-ratings2022-details4/iic-ratings2022-details4.component';
import { IicRatings2022Details5Component } from './components/pages/iic-ratings2022-details5/iic-ratings2022-details5.component';
import { IicRatings2022Details6Component } from './components/pages/iic-ratings2022-details6/iic-ratings2022-details6.component';
import { IicRatings2022Details7Component } from './components/pages/iic-ratings2022-details7/iic-ratings2022-details7.component';
import { YuktiChallengesComponent } from './components/users/institute/yukti-challenges/yukti-challenges.component';
import { YuktiChallengesAddComponent } from './components/users/institute/yukti-challenges/yukti-challenges-add/yukti-challenges-add.component';

import {AtalActivityProposalComponent } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-proposal.component';
import {AtalActivityForm1Component } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-form1/atal-activity-form1.component';
import {AtalActivityFormGlobalComponent } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-form-global/atal-activity-form-global.component';
import {AtalActivityForm2Component } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-form2/atal-activity-form2.component';
import {AtalActivityForm3Component } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-form3/atal-activity-form3.component';
import {AtalActivityForm4Component } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-form4/atal-activity-form4.component';
import {AtalActivityProposalDialogComponent } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atal-activity-proposal-dialog/atal-activity-proposal-dialog.component';

import { InstituteReferalProgramComponent } from './components/users/institute/institute-referal-program/institute-referal-program.component';
import { InstituteReferalProgramAddComponent } from './components/users/institute/institute-referal-program/institute-referal-program-add/institute-referal-program-add.component';
import { InstituteReferalProgramEditComponent } from './components/users/institute/institute-referal-program/institute-referal-program-edit/institute-referal-program-edit.component';

import { InstituteAffiliateProgramComponent } from './components/users/institute/institute-affiliate-program/institute-affiliate-program.component';
import { InstituteAffiliateProgramAddComponent } from './components/users/institute/institute-affiliate-program/institute-affiliate-program-add/institute-affiliate-program-add.component';
import { InstituteAffiliateProgramAddDialog } from './components/users/institute/institute-affiliate-program/institute-affiliate-program-add-dialog/institute-affiliate-program-add-dialog.component';
import { InstituteAffiliateProgramEditComponent } from './components/users/institute/institute-affiliate-program/institute-affiliate-program-edit/institute-affiliate-program-edit.component';

import { InstituteAffiliateProgramPendingComponent } from './components/users/institute/institute-affiliate-program-pending/institute-affiliate-program-pending.component';


import { InstituteInnovationAddComponent } from './components/users/institute/institute-yic2021/institute-innovation-add/institute-innovation-add.component';
import { InstituteInnovationTeamDetailsComponent } from './components/users/institute/institute-yic2021/institute-innovation-team-details/institute-innovation-team-details.component';

import {InstituteDashboard2022Component} from './components/users/institute/institute-dashboard2022/institute-dashboard2022.component';
import { Cards2022Component } from './components/users/institute/institute-dashboard2022/cards2022/cards2022.component';
import { Charts2022Component } from './components/users/institute/institute-dashboard2022/charts2022/charts2022.component';
import {InstituteDashboard2023Component} from './components/users/institute/institute-dashboard2023/institute-dashboard2023.component';
import { Cards2023Component } from './components/users/institute/institute-dashboard2023/cards2023/cards2023.component';
import { Charts2023Component } from './components/users/institute/institute-dashboard2023/charts2023/charts2023.component';
import { Cards2024Component } from './components/users/institute/institute-dashboard2024/cards2024/cards2024.component';
import { Charts2024Component } from './components/users/institute/institute-dashboard2024/charts2024/charts2024.component';
import { Downloads2022Component } from './components/users/institute/downloads2022/downloads2022.component';
import { Downloads2023Component } from './components/users/institute/downloads2023/downloads2023.component';
import { ActivityReportFormQpmComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-report-form-qpm/activity-report-form-qpm.component';
import { ActivityReportFormPmimComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/activity-report-form-pmim/activity-report-form-pmim.component';
import { InstituteMmDashboardComponent } from './components/users/institute/institute-schemes/institute-mm-dashboard/institute-mm-dashboard.component';

import { InstituteIdeaEvaluationComponent } from './components/users/institute/institute-yic2021/institute-idea-evaluation/institute-idea-evaluation.component';
import { InstituteInnovationEvaluationComponent } from './components/users/institute/institute-yic2021/institute-innovation-evaluation/institute-innovation-evaluation.component';
import { InstituteStartupEvaluationComponent } from './components/users/institute/institute-yic2021/institute-startup-evaluation/institute-startup-evaluation.component';
import { SelfDriveActivityAddComponent } from './components/users/institute/institute-activity/institute-my-activity/self-drive-activity-add/self-drive-activity-add.component';
import { SihGalleryPhotoComponent } from './components/pages/gallery/gallery-photo/sih-gallery-photo/sih-gallery-photo.component';
import { PhotoGalleryAhmedabadComponent } from './components/pages/gallery/gallery-photo/photo-gallery-ahmedabad/photo-gallery-ahmedabad.component';
import { PhotoGalleryBangaloreComponent } from './components/pages/gallery/gallery-photo/photo-gallery-bangalore/photo-gallery-bangalore.component';
import { PhotoGalleryChandigarhComponent } from './components/pages/gallery/gallery-photo/photo-gallery-chandigarh/photo-gallery-chandigarh.component';
import { PhotoGalleryGuwahatiComponent } from './components/pages/gallery/gallery-photo/photo-gallery-guwahati/photo-gallery-guwahati.component';
import { PhotoGalleryHyderabadComponent } from './components/pages/gallery/gallery-photo/photo-gallery-hyderabad/photo-gallery-hyderabad.component';
import { PhotoGalleryJaipurComponent } from './components/pages/gallery/gallery-photo/photo-gallery-jaipur/photo-gallery-jaipur.component';
import { PhotoGalleryKolkataComponent } from './components/pages/gallery/gallery-photo/photo-gallery-kolkata/photo-gallery-kolkata.component';
import { PhotoGalleryKochiComponent } from './components/pages/gallery/gallery-photo/photo-gallery-kochi/photo-gallery-kochi.component';
import { PhotoGalleryLucknowComponent } from './components/pages/gallery/gallery-photo/photo-gallery-lucknow/photo-gallery-lucknow.component';
import { PhotoGalleryMaduraiComponent } from './components/pages/gallery/gallery-photo/photo-gallery-madurai/photo-gallery-madurai.component';
import { PhotoGalleryNagpurComponent } from './components/pages/gallery/gallery-photo/photo-gallery-nagpur/photo-gallery-nagpur.component';
import { PhotoGalleryPuneComponent } from './components/pages/gallery/gallery-photo/photo-gallery-pune/photo-gallery-pune.component';
import { PhotoGalleryVisakhapatnamComponent } from './components/pages/gallery/gallery-photo/photo-gallery-visakhapatnam/photo-gallery-visakhapatnam.component';
import { IaActivityReportComponent } from './components/users/institute/ia-activity-report/ia-activity-report.component';
import { IaActivityReportInternalComponent } from './components/users/institute/ia-activity-report/ia-activity-report-internal/ia-activity-report-internal.component';
import { IaActivityReportExternalComponent } from './components/users/institute/ia-activity-report/ia-activity-report-external/ia-activity-report-external.component';
import { IaActivityReportDetailsDialogComponent } from './components/users/institute/ia-activity-report/ia-activity-report-details-dialog/ia-activity-report-details-dialog.component';
import { IaActivityReportExternalDetailsDialogComponent } from './components/users/institute/ia-activity-report/ia-activity-report-external-details-dialog/ia-activity-report-external-details-dialog.component';
import { PhotoGalleryBhopalComponent } from './components/pages/gallery/gallery-photo/photo-gallery-bhopal/photo-gallery-bhopal.component';
import { EvaluatorEditComponent } from './components/users/institute/institute-yic2021/evaluator/evaluator-edit/evaluator-edit.component';
import { InstituteEvaluationDetailsComponent } from './components/users/institute/institute-yic2021/institute-evaluation-details/institute-evaluation-details.component';
import { IicAchievementComponent } from './components/pages/iic-achievement/iic-achievement.component';
import { InstituteTeamLeadVerifyDialogComponent } from './components/users/institute/institute-yic2021/institute-team-lead-verify-dialog/institute-team-lead-verify-dialog.component';
import { PosterChennaiComponent } from './components/pages/poster/poster-chennai/poster-chennai.component';
import { PosterComponent } from './components/pages/poster/poster.component';
import { PosterBengaluruComponent } from './components/pages/poster/poster-bengaluru/poster-bengaluru.component';
import { PosterChandigarhComponent } from './components/pages/poster/poster-chandigarh/poster-chandigarh.component';
import { PosterDindigulComponent } from './components/pages/poster/poster-dindigul/poster-dindigul.component';
import { PosterJaipurComponent } from './components/pages/poster/poster-jaipur/poster-jaipur.component';
import { PosterKochiComponent } from './components/pages/poster/poster-kochi/poster-kochi.component';
import { PosterLucknowComponent } from './components/pages/poster/poster-lucknow/poster-lucknow.component';
import { PosterGalleryComponent } from './components/pages/poster/poster-gallery/poster-gallery.component';
import { AtlUserDashboardComponent } from './components/users/institute/atl-user-dashboard/atl-user-dashboard.component';
import { AtlHomeDashboardComponent } from './components/users/institute/atl-home-dashboard/atl-home-dashboard.component';
import { AtlMentorOfChangeComponent } from './components/users/institute/atl-mentor-of-change/atl-mentor-of-change.component';
import { AtlYuktiSubmissionComponent } from './components/users/institute/atl-yukti-submission/atl-yukti-submission.component';
import { AtlYuktiSubmissionAddComponent } from './components/users/institute/atl-yukti-submission/atl-yukti-submission-add/atl-yukti-submission-add.component';
import { StudentInnovationVideoComponent } from './components/pages/student-innovation-video/student-innovation-video.component';
import { AtlMenteeActivityComponent } from './components/users/institute/atl-user-dashboard/atl-mentee-activity/atl-mentee-activity.component';
import { InstituteStateIicListComponent } from './components/users/institute/institute-state-iic-list/institute-state-iic-list.component';
import { SubiicComponent } from './components/users/institute/subiic/subiic.component';
import { SubiicAddComponent } from './components/users/institute/subiic/subiic-add/subiic-add.component';
import { Iic202223CalenderComponent } from './components/pages/iic202223-calender/iic202223-calender.component';
import { CollaborationComponent } from './components/users/institute/collaboration/collaboration.component';
import { TeachingLearningComponent } from './components/users/institute/teaching-learning/teaching-learning.component';
import { TeachingLearning2Component } from './components/users/institute/teaching-learning2/teaching-learning2.component';
import { CollaborationAddComponent } from './components/users/institute/collaboration/collaboration-add/collaboration-add.component';
import { TeachingLearningAddComponent } from './components/users/institute/teaching-learning/teaching-learning-add/teaching-learning-add.component';
import { TeachingNonacademicAddComponent } from './components/users/institute/teaching-learning/teaching-nonacademic-add/teaching-nonacademic-add.component';
import { SubiicExistingComponent } from './components/users/institute/subiic/subiic-existing/subiic-existing.component';
import { IicRatings2022Details8Component } from './components/pages/iic-ratings2022-details8/iic-ratings2022-details8.component';
import { TrainingStatusComponent } from './components/users/institute/institute-schemes/training-status/training-status.component';
import { IaActivityReportTrainingComponent } from './components/users/institute/ia-activity-report/ia-activity-report-training/ia-activity-report-training.component';
import { InnovationContestComponent } from './components/users/institute/yukti-challenges/innovation-contest/innovation-contest.component';
import { ViewSubmissionComponent } from './components/users/institute/yukti-challenges/view-submission/view-submission.component';
import { ActivityMenteeDialogComponent } from './components/users/institute/atl-user-dashboard/atl-mentee-activity/activity-mentee-dialog/activity-mentee-dialog.component';
import { AtlMentorDetailComponent } from './components/users/institute/atl-mentor-detail/atl-mentor-detail.component';
import { AtlProfileDetailComponent } from './components/users/institute/atl-profile-detail/atl-profile-detail.component';
import { AtlContactUsComponent } from './components/users/institute/atl-contact-us/atl-contact-us.component';
import { AtlAnnouncementDetailComponent } from './components/users/institute/atl-announcement-detail/atl-announcement-detail.component';
import { AtlIaMemberNominationListComponent } from './components/users/institute/atl-ia-member-nomination-list/atl-ia-member-nomination-list.component';
import { NominatedAtlCoordinatorComponent } from './components/users/institute/nominated-atl-coordinator/nominated-atl-coordinator.component';
import { AddNominatedCoordinatorComponent } from './components/users/institute/nominated-atl-coordinator/add-nominated-coordinator/add-nominated-coordinator.component';
import { NominatedIicAtlCoordinatorListComponent } from './components/users/institute/nominated-iic-atl-coordinator-list/nominated-iic-atl-coordinator-list.component';
import { NominatedIicCoordinatorListComponent } from './components/users/institute/nominated-iic-coordinator-list/nominated-iic-coordinator-list.component';
import { IicReportDownloadComponent } from './components/users/institute/iic-report-download/iic-report-download.component';
import { AtlActivityViewSubmissionComponent } from './components/users/institute/institute-atal-mentor-schedule/atal-activity-proposal/atl-activity-view-submission/atl-activity-view-submission.component';
import { AtlIaMentorDetailComponent } from './components/users/institute/atl-ia-mentor-detail/atl-ia-mentor-detail.component';
import { AtlLoginDialogComponent } from './components/users/institute/atl-user-dashboard/atl-login-dialog/atl-login-dialog.component';
import { AtlInviteIicComponent } from './components/users/institute/atl-invite-iic/atl-invite-iic.component';
import { IaActivityReportTrainingDialogComponent } from './components/users/institute/ia-activity-report/ia-activity-report-training-dialog/ia-activity-report-training-dialog.component';
import { Downloads2020Component } from './components/users/institute/downloads2020/downloads2020.component';
import { YuktiNominatedChallengesComponent } from './components/users/institute/yukti-nominated-challenges/yukti-nominated-challenges.component';
import { YirDashboardComponent } from './components/users/institute/yir-dashboard/yir-dashboard.component';
import { Ntw2023Component } from './components/pages/gallery/gallery-photo/ntw2023/ntw2023.component';
import { NationalTechnologyWeekComponent } from './components/pages/national-technology-week/national-technology-week.component';
import { InstituteChallengeNominationComponent } from './components/users/institute/institute-challenge-nomination/institute-challenge-nomination.component';
import { YuktiInstituteChallengesComponent } from './components/users/institute/yukti-challenges/yukti-institute-challenges/yukti-institute-challenges.component';
import { IicImpactLectureSeries2023Component } from './components/pages/iic-impact-lecture-series2023/iic-impact-lecture-series2023.component';
import { AtlInstituteListComponent } from './components/users/institute/atl-institute-list/atl-institute-list.component';
import { YuktiInnovatorFormComponent } from './components/users/institute/yukti-innovator-form/yukti-innovator-form.component';
import { IprInnovatorFormComponent } from './components/users/institute/ipr-innovator-form/ipr-innovator-form.component';
import { TransferExistingInnovatorFormComponent } from './components/users/institute/transfer-existing-innovator-form/transfer-existing-innovator-form.component';
import { AltInviteVerficationInstituteComponent } from './components/alt-invite-verfication-institute/alt-invite-verfication-institute.component';
import { InstituteDashboard2024Component } from './components/users/institute/institute-dashboard2024/institute-dashboard2024.component';
import { YuktiInstituteNominateChallengesComponent } from './components/users/institute/yukti-challenges/yukti-institute-challenges/yukti-institute-nominate-challenges/yukti-institute-nominate-challenges.component';
import { TransferExistingInnovatorListComponent } from './components/users/institute/transfer-existing-innovator-list/transfer-existing-innovator-list.component';
import { TransferInnovationWithRegistrationComponent } from './components/users/institute/transfer-innovation-with-registration/transfer-innovation-with-registration.component';
import { YuktiRmNominationComponent } from './components/users/institute/yukti-rm-nomination/yukti-rm-nomination.component';
import { AcademicYearPerformance202223Component } from './components/users/institute/institute-profile/bifurcation-score/academic-year-performance202223/academic-year-performance202223.component';
import { IicRatings2023Component } from './components/pages/iic-ratings2023/iic-ratings2023.component';
import { IicRating2023Details1Component } from './components/pages/iic-ratings2023/iic-rating2023-details1/iic-rating2023-details1.component';
import { IicRating2023Details2Component } from './components/pages/iic-ratings2023/iic-rating2023-details2/iic-rating2023-details2.component';
import { IicRating2023Details3Component } from './components/pages/iic-ratings2023/iic-rating2023-details3/iic-rating2023-details3.component';
import { IicRating2023Details4Component } from './components/pages/iic-ratings2023/iic-rating2023-details4/iic-rating2023-details4.component';
import { IicRating2023Details5Component } from './components/pages/iic-ratings2023/iic-rating2023-details5/iic-rating2023-details5.component';
import { IicRating2023Details6Component } from './components/pages/iic-ratings2023/iic-rating2023-details6/iic-rating2023-details6.component';
import { IicRating2023Details7Component } from './components/pages/iic-ratings2023/iic-rating2023-details7/iic-rating2023-details7.component';
import { IicRating2023Details8Component } from './components/pages/iic-ratings2023/iic-rating2023-details8/iic-rating2023-details8.component';
import { IicRating2023DialogComponent } from './components/pages/iic-ratings2023/iic-rating2023-dialog/iic-rating2023-dialog.component';
import { IicRating2023Details9Component } from './components/pages/iic-ratings2023/iic-rating2023-details9/iic-rating2023-details9.component';
import { MentorReferalFormComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/mentor-referal-form/mentor-referal-form.component';
import { HandholdingInactiveIicComponent } from './components/users/institute/institute-schemes/institute-mentor-mentee/mentor-navbar/activity-proposal/handholding-inactive-iic/handholding-inactive-iic.component';
import { HandholdingInstituteRequestComponent } from './components/handholding-institute-request/handholding-institute-request.component';
import { InstituteAssignerDetailComponent } from './components/users/institute/institute-referal-program/institute-assigner-detail/institute-assigner-detail.component';
import { InstituteInactiveIicDetailComponent } from './components/users/institute/institute-referal-program/institute-inactive-iic-detail/institute-inactive-iic-detail.component';
import { AphVerificationDialogComponent } from './components/users/institute/institute-dashboard2024/aph-verification-dialog/aph-verification-dialog.component';
import { GalleryVideo2023Component } from './components/pages/gallery/gallery-video2023/gallery-video2023.component';
import { GalleryPhoto2023Component } from './components/pages/gallery/gallery-photo/gallery-photo2023/gallery-photo2023.component';
import { ChennaiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/chennai-photo/chennai-photo.component';
import { HublliPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/hublli-photo/hublli-photo.component';
import { IndorePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/indore-photo/indore-photo.component';
import { MumbaiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/mumbai-photo/mumbai-photo.component';
import { TirunelveliPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/tirunelveli-photo/tirunelveli-photo.component';
import { VaranasiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/varanasi-photo/varanasi-photo.component';
import { KolkataPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/kolkata-photo/kolkata-photo.component';
import { GuwahatiPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/guwahati-photo/guwahati-photo.component';
import { JaipurPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/jaipur-photo/jaipur-photo.component';
import { AhmedabadPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/ahmedabad-photo/ahmedabad-photo.component';
import { BhubaneswarPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/bhubaneswar-photo/bhubaneswar-photo.component';
import { PunePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/pune-photo/pune-photo.component';
import { NagpurPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/nagpur-photo/nagpur-photo.component';
import { BangalorePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/bangalore-photo/bangalore-photo.component';
import { CoimbatorePhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/coimbatore-photo/coimbatore-photo.component';
import { NoidaPhotoComponent } from './components/pages/gallery/gallery-photo/gallery-photo2023/noida-photo/noida-photo.component';
import { InternshipComponent } from './components/users/institute/Internship/internship/internship.component';
import { ManthanUserComponent } from './components/users/institute/manthan-user/manthan-user.component';
import { News2023Component } from './components/pages/gallery/news2023/news2023.component';
import { YuktiInstituteNominateChallengesNewComponent } from './components/users/institute/yukti-challenges/yukti-institute-challenges/yukti-institute-nominate-challenges-new/yukti-institute-nominate-challenges-new.component';
import { StudentTrainingStatusComponent } from './components/users/institute/student-training-status/student-training-status.component';
import { StudentnominationComponent } from './users/institute/institute-schemes/studentnomination/studentnomination.component';
import { StatuscountdialogComponent } from './components/users/institute/institute-schemes/statuscountdialog/statuscountdialog.component';


@NgModule({
  declarations: [
    MemberCertificateListComponent,
    AppComponent,
    NavbarComponent,

	// IicWebNavbarComponent,
    LoginComponent,
    AtlVerificationComponent,
    InstituteInnovationAddComponent,
    InstituteIdeaEvaluationComponent,
    InstituteInnovationEvaluationComponent,
    InstituteStartupEvaluationComponent,
    TeachingNonacademicAddComponent,
    CollaborationComponent,
    TeachingLearningAddComponent,
    TeachingLearningComponent,
    TeachingLearning2Component,
    CollaborationAddComponent,
    SignupComponent,
    AtlSignupComponent,
    RequestResetComponent,
    RequestResetComponent,
    ResponseResetComponent,
    EmailVerificationComponent,
    InstituteInnovationTeamDetailsComponent,
    PagesComponent,
    LandingComponent,
    InstituteIicAkamComponent,
    InstituteReferalProgramEditComponent,
    IicMentorMenteeListComponent,
    IicVolunteerMentorMenteeListComponent,
    IicRepositoryListComponent,
    FooterComponent,
    InstituteMyActivity202122Component,
    InstituteMyActivity202122Dialog,
    InstituteMyActivity202223Component,
    InstituteMyActivity202223Dialog,
    InstituteMyActivity202324Dialog,
    InstituteAtalSchoolComponent,
    InstituteAtalMentorScheduleComponent,
    InstituteAtalSchoolDialog,
    InstituteCelebrationComponent,
    InstituteCelebrationDialog,
    Iic4ODialogComponent,
    Iic4ODialogComponent2,
    InstituteIicAkamDialogComponent,
    AtalActivityForm1Component,
    AtalActivityFormGlobalComponent,
    AtalActivityForm2Component,
    ActivityReportForm1Component,
    ActivityReportForm2Component,
    AtalActivityForm3Component,
    AtalActivityForm4Component,
    AtlUserDashboardComponent,
    AtlHomeDashboardComponent,
    AtlMentorOfChangeComponent,
    AtlYuktiSubmissionComponent,
    InstituteStateIicListComponent,
    SubiicComponent,
    SubiicAddComponent,
    AtlYuktiSubmissionAddComponent,
    IicRatings2022Details1Component,
    IicRatings2022Details2Component,
    IicRatings2022Details3Component,
    IicRatings2022Details4Component,
    IicRatings2022Details5Component,
    IicRatings2022Details6Component,
    IicRatings2022Details7Component,
	// IicWebFooterComponent,
    CoordinatorComponent,
    NominateCoordinatorComponent,
    CoordinatorAddComponent,
    CoordinatorDialogComponent,
    UsersComponent,
    InstituteComponent,
    InstituteMyCouncilComponent,
    InstituteAffiliateProgramComponent,
    InstituteAffiliateProgramPendingComponent,
    InstituteAffiliateProgramAddComponent,
    InstituteAffiliateProgramEditComponent,
    InstituteDetailsComponent,
    InstituteFacultyComponent,
    InstituteStudentComponent,
    InstituteExternalComponent,
    InstituteCouncilMeetingComponent,
    InstituteDeclareSemestersComponent,
    InstituteSocialMediaComponent,
    InstituteIncubationComponent,
    InstituteIncubationCentersComponent,
    InstitutePreIncubationCentersComponent,
    InstituteIPRTTOCentersComponent,
    InstituteAllDetailsComponent,
    InstituteInnovationContestComponent,
    InstituteActivityComponent,
    InstituteIicImpactComponent,
    InstituteDownloadComponent,
    InstituteNewsCornerComponent,
    InstituteAnnouncementComponent,
    AtalActivityProposalDialogComponent,
    InstituteContactUsComponent,
    InstituteIdeaSubmission21Component,
    InstituteYuktiRegistrationsComponent,
    InstituteIdeaSubmissionDialog21Component,
    InstituteEvaluatorAssignDialogComponent,
    InstituteIdeaSubmissionComponent,
    InstitutePocSubmission21Component,
    InstitutePocSubmissionComponent,
    InstitutePrototypeSubmissionComponent,
    InstitutePrototypeSubmission21Component,
    InstituteIicCalendarComponent,
    InstituteMicDrivenComponent,
    InstituteSelfDrivenComponent,
    InstituteMyActivityComponent,
    InstituteActivityReportComponent,
    InstituteActivityPrototypeComponent,
    InstituteActivityTestimonialComponent,
    InstituteTestimonialComponent,
    InstituteActivitySecondFormComponent,
    ReportFormComponent,
    InstituteDetailsDialogComponent,
    InstituteFacultyDialogComponent,
    InstitutePreIncubationCentersDialogComponent,
    InstituteIPRTTOFacilitiesDialogComponent,
    InstituteStudentDialog,
    InstituteStudentPresidentDialog,
    AtalActivityProposalComponent,
    InstituteExternalDialogComponent,
    InstituteCouncilMeetingDialogComponent,
    InstituteSocialMediaDialogComponent,
    InstituteDetailsDialogComponent,
    SelectCheckAllComponent,
    InstituteDeclareSemesterDialog,
    InstituteReferalProgramAddComponent,
    InstituteReferalProgramComponent,
    InstituteDeclareSemestersDeleteDialogComponent,
    InstituteDeleteFacultyComponent,
    InstituteExternalDeleteComponent,
    InstituteCouncilDeleteComponent,
    InstituteDeleteDialogComponent,
    ResetPasswordDialogComponent,
    NominateForm2Component,
    NominateForm3Component,
    ImpactLecturesReportFormComponent,
    ImpactSoeFormComponent,
    InstituteNewsCornerDeleteDialogComponent,
    //InstituteSuggestionDialog,
    NominateForm2DialogComponent,
    NominationsDialogComponent,
    NominationsComponent,
    InstituteRequestLogoComponent,
    InstituteRequestLogoDialog,
    InstituteNewsCornerComponentDialog,
    InnerNavbarComponent,
    InnerFooterComponent,
    AboutUsComponent,
    AboutMHRDnnovationComponent,
    AboutMessageComponent,
    LeadershipTeamComponent,
    ContactUsComponent,
    InnovationCouncilComponent,
    IicComponent,
    WhyHeisComponent,
    IicAdvisoryCommitteeComponent,
    InnovationcontestComponent,
    InnovationAmbassadorComponent,
    IicRatingComponent,
    IicTeamComponent,
    IicwebinarComponent,
    InnovationRankingComponent,
    HackathonComponent,
    SihComponent,
    SingaporeIndiaHackathonComponent,
    StartUpPolicyComponent,
    StartupPolicyComponent,
    StartupPolicyCommitteeComponent,
    StartupApplicationComponent,
    NominateFacultyForNispComponent,
    LeadershipTalkComponent,
    IfltsUpcomingComponent,
    IfltsPastComponent,
    MicOutcomeComponent,
    GalleryComponent,
    GalleryVideoComponent,
    GalleryPhotoComponent,
    NewsComponent,
    LoaderComponent,
    InstitutePresidentComponent,
    InstituteHoiComponent,
    InstituteNavbarComponent,
    InstituteMenteeInfoDialogComponent,
    InstituteMentorInfoDialogComponent,
    InstituteFooterComponent,
    AnnouncementsComponent,
    AnnouncementsNewComponent,
    AnnouncementsOldComponent,
    SamadhanComponent,
    InstituteInnverNvavbarComponent,
    InstituteInnerFooterComponent,
    InstituteProfileComponent,
    HoiDetailsComponent,
    PresidentDetailsComponent,
    InstitutePocSubmissionDialogComponent,
    InstitutePocSubmissionDialog21Component,
    InstituteAffiliateProgramAddDialog,
    InstituteIdeaSubmissionDialogComponent,
    InstitutePrototypeSubmissionDialogComponent,
    InstitutePrototypeSubmissionDialog21Component,
    InstituteSuggestionComponent,
    InstituteSuggestionDialogComponent,
    ReplyDialogComponent,
    InstituteIicCalendarDialog,
    InstituteMicCalendarDialog,
    InstituteSelfDrivenDialog,
    InstituteMyActivityDialog,
    InstituteMyActivity202122Dialog,
    InstituteMyActivity202223Dialog,
    InstitutePresidentDialogComponent,
    InstituteHoiDialogComponent,
    ReplyListComponent,
    InstituteDialog,
    InstituteCouncilDeleteComponent,
    InstituteActivitySelfAnnouncmentForm,
    InstituteActivitySelfEditForm,
    InstituteNominationComponent,
    InstituteDashboardComponent,
    DashboardChartsComponent,
    DashboardCardsComponent,
    InstituteDashboard1819Component,
    Dashboard1819CardsComponent,
    Dashboard1819ChartsComponent,
    Dashboard1819ActivityListComponent,
    Dashboard1819AnnouncementsComponent,
    Dashboard1819MyRankingComponent,
    Dashboard1819NotificationsComponent,
    NotificationDialog,
    Dashboard1819StarPerformerComponent,
    DashboardActivityListComponent,
    IncubationInchargeDialogComponent,
    BifurcationScoreComponent,
    CertificatesComponent,
    InstituteActivityCountDialogComponent,
    InstituteAmbassadorComponent,
    MicOutcomeDialogComponent,
    LastLoginDialogComponent,
    OnlineRegistrationComponent,
    RegistrationOnlineSessionComponent,
    OnlineSessionLoginComponent,
    OnlineSessionDetailsComponent,
    OnlineSessionVideoComponent,
    OnlineSessionVideoDialogComponent,
	LandingDialogComponent,
	DashboardDialogComponent,
  RepositoryAddComponent,
    IicWebNavbarComponent,
    IicWebFooterComponent,
    IicaboutMicComponent,
    IicadvisoryCommitteeComponent,
    IicImplementationTeamComponent,
    IicNationalInnovationContestComponent,
    PocContest2019Component,
    InnovationConest2020Component,
    IicInnovationAmbassadorComponent,
    IicIncubatorstabComponent,
    IncubatorsComponent,
    PreincubatorsComponent,
    IicOnlineSessionsComponent,
    IicOnlineSessionsDetailsComponent,
    IicAmbassadorProfileComponent,
	ImpactLectureSeriesComponent,
    Iic201920Component,
    Iic202021Component,
    Iic202021CalenderComponent,
    Iic202122CalenderComponent,
    InternationalExposureComponent,
    IndiaCanadaComponent,
    IndiaSouthKoreaComponent,
    MaterialTableComponent,
    IicDashboard1819Component,
    IicDashboard1819ChartsComponent,
    IicDashboard1819CardsComponent,
    IicDashboard1920Component,
    IicDashboard1920ChartsComponent,
    IicContactUsComponent,
    IicDownloadComponent,
    IicNotificationComponent,
    IicRatings1819Component,
    MentorMenteeProgramComponent,
    IicRatings1920Component,
    IicRatings1920ListComponent,
    OnlineSessionNavbarComponent,
    VerifyOtpComponent,
    OsiaRequestResetComponent,
    InstitutesListDialogComponent,
    InstituteMentoringSessionComponent,
    IicWebMainNavbarComponent,
    MemberCertificateListComponent,
    Members1819Component,
    MembersCertificates1819Component,
    InstituteListComponent,
    CouncilDetailComponent,
    SesssionListComponent,
    InstituteSchemesComponent,
    NominationsComponent,
    ImpactLecturesComponent,
    AddDialogImpactComponent,
    ExportFileComponent,
    InstituteMentorMenteeComponent,
    MentorNavbarComponent,
    SelectMenterMenteeFormComponent,
    InstituteSuggestionNewComponent,
    Dashboard2021Component,
    InstituteDashboard2021Component,
    Cards2021Component,
    Charts2021Component,
    Downloads2021Component,
    InstituteDashboard2022Component,
    Cards2022Component,
    Charts2022Component,
    InstituteDashboard2023Component,
    Cards2023Component,
    Charts2023Component,
    Downloads2022Component,
    Downloads2023Component,
    SuggestDialogComponent,
    ActivityProposalComponent,
    ActivityProposalDialogComponent,
    MentorMenteeProgramNewComponent,
    NominateForm1Component,
    IicInnovationAmbassadorNewComponent,
    IicImpactLectureSeriesNewComponent,
    InstituteStageIEvaluationComponent,
    InstituteStageIIEvaluationComponent,
    InstituteStageIIIEvaluationComponent,
    InstituteEvaluatorComponent,
    InstituteEvaluatorAddComponent,
    InstituteYic2021Component,
    InstituteBankFormComponent,
    ActivityForm1Component,
    ActivityForm2Component,
    ActivityForm3Component,
    ActivityForm4Component,
    ActivityForm5Component,
    InstitutesListComponent,
    ActivityScheduleComponent,
    NewIaRequestComponent,
    CurrentYearPerformanceComponent,
    AcademicYearPerformance202122,
    PreviousYearPerformanceComponent,
    OnlineSessionListComponent,
    JoinUsWhatsappComponent,
    InstituteIicSubmissionComponent,
    IicOutcomeComponent,
    IicImpactDashboardComponent,
    RepositoryComponent,
    IicRatings2021Component,
    IicRatings2022Component,
    YuktiChallengesComponent,
    YuktiChallengesAddComponent,
    IicRatings2021DetailsComponent,
    IicRatingDialogComponent,
    IicRating2022DialogComponent,
    IicRatings2021Details1Component,
    IicRatings2021Details2Component,
    IicRatings2021Details3Component,
    IicImpactDashboardStateWiseComponent,
    ActivityExpensesComponent,
    ActivityReportFormQpmComponent,
    ActivityReportFormPmimComponent,
    InstituteMmDashboardComponent,
    RepositoryDialogComponent,
    ReferralInstitutesDialogComponent,
	SelfDriveActivityAddComponent,
	SihGalleryPhotoComponent,
	PhotoGalleryAhmedabadComponent,
	PhotoGalleryBangaloreComponent,
	PhotoGalleryChandigarhComponent,
	PhotoGalleryGuwahatiComponent,
	PhotoGalleryHyderabadComponent,
	PhotoGalleryJaipurComponent,
	PhotoGalleryKolkataComponent,
	PhotoGalleryKochiComponent,
	PhotoGalleryLucknowComponent,
	PhotoGalleryMaduraiComponent,
	PhotoGalleryNagpurComponent,
	PhotoGalleryPuneComponent,
	PhotoGalleryVisakhapatnamComponent,
  IaActivityReportComponent,
	IaActivityReportInternalComponent,
	IaActivityReportExternalComponent,
	IaActivityReportDetailsDialogComponent,
	IaActivityReportExternalDetailsDialogComponent,
	PhotoGalleryBhopalComponent,
	EvaluatorEditComponent,
	InstituteEvaluationDetailsComponent,
	IicAchievementComponent,
	InstituteTeamLeadVerifyDialogComponent,
	PosterChennaiComponent,
	PosterComponent,
	PosterBengaluruComponent,
	PosterChandigarhComponent,
	PosterDindigulComponent,
	PosterJaipurComponent,
	PosterKochiComponent,
	PosterLucknowComponent,
	PosterGalleryComponent,
	StudentInnovationVideoComponent,
	AtlMenteeActivityComponent,
	Iic202223CalenderComponent,
	SubiicExistingComponent,
	IicRatings2022Details8Component,
	TrainingStatusComponent,
	IaActivityReportTrainingComponent,
	InnovationContestComponent,
	ViewSubmissionComponent,
	ActivityMenteeDialogComponent,
	AtlMentorDetailComponent,
	AtlProfileDetailComponent,
	AtlContactUsComponent,
	AtlAnnouncementDetailComponent,
	AtlIaMemberNominationListComponent,
	NominatedAtlCoordinatorComponent,
	AddNominatedCoordinatorComponent,
	NominatedIicAtlCoordinatorListComponent,
	NominatedIicCoordinatorListComponent,
	IicReportDownloadComponent,
	AtlActivityViewSubmissionComponent,
	AtlIaMentorDetailComponent,
	AtlLoginDialogComponent,
	AtlInviteIicComponent,
	IaActivityReportTrainingDialogComponent,
	Downloads2020Component,
	YuktiNominatedChallengesComponent,
	YirDashboardComponent,
	Ntw2023Component,
	NationalTechnologyWeekComponent,
	InstituteChallengeNominationComponent,
	YuktiInstituteChallengesComponent,
	IicImpactLectureSeries2023Component,
	AtlInstituteListComponent,
	YuktiInnovatorFormComponent,
	IprInnovatorFormComponent,
	TransferExistingInnovatorFormComponent,
	AltInviteVerficationInstituteComponent,
	InstituteMyActivity202324Component,
	InstituteDashboard2024Component,
	Cards2024Component,
	Charts2024Component,
	YuktiInstituteNominateChallengesComponent,
	TransferExistingInnovatorListComponent,
	TransferInnovationWithRegistrationComponent,
	YuktiRmNominationComponent,
	AcademicYearPerformance202223Component,
	IicRatings2023Component,
	IicRating2023Details1Component,
	IicRating2023Details2Component,
	IicRating2023Details3Component,
	IicRating2023Details4Component,
	IicRating2023Details5Component,
	IicRating2023Details6Component,
	IicRating2023Details7Component,
	IicRating2023Details8Component,
	IicRating2023DialogComponent,
	IicRating2023Details9Component,
	MentorReferalFormComponent,
	HandholdingInactiveIicComponent,
	HandholdingInstituteRequestComponent,
	InstituteAssignerDetailComponent,
	InstituteInactiveIicDetailComponent,
	AphVerificationDialogComponent,
	GalleryVideo2023Component,
	GalleryPhoto2023Component,
	ChennaiPhotoComponent,
	HublliPhotoComponent,
	IndorePhotoComponent,
	MumbaiPhotoComponent,
	TirunelveliPhotoComponent,
	VaranasiPhotoComponent,
	KolkataPhotoComponent,
	GuwahatiPhotoComponent,
	JaipurPhotoComponent,
	AhmedabadPhotoComponent,
	BhubaneswarPhotoComponent,
	PunePhotoComponent,
	NagpurPhotoComponent,
	BangalorePhotoComponent,
	CoimbatorePhotoComponent,
	NoidaPhotoComponent,
	InternshipComponent,
	ManthanUserComponent,
	News2023Component,
	YuktiInstituteNominateChallengesNewComponent,
	StudentTrainingStatusComponent,
	StudentnominationComponent,
	StatuscountdialogComponent,
  

  ],
  imports: [
    // TimePickerComponent,
    // MatTimePickerDirective,
    NgxMaterialTimepickerModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
	MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatSliderModule,
    MatInputModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
    }),
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatTableModule,
    MatAutocompleteModule,
    MatSortModule,
    MatProgressSpinnerModule,
    NgxChartsModule,
    ChartsModule,
    GaugeChartModule,
    NgxPaginationModule,
    MatChipsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    YouTubePlayerModule
  ],
  providers: [httpInterceptorProviders, JarwisService, AfterLoginAmbassadorService],
  bootstrap: [AppComponent],
  entryComponents: [
    // InstituteSuggestionDialog,
    NominateForm2DialogComponent,
    NominationsDialogComponent,
    AddDialogImpactComponent,
    OnlineSessionVideoDialogComponent,
    IicContactUsComponent,
	LandingDialogComponent,
	DashboardDialogComponent,
  ActivityReportForm1Component,
  ActivityReportForm2Component,
  InstituteAffiliateProgramAddDialog,
  CoordinatorDialogComponent,
  InstituteMenteeInfoDialogComponent,
    InstituteMentorInfoDialogComponent,
    InstituteNewsCornerComponentDialog,
    InstituteDeclareSemesterDialog,
    InstituteDetailsDialogComponent,
    AtalActivityProposalDialogComponent,
    InstituteStudentDialog,
    InstituteStudentPresidentDialog,
    InstituteSocialMediaDialogComponent,
    InstituteFacultyDialogComponent,
    InstitutePreIncubationCentersDialogComponent,
    InstituteIPRTTOFacilitiesDialogComponent,
    InstituteExternalDialogComponent,
    InstituteCouncilMeetingDialogComponent,
    InstitutePocSubmissionDialogComponent,
    InstitutePocSubmissionDialog21Component,
    InstituteIdeaSubmissionDialog21Component,
    InstituteEvaluatorAssignDialogComponent,
    InstituteEvaluationDetailsComponent,
    InstituteIdeaSubmissionDialogComponent,
    IaActivityReportDetailsDialogComponent,
    InstituteTeamLeadVerifyDialogComponent,
    IaActivityReportExternalDetailsDialogComponent,
    InstitutePrototypeSubmissionDialogComponent,
    InstitutePrototypeSubmissionDialog21Component,
    Iic4ODialogComponent,
    Iic4ODialogComponent2,
    InstituteIicAkamDialogComponent,
    ReplyDialogComponent,
    InstituteMyActivityDialog,
    InstituteIicCalendarDialog,
    InstituteMicCalendarDialog,
    InstituteSelfDrivenDialog,
    ReplyListComponent,
    AtalActivityForm1Component,
    AtalActivityFormGlobalComponent,
    AtalActivityForm2Component,
    AtalActivityForm3Component,
    AtalActivityForm4Component,
    InstituteRequestLogoDialog,
    InstituteSuggestionDialogComponent,
    InstituteDeclareSemestersDeleteDialogComponent,
    InstituteDialog,
    InstituteCelebrationDialog,
    InstituteDeleteFacultyComponent,
    InstituteExternalDeleteComponent,
    InstituteCouncilDeleteComponent,
    InstituteDeleteDialogComponent,
    ResetPasswordDialogComponent,
    InstituteNewsCornerDeleteDialogComponent,
    InstituteActivitySelfAnnouncmentForm,
    InstituteActivitySelfEditForm,
    NotificationDialog,
    IncubationInchargeDialogComponent,
    InstituteActivityCountDialogComponent,
    MicOutcomeDialogComponent,
    LastLoginDialogComponent,
    InstitutesListDialogComponent,
    InstituteAtalSchoolDialog,
    SuggestDialogComponent,
    ActivityProposalDialogComponent,
    ActivityForm1Component,
    ActivityForm2Component,
    ActivityForm3Component,
    ActivityForm4Component,
    ActivityForm5Component,
    IicRatingDialogComponent,
    IicRating2022DialogComponent,
    IicRating2023DialogComponent,
    AtlLoginDialogComponent,
    IaActivityReportTrainingDialogComponent,
    AphVerificationDialogComponent,
    StatuscountdialogComponent,
    InstituteMyActivity202324Dialog
  ]
})
export class AppModule { }
