import { Component, OnInit } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/app.component';
import { A } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  captchaResponse: any;

  public signupForm: FormGroup;
  public error = [];

  instituteDetails: any;
  approvalType: any;

  institute_name: any = '';
  institute_address: any = '';
  institute_city: any = '';
  institute_state: any = '';
  consortium_id: any = '';
  uploadDiv: boolean;
  isConditionIsTrue: boolean;

  constructor(private jarwis: JarwisService,
    private token: TokenService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  ngOnInit() {

    this.jarwis.getApprovalType().subscribe(
      data => this.getApprovalType(data)
    );
    // aicte_approved_program: [null, Validators.required],
    this.signupForm = this.fb.group({
      name: [null, Validators.required],
      
      pid: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      mobile_number: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      aishe_code: [],
      institute_type: [null, Validators.required],
      institute_subtype: [null, Validators.required],
      institute_name: [{ value: null, disabled: true }, Validators.required],
      institute_city: [{ value: null, disabled: true }],
      institute_state: [{ value: null, disabled: true }],
      institute_address: [{ value: null, disabled: true }],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/()\\[\\{}])(?=.*[A-Z])(?=.*[a-z]).*$')])],
      confirm_password: [null, Validators.compose([Validators.required, Validators.pattern('(?=^.{6,}$)(?=.*[0-9])(?![.\n])(?=.*[@!#$%^&*-+:;_=,.<>~`/])(?=.*[A-Z])(?=.*[a-z]).*$')])],
      approval_type: [null, Validators.required],
    });

    // this.signupForm.get('aicte_approved_program').valueChanges.subscribe(
    //   data => this.handleData2(data)
    // )

  }

  // handleData2(data) {
  //   if (data == "Yes") {
  //     this.uploadDiv = true;
  //     this.signupForm.get('pid').setValidators(Validators.required);
  //     this.signupForm.get('pid').updateValueAndValidity();
  //   } else if (data == "No") {
  //     this.signupForm.get('pid').clearValidators();
  //     this.signupForm.get('pid').updateValueAndValidity();
  //     this.uploadDiv = false;
  //   }
  // }
  getApprovalType(data){
    this.approvalType = data['approvals'];
  }
  // for toastr
  showSuccess(message: string) {
    this.toastr.success(message, 'Success');
  }
  showError(message: string) {
    this.toastr.error(message, 'Error!');
  }
  showWarning(message: string) {
    this.toastr.warning(message, 'Warning');
  }
  showInfo(message: string) {
    this.toastr.info(message, 'Info');
  }

  onSubmit(values) {

    if (this.captchaResponse) {
      if (this.signupForm.valid) {
        if (values.password == values.confirm_password) {
          values['role'] = 3;
          values['institute_name'] = this.institute_name;
          values['address'] = this.institute_address;
          values['city'] = this.institute_city;
          values['state'] = this.institute_state;
          values['consortium_id'] = this.consortium_id;
          if(values['approval_type'] != 'AICTE'){
            values['pid'] = '';
          }
          this.jarwis.signUp(values).subscribe(
            data => this.handleResponse(data)
          );
        } else {
          this.toastr.error('Passwords does not match');
        }
      } else {
        this.toastr.error('Kindly fill all the details');
      }
    } else {
      this.toastr.error('Kindly verify Captcha!')
    }
  }

  getAisheDetails(data) {
    var aisheCode = this.signupForm.get('aishe_code').value;
    var pid = this.signupForm.get('pid').value;
    if(aisheCode){
      this.jarwis.getAisheDetails(data,pid).subscribe(
        data => this.getInstituteDetails(data)
        );
    }else{
      this.jarwis.getAisheDetails(pid).subscribe(
        data => this.getInstituteDetails(data)
      );
    }
  }

  getInstituteDetails(data) {
    if (data['message'] == "Error") {
      this.toastr.error(data['reason']);
    }
    else {
      this.institute_name = data['aisheDetails']['0']['name'];
      this.institute_address = (data['aisheDetails']['0']['address_line1'] + ' ' + data['aisheDetails']['0']['address_line2']);
      this.institute_city = data['aisheDetails']['0']['city'];
      this.institute_state = data['aisheDetails']['0']['state'];
      this.consortium_id = data['aisheDetails']['0']['consortium_id'];
    }
  }

  handleResponse(data) {
    if (data['message'] == 'Success') {
      this.logout();
      this.toastr.success('Registered Successfully')
    } else {
      this.toastr.error(data['reason']);
    }

  }

  handleError(error) {
    this.showError(error.error.errors);
  }

  logout() {
    this.token.remove();
    this.auth.changeStatus(false);
    this.router.navigateByUrl('/login');
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isNumKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 45) return true;

    return false;
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

  getSelectedApprovalType() {
    var approvalType = this.signupForm.get('approval_type').value;
    if(approvalType == 'AICTE'){
      this.uploadDiv = true;
      this.isConditionIsTrue = false;
      this.signupForm.get('pid').setValidators(Validators.required);
      this.signupForm.get('pid').updateValueAndValidity();
      // document.getElementById("#aishe_code1").removeAttribute("required");
    }else{
      this.uploadDiv = false;
      this.isConditionIsTrue = true;
      this.signupForm.get('pid').clearValidators();
      this.signupForm.get('pid').updateValueAndValidity();
      // document.getElementById("#aishe_code1").attributes["required"] = true;
    }
    // console.log(this.signupForm.get('approval_type').value);
  }
}