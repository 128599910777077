import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { TokenService } from 'src/app/Services/token.service';
import { Router } from '@angular/router';
// import { AuthService } from 'src/app/Services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  captcha = null;
  ctx;
  @ViewChild('canvas2', { static: true })
  canvas2: ElementRef<HTMLCanvasElement>;
  captcha_response = null;
  captcha_verified = null;

  captchaResponse: any;
  public form = {
    email: null,
    password: null
  };

  public error = null;

  constructor(
    private jarwis: JarwisService,
    private token: TokenService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private myCouncilService: InstituteMyCouncilService,
  ) {}

  ngOnInit() {
    const token = localStorage.getItem('token');
    if(token){
    this.auth.getToken(token).subscribe(
      data => {this.handleResponse1(data)
      }
    );
      }

      this.createCaptcha();
  }

      handleResponse1(data){
        if(data['status'] ="1"){
          this.router.navigateByUrl('/institute/dashboard2023-24');
        }else{
          localStorage.removeItem('token');
          this.router.navigateByUrl('/home')
        }
      }

  onSubmit(captcha_response) {
    // return false;
    this.verifyCaptcha(captcha_response);
    // if (this.captchaResponse) {
    if (this.captcha_verified) {
        this.jarwis.login(this.form).subscribe(
          (data) => this.handleResponse(data),
          (error) => this.handleError(error)
        );
    } else {
      this.toastr.error('Kindly verify Captcha!');
    }
  }


  handleResponse(data) {
    if (data['message'] == 'Error') {
      this.toastr.error(data['reason']);
    } else {
      this.token.handle(data.token);
      let payload = this.token.payload(data.token);
      this.auth.changeStatus(true);
      // console.log(payload);
      switch (payload.role_id) {
        case 3:
          {
            this.myCouncilService.checkMyCouncil().subscribe((data) => {
              if (data['applicationStatus'] == '1' && data['applicationStatusDisapproved'] == '1') {
                this.router.navigateByUrl('/institute/dashboard2023-24');
              }
              if (data['applicationStatus'] == '0' && data['applicationStatusDisapproved'] == '0') {
                this.router.navigateByUrl('/institute/my-council');
              }
			  
            });
          }
          break;
          case 5:
          {

            this.myCouncilService.checkMyCouncil().subscribe((data) => {
              if(data['applicationStatus'] == '1' && data['applicationStatusDisapproved'] == '1') {
                this.router.navigateByUrl('/institute/atlsic-dashboard');
              }			  
            });            
          }
          break;
          case 10:
          {
            // console.log('hii')
            // this.myCouncilService.checkMyCouncil().subscribe((data) => {
              this.router.navigateByUrl('/institute/dashboard2023-24');
            // });            
          }
          break;
        default:
          break;
      }
    }
  }

  handleError(error) {
    this.error = error.error.error;
    this.toastr.error(error.error.error);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

  verifyCaptcha(value) {
    this.captchaResponse = value;
    // console.log('IS Captcha verified : ', this.captcha == value);
    if (this.captcha == value) {
      this.captcha_verified = true;
    } else {
      this.captcha_verified = false;
    }
  }
  createCaptcha() {
    let alphaNums = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'a',
      'b',
      'c',
      'd',
      'e',
      'f',
      'g',
      'h',
      'i',
      'j',
      'k',
      'l',
      'm',
      'n',
      'o',
      'p',
      'q',
      'r',
      's',
      't',
      'u',
      'v',
      'w',
      'x',
      'y',
      'z',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
    ];

    let a = alphaNums[Math.floor(Math.random() * alphaNums.length)];
    let b = alphaNums[Math.floor(Math.random() * alphaNums.length)];
    let c = alphaNums[Math.floor(Math.random() * alphaNums.length)];
    let d = alphaNums[Math.floor(Math.random() * alphaNums.length)];
    let e = alphaNums[Math.floor(Math.random() * alphaNums.length)];
    let f = alphaNums[Math.floor(Math.random() * alphaNums.length)];

    this.captcha = a + b + c + d + e + f;

    this.ctx = this.canvas2.nativeElement.getContext('2d');
    this.ctx.clearRect(0, 0, 300, 150);
    // this.realCaptcha = refreshArr.join('');
    this.ctx.font = '48px cursive';
    // this.ctx.textAlign = 'center'
    this.ctx.fillText(this.captcha, 75, 75);

    // console.log(this.captcha);
  }
}
