import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
//import { emailValidator } from 'src/app/app.component';
//import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import * as myGlobals from 'src/app/app.component';


@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.css']
})

export class ReportFormComponent implements OnInit {

  showTwitterURL: boolean;
  showFacebookURL: boolean;
  showInstaURL: boolean;
  showLinkedInURL : boolean;

  public activityForm: FormGroup;
  photograph1: any;
  photograph2: any;
  programData: any;
  //facultyList: any;
  facultyList: string[] = []; //{[key: string]: string};
  //studentList: any;
  studentList: string[] = [];//{[key: string]: string};
  //programThemeData: any;
  programType: string[] = [];
  programTheme: string[] = [];
  event_id: string;
  action: string;
  institute_id: string;
  programName: any;

  program_type_other_flg: boolean;
  program_theme_other_flg: boolean;

  public start_date: any;

  public baseImageUrl = myGlobals.baseImageUrl;
  public allUrls: {
    photog1: string;
    photograph2: string;
    brochure: string;
  };
  social: any[];
  social1: any[];
  social2: any[];
  social3: any[];
  urlReport: any;
  curr_year: any = "";
  acad_year: any = "";

  constructor(public fb: FormBuilder,
    private jarwis: JarwisService,
    // public dialogRef: MatDialogRef<InstituteActivityReportComponent>,
    // private myCouncilService: InstituteMyCouncilService,
    private instituteMaster: InstituteInnovationContestService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
    //@Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.showTwitterURL = true;
    this.showFacebookURL = true;
    this.showInstaURL = true;
    this.showLinkedInURL = true;

    this.program_theme_other_flg = true;
    this.program_type_other_flg = true;
    this.start_date = "";

    this.allUrls = {
      photog1: '',
      photograph2: '',
      brochure: ''
    };
  }

  ngOnInit(): void {

    //this.event_id = this.route.snapshot.paramMap.get('event_id');
    //this.action = this.route.snapshot.queryParamMap.get('action');
    //this.institute_id = this.route.snapshot.queryParamMap.get('instid');


    this.route.params.subscribe(params => {
      //this.id = +params['id']; // (+) converts string 'id' to a number
      this.event_id = params['event_id'];
      


      // In a real app: dispatch action to load the details here.
    });


    this.route.queryParamMap.subscribe((params) => this.setQueryParams(params));

    if(this.event_id=="M21-232055")
    {
      this.activityForm = this.fb.group({
        twitter_checkbox: 0,
        fb_checkbox: 0,
        instagram_checkbox: 0,
        linkedIn_checkbox: 0,

        twitter_url: [null],
        fb_url: [null],
        instagram_url: [null],
        linkedin_url: [null],

        academic: [null, Validators.required],
        program: [null, Validators.required],
        quater: [null, Validators.required],
        program_name: [null, Validators.required],
        program_type: [null, Validators.required],
        program_other: [null],
        program_theme: [null, Validators.required],

        activity_duration: [null, Validators.required],
        // programtheme_other: [null],
        start_date: [null, Validators.required],
        end_date: [null, Validators.required],
        student_participants: [null],
        faculty_participants: [null],
        external_participants: [null],
        expenditure_amount: [null],
        remark: [null],
        objective: ['', [Validators.maxLength(100),Validators.required]],
        //benefit_learning: [null,Validators.maxLength(150)],
        benefit_learning: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(150)]),
        ],
        facultyMember: [null],
        studentMember: [null],
        session: [null, Validators.required],

        photograph1: this.action == 'edit' ? [null] : [null, Validators.required],
        photograph1_file: this.action == 'edit' ? [null] : [null, Validators.required],

        photograph2: [null],
        photograph2_file: [null],

        brochure: [null],
        brochure_file: [null],

        video: [null]
      });
    }
    else
    {
      this.activityForm = this.fb.group({
        twitter_checkbox: 0,
        fb_checkbox: 0,
        instagram_checkbox: 0,
        linkedIn_checkbox: 0,

        twitter_url: [null],
        fb_url: [null],
        instagram_url: [null],
        linkedin_url: [null],

        academic: [null, Validators.required],
        program: [null, Validators.required],
        quater: [null, Validators.required],
        program_name: [null, Validators.required],
        program_type: [null, Validators.required],
        program_other: [null],
        program_theme: [null, Validators.required],

        activity_duration: [null, Validators.required],
        // programtheme_other: [null],
        start_date: [null, Validators.required],
        end_date: [null, Validators.required],
        student_participants: [null, Validators.required],
        faculty_participants: [null],
        external_participants: [null],
        expenditure_amount: [null],
        remark: [null],
        objective: ['', [Validators.maxLength(100),Validators.required]],
        //benefit_learning: [null,Validators.maxLength(150)],
        benefit_learning: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(150)]),
        ],
        facultyMember: [null],
        studentMember: [null],
        session: [null, Validators.required],

        photograph1: this.action == 'edit' ? [null] : [null, Validators.required],
        photograph1_file: this.action == 'edit' ? [null] : [null, Validators.required],

        photograph2: [null],
        photograph2_file: [null],

        brochure: [null],
        brochure_file: [null],

        video: [null]
      });
    }







    if (this.event_id) {
      this.instituteMaster
        .getMonthlyReportData(this.event_id)
        .subscribe(
          (data) => {
            this.handleResponseFacultyData(data)
            this.programName = data['title']
          }
        );

    }

    var parm = JSON.stringify(["program_type", "program_theme"]);
    this.instituteMaster
      .getMasterDetails(parm)
      .subscribe((data) => this.handleResponseForAutoPopulate(data));

    this.instituteMaster
      .getFacultyMember()
      .subscribe((data) => this.handleResponseForStudentFaculty(data, 'faculty'));

    this.instituteMaster
      .getStudentMember()
      .subscribe((data) => this.handleResponseForStudentFaculty(data, 'student'));

    //this.getProgramType();
    // this.Year = this.filterData['ACADEMIC_YEAR'];
    // this.State = this.filterData['INSTI_STATE'];
    // this.Type = this.filterData['INST_TYPE'];
    // this.Level = this.filterData['LEVEL_CATEGORY'];
    // this.Region = this.filterData['Region'];

  }

  setQueryParams(data) {
    //this.orderObj = { ...params.keys, ...params };
    this.action = data.get('action');
    this.institute_id = data.get('instid') ? data.get('instid') : '';
    this.curr_year = data.get('curr_year');
  }

  checkSocial(e, type) {

    if (type == 'facebook') {
      if (e.checked === true) {
        this.showFacebookURL = false;
      } else {
        this.showFacebookURL = true;
      }
    } else if (type == 'twitter') {
      if (e.checked === true) {
        this.showTwitterURL = false;
      } else {
        this.showTwitterURL = true;
      }
    } else if (type == 'instagram') {
      if (e.checked === true) {
        this.showInstaURL = false;
      } else {
        this.showInstaURL = true;
      }
    }
    else if (type == 'linkedIn') {
      if (e.checked === true) {
        this.showLinkedInURL = false;
      } else {
        this.showLinkedInURL = true;
      }
    }
  }

  handleResponseFacultyData(data) {
    if(this.curr_year){
      this.acad_year = "2023-24";
    }else{
      this.acad_year = "2022-23";
    }
    this.activityForm.patchValue({
      academic: this.acad_year,
      program: (data.type && data.type == "IIC Calendar Activity") ? "1" : (data.type == "MIC driven Activity" ? "2" : data.type == "Celebrations" ? "4" : (data.type == "Self-driven Activity" ? "3" : 0)),
      program_name: data.title ? data.title : '',
      quater: data.quarter,
    });

    if (this.action == 'edit') {
      this.urlReport=data.urlReport
      this.allUrls.photog1 = (data.photo1 && data.photo1 != 'NA') ? this.baseImageUrl + data.photo1 : '';
      this.allUrls.photograph2 = (data.photo2 && data.photo2 != 'NA') ? this.baseImageUrl + data.photo2 : '';
      this.allUrls.brochure = (data.urlReport != "NA") ? this.baseImageUrl + data.urlReport : '';

      if (data.facebook_url != '0') {
        this.showFacebookURL = false;
      } else {
        this.showFacebookURL = true;
      }

      if (data.twitter_url != '0') {
        this.showTwitterURL = false;
      } else {
        this.showFacebookURL = true;
      }

      if (data.instagram_url != '0') {
        this.showInstaURL = false;
      } else {
        this.showInstaURL = true;
      }

      if (data.linkedin_url != '0') {
        this.showLinkedInURL = false;
      } else {
        this.showLinkedInURL = true;
      }

      this.activityForm.patchValue({
        quater: data.quarter,
        objective: data.Objective != null && data.Objective != 'null' && data.Objective != '0' ? data.Objective : '',
        benefit_learning: data.benefit_learning != null && data.benefit_learning != 'null' && data.benefit_learning != '0' ? data.benefit_learning : '',
        program_type: data.event_type,
        program_other: data.event_type == "Other" ? data.otherProgramType : '',
        program_theme: data.event_theme,
        // programtheme_other: data.event_theme == 'Other' ? data.other : '',
        event_type: data.event_type,
        expenditure_amount: data.expenditure_amount,
        facultyMember: data.faculty_member,
        studentMember: data.student_member,

        activity_duration: data.activity_duration,
        start_date: new Date(data.from_date),
        end_date: new Date(data.to_date),
        faculty_participants: data.faculty_participants != 'NA' ? data.faculty_participants : '',
        academic: data.financial_year != 'NA' ? data.financial_year : '',
        external_participants: data.internal_participant,
        remark: data.remark != null && data.remark != '0' && data.remark != 'null' ? data.remark : '',
        session: data.session,
        video: data.video && data.video != 'null' && data.video != '0' ? data.video : '',
        student_participants: data.student_participants ? data.student_participants : '',

        twitter_url: data.twitter_url != "0" ? data.twitter_url : null,
        twitter_checkbox: data.twitter_url != "0" ? 1 : 0,

        fb_url: data.facebook_url != "0" ? data.facebook_url : null,
        fb_checkbox: data.facebook_url != "0" ? 2 : 0,

        instagram_url: data.instagram_url != "0" ? data.instagram_url : null,
        instagram_checkbox: data.instagram_url != "0" ? 3 : 0,

        linkedin_url: data.linkedin_url != "0" ? data.linkedin_url : null,
        linkedIn_checkbox: data.linkedin_url != "0" ? 4 : 0
      });

    }

  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }


  handleResponseForAutoPopulate(data) {
    //this.programData = data['data']['program_type'];

    //if (data['data']['program_type']) {
    if (data['data'][0]) {
      data['data'][0].forEach((element) => {
        this.programType.push(element.data.name);
      });
    }

    // if (data['data']['program_theme']) {
    if (data['data'][1]) {
      data['data'][1].forEach((element) => {
        this.programTheme.push(element.data.name);
      });
    }

  }

  handleResponseForStudentFaculty(data, type) {

    if (type == 'faculty') {
      if (data) {
        data.forEach((element) => {
          this.facultyList[element.Id] = element.name;
        });


      }
    } else if (type == 'student') {
      if (data) {
        data.forEach((element) => {
          this.studentList[element.Id] = element.name;
        });
      }
    }

  }

  validateOtherField($event, type) {

    if (type == "program_type") {
      if (this.activityForm.get('program_type').value == "Other") {
        this.activityForm.get('program_other').setValidators([Validators.required]);
        this.activityForm.get('program_other').updateValueAndValidity();
      } else {
        //this.carmForm.get('name').clearValidators();
        this.activityForm.get('program_other').clearValidators();
        this.activityForm.get('program_other').updateValueAndValidity();
      }
      this.program_type_other_flg = this.activityForm.get('program_type').value == "Other" ? false : true;
    }
    //  else if (type == "program_theme") {
    //   if (this.activityForm.get('program_theme').value == "Other") {
    //     this.activityForm.get('programtheme_other').setValidators([Validators.required]);
    //     this.activityForm.get('programtheme_other').updateValueAndValidity();
    //   } else {
    //     this.activityForm.get('programtheme_other').clearValidators();
    //     this.activityForm.get('programtheme_other').updateValueAndValidity();
    //   }
    //   this.program_theme_other_flg = this.activityForm.get('program_theme').value == "Other" ? false : true;
    // }
  }

  onFileChange(event, type) {


    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (type == 'photograph1') {
        this.activityForm.patchValue({
          photograph1_file: file
        });
      } else if (type == 'photograph2') {
        this.activityForm.patchValue({
          photograph2_file: file
        });
      } else if (type == 'brochure') {
        this.activityForm.patchValue({
          brochure_file: file
        });
      }
    }
  }

  handleSubmitResponse(data) {
    if (data['status'] == '1') {
      this.toastr.success(data['msg']);

      setTimeout(() => {

        this.router.navigate(['/institute/activity/monthly_report/' + this.event_id], { queryParams: { action: 'edit' } });

        this.instituteMaster
          .getMonthlyReportData(this.event_id)
          .subscribe((data) => this.handleResponseFacultyData(data));

      }, 2000);

    } else {
      if (data['ERROR'] == "YES") {
        this.toastr.error(data['MSG']);
      } else {
        this.toastr.error('Kindly check the details!');
      }

    }
  }

  onSubmit(values) {
    var brochure = this.activityForm.get('brochure_file').value
    if(this.urlReport==null || this.urlReport==undefined){

      if(brochure==null||brochure==undefined){

        this.toastr.error("Session Plan/Brochure/Document Overall report of the activity file is requied ")
      }
      else{

        var date = this.activityForm.get('activity_duration').value
            if(date=="NA" || date==null || date == undefined){
              this.toastr.error("Duration of the activity (in mins) is required!")

            }else{

              // if(data.student_participants<40)
              //   {
              //     this.toastr.error("Minimum 40 Student Participants required.")
              //     return false;
              //   }


          if (this.activityForm.valid) {
            var data = this.activityForm.value;

            this.social = [];
            this.social1 = [];
            this.social2 = [];
            this.social3 = [];
            this.social.push((data.twitter_checkbox) ? '1' : '0');
            this.social1.push((data.fb_checkbox) ? '1' : '0');
            this.social2.push((data.instagram_checkbox) ? '1' : '0');
            this.social3.push((data.linkedIn_checkbox) ? '1' : '0');

            // if ((this.social['0'] == '1') || (this.social1['0'] == '1') || (this.social2['0'] == '1') || (this.social3['0'] == '1')) {

            var formData = new FormData();
            formData.append('eventId', this.event_id);

            formData.append('action', this.action);

            formData.append('social_media[]', (data.twitter_checkbox) ? '1' : '0');
            formData.append('social_media[]', data.fb_checkbox ? '2' : '0');
            formData.append('social_media[]', data.instagram_checkbox ? '3' : '0');
            formData.append('social_media[]', data.linkedIn_checkbox ? '4' : '0');

            formData.append('social_media_url[]', data.twitter_url);
            formData.append('social_media_url[]', data.fb_url);
            formData.append('social_media_url[]', data.instagram_url);
            formData.append('social_media_url[]', data.linkedin_url);


            formData.append('financialYear', data.academic);
            formData.append('eventDriven', data.program);
            formData.append('activityName', data.program_name);
            formData.append('quarter', data.quater);
            formData.append('eventType', data.program_type);
            formData.append('otherProgramType', data.program_other);
            formData.append('eventTheme', data.program_theme);
            formData.append('activity_duration', data.activity_duration);

            formData.append('fromDate', this.formatDate(data.start_date));
            formData.append('toDate', this.formatDate(data.end_date));

            formData.append('internalParticipant', data.external_participants);
            formData.append('studentParticipants', data.student_participants);
            formData.append('facultyParticipants', data.faculty_participants);
            formData.append('expenditureAmount', data.expenditure_amount);
            formData.append('remark', data.remark);
            formData.append('session', data.session);
            formData.append('objective', data.objective);
            formData.append('benefit_learning', data.benefit_learning);
            //formData.append('objective',data.objective);
            formData.append('facultyMember', data.facultyMember);
            formData.append('studentMember', data.studentMember);
            formData.append('video', data.video);

            var photo1 = this.activityForm.get('photograph1_file').value
            var photo2 = this.activityForm.get('photograph2_file').value
            var brochure = this.activityForm.get('brochure_file').value



            formData.append('Photograph1', photo1);
            formData.append('Photograph2', photo2);
            formData.append('reportUpload', brochure);


            this.instituteMaster
              .monthlyReportSubmit(formData)
              .subscribe((data) => this.handleSubmitResponse(data));
            // }
            // else{
            //   this.toastr.error("Promotion in any one social media is mandatory!");
            // }
          }
        }
      }
    }else{

      var date = this.activityForm.get('activity_duration').value
      if(date=="NA" || date==null || date == undefined){
        this.toastr.error("Duration of the activity (in mins) is required!")

      }else{
        if (this.activityForm.valid) {
          var data = this.activityForm.value;

          // if(data.student_participants<40)
          //   {
          //     this.toastr.error("Minimum 40 Student Participants required.")
          //     return false;
          //   }

          this.social = [];
          this.social.push((data.twitter_checkbox) ? '1' : '0');
          this.social1 = [];
          this.social2 = [];
          this.social3 = [];
          this.social1.push((data.fb_checkbox) ? '1' : '0');
          this.social2.push((data.instagram_checkbox) ? '1' : '0');
          this.social3.push((data.linkedIn_checkbox) ? '1' : '0');

          // if ((this.social['0'] == '1') || (this.social1['0'] == '1') || (this.social2['0'] == '1') || (this.social3['0'] == '1')) {

          var formData = new FormData();
          formData.append('eventId', this.event_id);

          formData.append('action', this.action);

          formData.append('social_media[]', (data.twitter_checkbox) ? '1' : '0');
          formData.append('social_media[]', data.fb_checkbox ? '2' : '0');
          formData.append('social_media[]', data.instagram_checkbox ? '3' : '0');
          formData.append('social_media[]', data.linkedIn_checkbox ? '4' : '0');

          formData.append('social_media_url[]', data.twitter_url);
          formData.append('social_media_url[]', data.fb_url);
          formData.append('social_media_url[]', data.instagram_url);
          formData.append('social_media_url[]', data.linkedin_url);

          formData.append('financialYear', data.academic);
          formData.append('eventDriven', data.program);
          formData.append('activityName', data.program_name);
          formData.append('quarter', data.quater);
          formData.append('eventType', data.program_type);
          formData.append('otherProgramType', data.program_other);
          formData.append('eventTheme', data.program_theme);
          formData.append('activity_duration', data.activity_duration);

          formData.append('fromDate', this.formatDate(data.start_date));
          formData.append('toDate', this.formatDate(data.end_date));

          formData.append('internalParticipant', data.external_participants);
          formData.append('studentParticipants', data.student_participants);
          formData.append('facultyParticipants', data.faculty_participants);
          formData.append('expenditureAmount', data.expenditure_amount);
          formData.append('remark', data.remark);
          formData.append('session', data.session);
          formData.append('objective', data.objective);
          formData.append('benefit_learning', data.benefit_learning);
          //formData.append('objective',data.objective);
          formData.append('facultyMember', data.facultyMember);
          formData.append('studentMember', data.studentMember);
          formData.append('video', data.video);

          var photo1 = this.activityForm.get('photograph1_file').value
          var photo2 = this.activityForm.get('photograph2_file').value
          var brochure = this.activityForm.get('brochure_file').value

          formData.append('Photograph1', photo1);
          formData.append('Photograph2', photo2);
          formData.append('reportUpload', brochure);


          this.instituteMaster
            .monthlyReportSubmit(formData)
            .subscribe((data) => this.handleSubmitResponse(data));
          // }
          // else{
          //   this.toastr.error("Promotion in any one social media is mandatory!");
          // }
        }
      }
    }

  }
}
