<!-- <div class="container">
	<div class="row">
		<marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();"><p class="iicCalFirstText1">Quarter 1 and Quarter 2 is merged and institutes can conduct the activities accordingly.</p></marquee>
	</div>
</div> -->

<mat-tab-group mat-align-tabs="center">
    <mat-tab label="IIC Calendar Activity">
      <app-institute-iic-calendar></app-institute-iic-calendar>
    </mat-tab>
    <mat-tab label="MIC Driven Activity">
      <app-institute-mic-driven></app-institute-mic-driven>
    </mat-tab>
    <mat-tab label="Celebration Activity">
      <app-institute-celebration></app-institute-celebration>
    </mat-tab>
    <mat-tab label="Self-Driven Activity">
      <app-institute-self-driven></app-institute-self-driven>
    </mat-tab>

    <mat-tab label="My Activity Calendar - 2023-24">
      <app-institute-my-activity202324></app-institute-my-activity202324>
    </mat-tab>
    <!-- <mat-tab label="My Activity Calendar - 2022-23">
      <app-institute-my-activity-2022-23></app-institute-my-activity-2022-23>
    </mat-tab> -->

    <!-- <mat-tab label="My Activity Calendar - 2021-22">
      <app-institute-my-activity-2021-22></app-institute-my-activity-2021-22>
    </mat-tab> -->

  <!-- <mat-tab label="My Activity Calendar - 2020-21">
    <app-institute-my-activity></app-institute-my-activity>
</mat-tab> -->

</mat-tab-group>

