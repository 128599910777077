<div class="container">
  <div class="row" color="primary">
    <div class="col-md-12">
      <h2 class="ss headTitle">
          I&E Collaborations</h2>
    </div>
      
  </div>

<p><a class="btn btn-info" routerLink="/institute/collaboration-add/Add" *ngIf="ForWhom=='Institute'">Add New</a></p>

<div class="table-responsive">
  <table class="table table-hover" style="padding:10px 30px;" >
    <thead class="facultyTable">
      <tr>
        <th>S.No</th>
        <th>Collaborated Agency Name</th>
        <th>Collaborated Agency Type</th>
        <th>Status Of Collaboration</th>
        <th>Date of MOU</th>
        <th>Collaboration MoU Valid upto</th>
        <th>MOU Document</th>
        <th *ngIf="ForWhom=='Institute'">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let st of page_data; let i=index;">
        <td>{{i + 1}}</td>
        <td>{{st.name}}</td>
        <td>{{st.type}}</td>
        <td>{{st.status}}</td>
        <td>{{st.date_of_mou}}</td>
        <td>{{st.period}}</td>
        <td>
          <button class="btn btn-info viewButton">
            <a href="{{baseUrl + st?.file}}" target="_blank">View File</a>
          </button>          
        </td>
        <td *ngIf="ForWhom=='Institute'">
          
          <p>
             <a class="btn btn-success editButton" routerLink="/institute/collaboration-add/{{st?.id}}">
                <span class="mat-button-wrapper">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true" data-mat-icon-type="font">create</mat-icon>&nbsp;Edit
                </span>
              </a>
          </p>
          <p>
            <a (click)="deleteitem(st.id)" class="btn btn-danger deleteButton">
              <span class="mat-button-wrapper">
                    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true" data-mat-icon-type="font">delete</mat-icon>&nbsp;Delete
              </span>
            </a>
          </p>
          
        </td>
      </tr>
    </tbody>
</table>
</div>
</div>