<div class="container">

  <div class="row addTeachingTopRow addTeachingTopRowExterMember">
    <div class="col-8" *ngIf="this.ForWhom != 'institute_student'">
      <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3" (click)='openDialog()' *ngIf="data?.facultyStatus == '1' && data?.studentStatus == '1'">      
        <mat-icon>add</mat-icon>Add Quarterly Council Meeting Details
      </button>

      <button mat-raised-button color='primary' class="mat-elevation-z6 mt-3"     
      *ngIf="data?.facultyStatus == '0' || data?.studentStatus == '0'">      
        <mat-icon>add</mat-icon>Fill Faculty and Student Status to enable Council Meeting
      </button>
    </div>
    <!-- <div class="col-2" *ngIf="!firstPage">
      <button type="button" (click)='previousPage()' class="btn btn-success">Previous</button>
    </div>
    <div class="col-2" *ngIf="!lastPage">
      <button type="button" (click)='nextPage()' class="btn btn-success">Next</button>
    </div> -->
  </div>




  <!-- <div class="row"> -->
  <!-- <div class="col-sm-6 col-md-3" *ngFor="let faculty of council;let i = index">
        <div class="card text-center text-white bg-info mb-4 card-shadow">
            <div class="card-body">
                <h4>{{faculty?.quarter}}</h4>
                <h4>Date : {{faculty?.date_conducted_meeting}}</h4>
                <div style="text-align: center">
                    <a href="{{baseUrl + faculty?.quarterly_plan}}" target="_blank">
                        <button type="button" class="btn btn-danger mt-2 mb-2">Download Quaterely Plan</button>
                    </a>
                </div>
                <div style="text-align: center">
                    <a href="{{baseUrl + faculty?.minutes_of_meeting}}" target="_blank">
                        <button type="button" class="btn btn-danger mt-2 mb-2">Download Minutes of
                            Meeting</button>
                    </a>
                </div>
                <div style="text-align: center">
                    <a href="{{baseUrl + faculty?.photo_upload}}" target="_blank">
                        <button type="button" class="btn btn-danger mt-2 mb-2">Download Photo Upload</button>
                    </a>
                </div>
                <div style="text-align: center">
                    <a href="{{baseUrl + faculty?.resolution}}" target="_blank">
                        <button type="button" class="btn btn-danger mt-2 mb-2">Download Resolution</button>
                    </a>
                </div>
            </div>
        </div>
    </div> -->

  <!-- <div class="row m-4"></div>
  <div class="row m-4"></div>
  <div class="row m-4"></div> -->

  <div class="row">
    <!-- <div class="col-xl-4 col-md-3 mb-4" *ngFor="let faculty of council;let i = index">
      <div class="aheto-pricing aheto-pricing--default  mat-elevation-z8">
        <div class="aheto-pricing__content">
          <h4 class="aheto-pricing__title t-bold">{{faculty?.quarter}}</h4>
          <div class="aheto-pricing__description">
            <a href="{{baseUrl + faculty?.quarterly_plan}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2">Download Quaterely Plan</button>
            </a>
          </div>
          <div class="aheto-pricing__description">
            <a href="{{baseUrl + faculty?.minutes_of_meeting}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2">Download Minutes of
                Meeting</button>
            </a>
          </div>
          <div class="aheto-pricing__description">
            <a href="{{baseUrl + faculty?.photo_upload}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2">Download Photo Upload</button>
            </a>
          </div>
          <div class="aheto-pricing__description">
            <a href="{{baseUrl + faculty?.resolution}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2">Download Resolution</button>
            </a>

          </div>
          <div>
            <button type="button" class="btn btn-secondary" (click)="openEditDialog(faculty)">Edit</button>
          </div>
          <div>
            <button type="button" class="btn btn-secondary" (click)="deleteDialog(faculty)">Delete</button>
          </div>
        </div>
      </div>
    </div> -->


    <div class="col-md-4" *ngFor="let faculty of council;let i = index">
      <div class="CardBox">
        <img src="assets/customCard/card-gradient-img.png" class="CardBoxContentTopImg">
        <img src="assets/customCard/card-top-banner.png" class="CardBoxContentTopBanner">
        <div class="CardBoxContentTopName">
          <!-- <h3>Quarter</h3> -->
          <h3>{{faculty?.quarter}}</h3>
        </div>

        <div class="CardBoxContent">
          <h3>Date Meeting Conducted:</h3>
          <p>{{faculty?.date_conducted_meeting}}</p>
        </div>

        <div class="CardBoxContent">
          <a href="{{baseUrl + faculty?.quarterly_plan}}" target="_blank">
            <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG">Download Quaterely Plan</button>
          </a>
        </div>

        <div class="CardBoxContent">
          <a href="{{baseUrl + faculty?.minutes_of_meeting}}" target="_blank">
            <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG">Download Minutes of
              Meeting</button>
          </a>
        </div>

        <div id="InstCouncilID" class="collapse">

          <div class="CardBoxContent">
            <a href="{{baseUrl + faculty?.photo_upload}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG">Download Photo of council
                meeting</button>
            </a>
          </div>

          <div class="CardBoxContent">
            <a href="{{baseUrl + faculty?.resolution}}" target="_blank">
              <button type="button" class="btn btn-danger mt-2 mb-2 instiDownloadButBG">Download Resolution</button>
            </a>
          </div>

        </div>

        <!--<div class="CardBoxContent">
          <div class="row">
            <div class="col-md-6">
              <div class="cardEditIcon">
                <a class="cardEditIconEdit" (click)="openEditDialog(faculty)"> <i class="fa fa-pencil-square-o"
                    aria-hidden="true"></i> Edit </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="cardEditIcon">
                <a class="cardEditIconDelete" (click)="deleteDialog(faculty)"> <i class="fa fa-trash-o"
                    aria-hidden="true"></i> Delete </a>
              </div>
            </div>
          </div>
        </div> -->

        <div class="CardBoxContent" *ngIf="this.ForWhom != 'institute_student'">
          <div class="row">
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="openEditDialog(faculty)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-primary btn-lg edit1" data-original-title="Edit Task">
                  <i class="fa fa-edit"> Edit</i>
                </a>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-button-action">
                <a (click)="deleteDialog(faculty)" data-toggle="tooltip" title=""
                  class="btn btn-link btn-danger deleteInst1" data-original-title="Remove">
                  <i class="fa fa-times"> Delete</i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="cardReadMore">

          <button data-toggle="collapse" data-target="#InstCouncilID" *ngIf="readMore" (click)="readMore=!readMore">Read
            More</button>
          <button data-toggle="collapse" data-target="#InstCouncilID" *ngIf="!readMore" (click)="readMore=!readMore">Read
            Less</button>

        </div>


      </div>
    </div>


  </div>



  <div *ngIf="council.length<1">
    <div class="card text-center text-white bg-info mb-4 card-shadow">
      <div class="card-body">
        <p class="card-text text-white"><i>No Counil Meeting added yet!</i></p>
      </div>
    </div>
  </div>
  <!-- </div> -->

</div>
