<!-- IIC Website Header -->
<app-iic-web-navbar></app-iic-web-navbar>

<!-- <div class="cbp-row wh-page-title-bar"> -->
	<!-- <div class="cbp-container"> -->
		<!-- <div class="one whole breadcrumbPadding"> -->
			<!-- <h1 class="page-title">IIC Implementation Team</h1> -->
		<!-- </div> -->
	<!-- </div> -->
<!-- </div> -->

<section class="newInnerIICHeading">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h1 class="page-title">IIC Implementation Team</h1>
				<div class="sq1"></div>
				<div class="sq2"></div>
				<div class="sq3"></div>
			</div>
		</div>
	</div>
</section>

<nav aria-label="breadcrumb">
  <ul class="breadcrumb iicWebBreadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
	<li class="breadcrumb-item"><a>About Us</a></li>
    <li class="breadcrumb-item active" aria-current="page">IIC Implementation Team</li>
  </ul>
</nav>

<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>National Implementation Team for Institution's Innovation Council</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<section>
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-4 mt-4">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/Sitharam.png" alt="Prof. Anil Sahasrabudhe"> </div>
					</div>
					<div class="card-body pt-0 cb-text">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Prof. T. G. Sitharam</h5>
					<p class="impTeamFirstPara">Chairman, AICTE</p>
				  </div>
				</div>
			</div>

			<div class="col-md-4 mt-4">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/cio.jpg" alt="Dr. Abhay Jere"> </div>
					</div>
					<div class="card-body pt-0 cb-text">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Dr. Abhay Jere</h5>
					<p class="impTeamFirstPara">Chief Innovation Officer</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
				  </div>
				</div>
			</div>

			<!-- <div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/mohit-gambhir.jpg" alt="Dr. Abhay Jere"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Dr. Mohit Gambhir</h5>
					<p class="impTeamFirstPara">Innovation Director</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
				  </div>
				</div>
			</div> -->
		</div>
	</div>
</section>

<section>
	<div class="container">
		<div class="row">
			<!-- pic1 -->
			<div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/dipan-sahu.jpg" alt="Mr. Dipan Sahu"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Mr. Dipan Sahu</h5>
					<p class="impTeamFirstPara">Assistant Innovation Director</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
					<p class="text-center cardSmallFont" style="color:#c71e23;">
					  <b>	dipan.sahu@aicte-india.org <br>
							dipan.sahu@gov.in <br>
						011 2958 1226
					</b>
					</p>

				  </div>
				</div>
				<!-- <p class="mt-3 w-100 float-left text-center"><strong><h2 style="text-align: center; color: #000;">National Coordinator</h2></strong></p> -->
			</div>
		</div>
	</div>
</section>

<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>Zonal Coordinators</h2>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- zonal incharge 1 start -->
<section class="zonalCordinaterBox">
	<div class="container">
		<div class="row asd">
			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/selva-rani.jpg" alt="Ms. Selvarani"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Southern/SRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>sro.iic.mic@aicte-india.org </b>
										</p>
										<!-- <h5 id="spacc1">North-West/NWRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nwro.iic.mic@aicte-india.org 
										   </b>
										</p> -->
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Selvarani</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Innovation Officer <br>
										MoE's Innovation Cell <br>
										011 2958 1513
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 </tr>
									 <tr>
										<td height="40" rowspan="1" id="td4">
										   Southern/SRO
										</td>
										<td id="td2">
										   Tamil Nadu
										</td>
										<td id="td2">
										   Puducherry
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:sro.iic.mic@aicte-india.org">sro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1513">011 2958 1513</a>
										</td>
									 </tr>
								  </table>
								</div>
								<!-- <div class="col-md-6">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>

									 <tr>
										<td height="140" rowspan="4" id="td4">
										   North-West/NWRO
										</td>
										<td id="td2">
										   Chandigarh
										</td>
										<td id="td2">
										   Delhi
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Haryana
										</td>
										<td id="td2">
										   Himachal Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Jammu and Kashmir
										</td>
										<td id="td2">
										   Punjab
										</td>
									 </tr>
									 <tr>
										<td  id="td2">
										   Rajasthan
										</td>
										<td  id="td2">
										</td>
									 </tr>
									 <tr>
									<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nwro.iic.mic@aicte-india.org">nwro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1513</a>
										</td>
									 </tr>
								  </table>
								</div> -->
							</div>
					   </div>
				</div>
			</div>
			
			<!-- <div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/udyan.jpg" alt="Mr. Udyan Maurya"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Northern/NRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nro.iic.mic@aicte-india.org </b>
										</p>
										<h5 id="spacc1">Eastern/ERO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>ero.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Udyan Maurya</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Startup Fellow <br>
										MoE's Innovation Cell <br>
										011 2958 1240
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-6">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>
									 <tr >
										<td height="60" rowspan="2" id="td4">
										   Northern/NRO
										</td>
										<td id="td2">
										   Bihar
										</td>
										<td id="td2">
										   Uttar Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Uttarakhand
										</td>
										<td id="td2">
										</td>
									 </tr>
									 <tr>
												<td colspan="3" style="padding:0">
												   <a class="emailText" href="mailto:nro.iic.mic@aicte-india.org">nro.iic.mic@aicte-india.org</a>
												</td>
											 </tr>
											 <tr>
												<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1223">011 2958 1240</a>
												</td>
											 </tr>
								  </table>
								</div>
								<div class="col-md-6">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 </tr>
									 <tr>
											<td height="258" rowspan="6" id="td4">
											   Eastern/ERO
											</td>
											<td id="td2">
											   Andaman and Nicobar Islands
											</td>
											<td id="td2">
											   Arunachal Pradesh
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Assam
											</td>
											<td id="td2">
											   Jharkhand
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Manipur
											</td>
											<td id="td2">
											   Meghalaya
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Mizoram
											</td>
											<td id="td2">
											   Nagaland
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Odisha/Orissa
											</td>
											<td id="td2">
											   Sikkim
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Tripura
											</td>
											<td id="td2">
											   West Bengal
											</td>
										 </tr>
										<tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:ero.iic.mic@aicte-india.org">ero.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1240">011 2958 1240</a>
											</td>
										</tr>
								  </table>
								</div>
							</div>
					   </div>
				</div>
			</div> -->
			
			
			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/abhishek-ranjan-kumar.jpg" alt="Mr. Abhishek Ranjan Kumar"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">South-Western/SWRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>swro.iic.mic@aicte-india.org </b>
										</p>
										<h5 id="spacc1">Central/CRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>cro.iic.mic@aicte-india.org
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Abhishek Ranjan Kumar</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Innovation Officer <br>
										MoE's Innovation Cell <br>
										011 2958 1517
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-6">
									<table>
									   <tr class="btn-primary-table">
									   <td id="td1">
										<b id="font1"> Zone </b>
									   </td>
									   <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									   </td>
									   
									   </tr>
									   <tr>
										<td id="td4">
										   South-West/SWRO
										</td>
										<td id="td2">
										   Karnataka
										</td>
										<td id="td2">
										   Kerala
										</td>
									   </tr>
									   <tr>
												<td colspan="3" style="padding:0">
													<a class="emailText" href="mailto:swro.iic.mic@aicte-india.org">swro.iic.mic@aicte-india.org</a>
												</td>
											</tr>
											<tr>
												<td colspan="3" style="padding:0">
													   <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
												</td>
											</tr>
									  </table>
								</div>
								<div class="col-md-6">
									<table>
									   <tr class="btn-primary-table">
										   <td id="td1">
												<b id="font1"> Zone </b>
										   </td>
										   <td id="td1" colspan="3">
												<b id="font1"> State
											</b>
										   </td>
										   
										</tr>
										   <tr >
												<td rowspan="2" id="td4">
												   Central/CRO
												</td>
												<td id="td2">
												   Chhattisgarh
												</td>
												<td id="td2">
												   Gujarat
												</td>
											</tr>
											<tr>
												<td id="td2">
												   Madhya Pradesh
												</td>
												<td id="td2">
												</td>
											</tr>
											<tr>
												<td colspan="3" style="padding:0">
													<a class="emailText" href="mailto:cro.iic.mic@aicte-india.org">cro.iic.mic@aicte-india.org</a>
												</td>
											</tr>
											<tr>
												<td colspan="3" style="padding:0">
													   <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
												</td>
											</tr>
									  </table>
								</div>
							</div>
					   </div>
				</div>
			</div>
			
			<!-- <div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/SurendarR.jpg" alt="Mr. SurendarR"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Western/WRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>wro.iic.mic@aicte-india.org</b>
										</p>
										<h5 id="spacc1">South-Central/SCRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>scro.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Surendar R</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Startup Fellow <br>
										MoE's Innovation Cell <br>
										011 2958 1337
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-6">
									<table>
										 <tr class="btn-primary-table">
											 <td id="td1">
												<b id="font1"> Zone </b>
											 </td>
											 <td id="td1" colspan="3">
												<b id="font1"> State
												</b>
											 </td>
										 </tr>
										 
										 <tr>
											<td rowspan="2" id="td4">
											   Western/WRO
											</td>
											<td id="td2">
											   Goa
											</td>
											<td id="td2">
											   Maharashtra
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Dadra and Nagar Haveli
											</td>
											<td id="td2">
											   Daman and Diu
											</td>
										 </tr>
										 <tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:wro.iic.mic@aicte-india.org">wro.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1235">011 2958 1337</a>
											</td>
										</tr>
									  </table>
								</div>
								<div class="col-md-6">
									<table>
										 <tr class="btn-primary-table">
											 <td id="td1">
												<b id="font1"> Zone </b>
											 </td>
											 <td id="td1" colspan="2">
												<b id="font1"> State
												</b>
											 </td>
										 </tr>
										 
										 <tr>
											<td>
											   South-Central/SCRO 
											</td>
											<td>
												Andhra Pradesh
											</td>
											<td>
											   Telangana
											</td>
										 </tr>
										
										 <tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:scro.iic.mic@aicte-india.org">scro.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1326">011 2958 1337</a>
											</td>
										</tr>
									  </table>
								</div>
							</div>
					   </div>
				</div>
			</div> -->

			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/SurendarR.jpg" alt="Mr. SurendarR"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Western/WRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>wro.iic.mic@aicte-india.org</b>
										</p>
										
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Surendar R</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Regional Consultant<br>
										MoE's Innovation Cell <br>
										011 2958 1336
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-12">
									<table>
										 <tr class="btn-primary-table">
											 <td id="td1">
												<b id="font1"> Zone </b>
											 </td>
											 <td id="td1" colspan="3">
												<b id="font1"> State
												</b>
											 </td>
										 </tr>
										 
										 <tr>
											<td rowspan="2" id="td4">
											   Western/WRO
											</td>
											<td id="td2">
											   Goa
											</td>
											<td id="td2">
											   Maharashtra
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Dadra and Nagar Haveli
											</td>
											<td id="td2">
											   Daman and Diu
											</td>
										 </tr>
										 <tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:wro.iic.mic@aicte-india.org">wro.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1235">011 2958 1336</a>
											</td>
										</tr>
									  </table>
								</div>
							</div>
					   </div>
				</div>
			</div>

			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1">
											<img class="card-img-top" src="assets/assets/images/imp-team/Jerry.png" alt="Jerry">
										</div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">North-Western/NWRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nwro.iic.mic@aicte-india.org</b>
										</p>
										
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Mr. Jerry Joshy</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Startup Fellow<br>
										MoE's Innovation Cell <br>
										011 2958 1225
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>

					   <div class="card back">
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>

									 <tr>
										<td height="140" rowspan="4" id="td4">
										   North-West/NWRO
										</td>
										<td id="td2">
										   Chandigarh
										</td>
										<td id="td2">
										   Delhi
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Haryana
										</td>
										<td id="td2">
										   Himachal Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Jammu and Kashmir
										</td>
										<td id="td2">
										   Punjab
										</td>
									 </tr>
									 <tr>
										<td  id="td2">
										   Rajasthan
										</td>
										<td  id="td2">
										</td>
									 </tr>
									 <tr>
									<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nwro.iic.mic@aicte-india.org">nwro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1225</a>
										</td>
									 </tr>
								  </table>
								</div>
							</div>
					   </div>





				</div>
			</div>

			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/Monika.png" alt="Monika"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Eastern/ERO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>ero.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Monika Choudhary</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">Young Professional <br>
										MoE's Innovation Cell <br>
										011 2958 1227
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 </tr>
									 <tr>
											<td height="258" rowspan="6" id="td4">
											   Eastern/ERO
											</td>
											<td id="td2">
											   Andaman and Nicobar Islands
											</td>
											<td id="td2">
											   Arunachal Pradesh
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Assam
											</td>
											<td id="td2">
											   Jharkhand
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Manipur
											</td>
											<td id="td2">
											   Meghalaya
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Mizoram
											</td>
											<td id="td2">
											   Nagaland
											</td>
										 </tr>
										 <tr>
											<td id="td2">
											   Odisha/Orissa
											</td>
											<td id="td2">
											   Sikkim
											</td>
										 </tr>
										 <tr >
											<td id="td2">
											   Tripura
											</td>
											<td id="td2">
											   West Bengal
											</td>
										 </tr>
										<tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:ero.iic.mic@aicte-india.org">ero.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1240">011 2958 1227</a>
											</td>
										</tr>
								  </table>
								</div>
							</div>
					   </div>
				</div>
			</div>

			

			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/Seema.png" alt="Seema"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">South-Central/SCRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>scro.iic.mic@aicte-india.org 
										   </b>
										</p>
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Seema Chhillar</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">DEO <br>
										MoE's Innovation Cell <br>
										011 2958 1227
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								
								<div class="col-md-12">
									<table>
										 <tr class="btn-primary-table">
											 <td id="td1">
												<b id="font1"> Zone </b>
											 </td>
											 <td id="td1" colspan="2">
												<b id="font1"> State
												</b>
											 </td>
										 </tr>
										 
										 <tr>
											<td>
											   South-Central/SCRO 
											</td>
											<td>
												Andhra Pradesh
											</td>
											<td>
											   Telangana
											</td>
										 </tr>
										
										 <tr>
											<td colspan="3" style="padding:0">
												<a class="emailText" href="mailto:scro.iic.mic@aicte-india.org">scro.iic.mic@aicte-india.org</a>
											</td>
										</tr>
										<tr>
											<td colspan="3" style="padding:0">
												   <a class="phoneText" href="tel:011 2958 1326">011 2958 1227</a>
											</td>
										</tr>
									  </table>
								</div>
							</div>
					   </div>
				</div>
			</div>
			<div class="col-xl-6 col-md-6 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5 newImpBoxCard">
							 <div class="row">
								<div class="col-md-6 cardBdrRight">
									<div class="card-img-block">
										<div class="imgCenterRound1"><img class="card-img-top" src="assets/assets/images/imp-team/Pooja.png" alt="Pooja"></div>
									 </div>
								</div>
								<div class="col-md-6">
									<div class="card-img-block1">
										<h5 id="spacc1">Northern/NRO</h5>
										<p class="text-center cardSmallFont" style="color:#c71e23;">
										   <b>nro.iic.mic@aicte-india.org </b>
										</p>
										
									 </div>
								</div>
							 </div>
						  </div>
						  <div class="card profile-card-5">
							<div class="row">
								<div class="col-md-12">
									<div class="card-body pt-0">
										<h5 class="card-title text-center cardTitleImplementation1" style="color: #012e5b">Ms. Pooja Sharma</h5>
										<p class="impTeamFirstPara1" style="color:#c71e23;">DEO <br>
										MoE's Innovation Cell <br>
										011 2958 1235
										</p>	
									 </div>
								</div>
							</div>
						  </div>
					   </div>
					   <div class="card back">
							<div class="row">
								<div class="col-md-12">
									<table>
									 <tr class="btn-primary-table">
									 <td id="td1">
										<b id="font1"> Zone </b>
									 </td>
									 <td id="td1" colspan="3">
										<b id="font1"> State
										</b>
									 </td>
									 
									 </tr>
									 <tr >
										<td height="60" rowspan="2" id="td4">
										   Northern/NRO
										</td>
										<td id="td2">
										   Bihar
										</td>
										<td id="td2">
										   Uttar Pradesh
										</td>
									 </tr>
									 <tr>
										<td id="td2">
										   Uttarakhand
										</td>
										<td id="td2">
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="emailText" href="mailto:nro.iic.mic@aicte-india.org">nro.iic.mic@aicte-india.org</a>
										</td>
									 </tr>
									 <tr>
										<td colspan="3" style="padding:0">
										   <a class="phoneText" href="tel:011 2958 1223">011 2958 1235</a>
										</td>
									 </tr>
								  </table>
								</div>
								
							</div>
					   </div>
				</div>
			</div>
			
			
		</div>
	</div>
</section>


<!-- zonal incharge 1 start -->
<!-- <section class="zonalCordinaterBox">
	<div class="container">
		<div class="row asd">
			 <div class="col-xl-3 col-md-3 mt-4 card-container">
					<div class="card-flip">
					   <div class="card front implementCardBox">
						  <div class="card profile-card-5">
							 <div class="card-img-block">
								<h5 id="spacc">Southern/SRO</h5>
								<div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/selva-rani.jpg" alt="Ms. Selvarani"></div>
							 </div>
							 <div class="card-body pt-0">
								<h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Ms. Selvarani</h5>
								<p class="text-center cardSmallFont" style="color:#c71e23;">
								   <b>	sro.iic.mic@aicte-india.org <br>
								   011 2958 1513
								   </b>
								</p>
							 </div>
						  </div>
					   </div>
					   <div class="card back">
							 <table>
							 <tr class="btn-primary-table">
							 <td id="td1">
								<b id="font1"> Zone </b>
							 </td>
							 <td id="td1" colspan="3">
								<b id="font1"> State
								</b>
							 </td>
							 </tr>
							 <tr>
								<td height="40" rowspan="1" id="td4">
								   Southern/SRO
								</td>
								<td id="td2">
								   Tamil Nadu
								</td>
								<td id="td2">
								   Puducherry
								</td>
							 </tr>
							 <tr>
								<td colspan="3" style="padding:0">
								   <a class="emailText" href="mailto:sro.iic.mic@aicte-india.org">sro.iic.mic@aicte-india.org</a>
								</td>
							 </tr>
							 <tr>
								<td colspan="3" style="padding:0">
								   <a class="phoneText" href="tel:011 2958 1513">011 2958 1513</a>
								</td>
							 </tr>
						  </table>
					   </div>
				</div>
			</div>

			
         <div class="col-xl-3 col-md-3 mt-4 card-container">
            <div class="card-flip">
               <div class="card front implementCardBox">
                  <div class="card profile-card-5">
                     <div class="card-img-block">
						<h5 id="spacc2">Northern/NRO</h5>
                        <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/udyan.jpg" alt="Mr. Udyan Maurya"></div>
                     </div>
                     <div class="card-body pt-0">
                        <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Udyan Maurya</h5>
                        <p class="text-center cardSmallFont" style="color:#c71e23;">
                           <b>nro.iic.mic@aicte-india.org <br>
                          
                           011 2958 1240
                           </b>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="card back">
                  
                  <table>
                     <tr class="btn-primary-table">
                     <td id="td1">
                        <b id="font1"> Zone </b>
                     </td>
                     <td id="td1" colspan="3">
                        <b id="font1"> State
                        </b>
                     </td>
                     
                     </tr>
                     <tr >
                        <td height="60" rowspan="2" id="td4">
                           Northern/NRO
                        </td>
                        <td id="td2">
                           Bihar
                        </td>
                        <td id="td2">
                           Uttar Pradesh
                        </td>
                     </tr>
                     <tr>
                        <td id="td2">
                           Uttarakhand
                        </td>
                        <td id="td2">
                        </td>
                     </tr>
                     <tr>
								<td colspan="3" style="padding:0">
								   <a class="emailText" href="mailto:nro.iic.mic@aicte-india.org">nro.iic.mic@aicte-india.org</a>
								</td>
							 </tr>
							 <tr>
								<td colspan="3" style="padding:0">
								   <a class="phoneText" href="tel:011 2958 1223">011 2958 1240</a>
								</td>
							 </tr>
                  </table>
                
               </div>
            </div>
        </div>

		<div class="col-xl-3 col-md-3 mt-4 card-container">
            <div class="card-flip">
               <div class="card front implementCardBox">
                  
                  <div class="card profile-card-5">
                     <div class="card-img-block">
						<h5 id="spacc2">North-West/NWRO</h5>
                        <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/selva-rani.jpg" alt="Ms. Selvarani"></div>
                     </div>
                     <div class="card-body pt-0">
                        <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Ms. Selvarani</h5>
                        <p class="text-center cardSmallFont" style="color:#c71e23;">
                           <b>nwro.iic.mic@aicte-india.org <br>
                          
                           011 2958 1513
                           </b>
                        </p>
                     </div>
                  </div>

               </div>
               <div class="card back">
                 
                  <table>
                     <tr class="btn-primary-table">
                     <td id="td1">
                        <b id="font1"> Zone </b>
                     </td>
                     <td id="td1" colspan="3">
                        <b id="font1"> State
                        </b>
                     </td>
                     
                     </tr>

                     <tr>
                        <td height="140" rowspan="4" id="td4">
                           North-West/NWRO
                        </td>
                        <td id="td2">
                           Chandigarh
                        </td>
                        <td id="td2">
                           Delhi
                        </td>
                     </tr>
                     <tr>
                        <td id="td2">
                           Haryana
                        </td>
                        <td id="td2">
                           Himachal Pradesh
                        </td>
                     </tr>
                     <tr>
                        <td id="td2">
                           Jammu and Kashmir
                        </td>
                        <td id="td2">
                           Punjab
                        </td>
                     </tr>
                     <tr>
                        <td  id="td2">
                           Rajasthan
                        </td>
                        <td  id="td2">
                        </td>
                     </tr>
                     <tr>
					<td colspan="3" style="padding:0">
						   <a class="emailText" href="mailto:nwro.iic.mic@aicte-india.org">nwro.iic.mic@aicte-india.org</a>
						</td>
					 </tr>
					 <tr>
						<td colspan="3" style="padding:0">
						   <a class="phoneText" href="tel:011 2958 1223">011 2958 1513</a>
						</td>
					 </tr>
                  </table>
                  
               </div>
            </div>
        </div>


        <div class="col-md-3 mt-4 card-container">
          <div class="card-flip">
             <div class="card front implementCardBox">
              <div class="card profile-card-5">
               <div class="card-img-block">
				<h5 id="spacc2">South-Western/SWRO</h5>
                <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/abhishek-ranjan-kumar.jpg" alt="Mr. Abhishek Ranjan Kumar"></div>
               </div>
               <div class="card-body pt-0">
                <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Abhishek Ranjan Kumar</h5>
                <p class="text-center cardSmallFont" style="color:#c71e23;">
                   <b>	swro.iic.mic@aicte-india.org <br>
                   
                   011 2958 1517
                   </b>
                </p>
               </div>
              </div>

             </div>
             <div class="card back">
              
              <table>
               <tr class="btn-primary-table">
               <td id="td1">
                <b id="font1"> Zone </b>
               </td>
               <td id="td1" colspan="3">
                <b id="font1"> State
                </b>
               </td>
               
               </tr>
               <tr>
                <td id="td4">
                   South-West/SWRO
                </td>
                <td id="td2">
                   Karnataka
                </td>
                <td id="td2">
                   Kerala
                </td>
               </tr>
               <tr>
						<td colspan="3" style="padding:0">
							<a class="emailText" href="mailto:swro.iic.mic@aicte-india.org">swro.iic.mic@aicte-india.org</a>
						</td>
					</tr>
					<tr>
						<td colspan="3" style="padding:0">
							   <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
						</td>
					</tr>
              </table>
              
             </div>
          </div>
        </div>



			<div class="col-md-3 mt-4 card-container">
          <div class="card-flip">
             <div class="card front implementCardBox">
             
              <div class="card profile-card-5">
               <div class="card-img-block">
				<h5 id="spacc2">Central/CRO</h5>
                <div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/abhishek-ranjan-kumar.jpg" alt="Mr. Abhishek Ranjan Kumar"></div>
               </div>
               <div class="card-body pt-0">
                <h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Abhishek Ranjan Kumar</h5>
                <p class="text-center cardSmallFont" style="color:#c71e23;">
                   <b>	cro.iic.mic@aicte-india.org <br>
                  
                   011 2958 1517
                   </b>
                </p>
               </div>
              </div>

             </div>
             <div class="card back">
             
              <table>
               <tr class="btn-primary-table">
	               <td id="td1">
	                	<b id="font1"> Zone </b>
	               </td>
	               <td id="td1" colspan="3">
	                	<b id="font1"> State
	                </b>
	               </td>
	               
	            </tr>
				   <tr >
						<td rowspan="2" id="td4">
						   Central/CRO
						</td>
						<td id="td2">
						   Chhattisgarh
						</td>
						<td id="td2">
						   Gujarat
						</td>
				 	</tr>
				 	<tr>
						<td id="td2">
						   Madhya Pradesh
						</td>
						<td id="td2">
						</td>
				 	</tr>
				 	<tr>
						<td colspan="3" style="padding:0">
							<a class="emailText" href="mailto:cro.iic.mic@aicte-india.org">cro.iic.mic@aicte-india.org</a>
						</td>
					</tr>
					<tr>
						<td colspan="3" style="padding:0">
							   <a class="phoneText" href="tel:011 2958 1517">011 2958 1517</a>
						</td>
					</tr>
              </table>
              
             </div>
          </div>
        </div>

			<div class="col-xl-3 col-md-3 mt-4 card-container">
				<div class="card-flip">
				   <div class="card front implementCardBox">
					 
					  <div class="card profile-card-5">
						 <div class="card-img-block">
							<h5 id="spacc2">Western/WRO</h5>
							<div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/SurendarR.jpg" alt="Mr. SurendarR"></div>
						 </div>
						 <div class="card-body pt-0">
							<h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Surendar R</h5>
							<p class="text-center cardSmallFont" style="color:#c71e23;">
							   <b>wro.iic.mic@aicte-india.org <br>
							  
							   011 2958 1337
							   </b>
							</p>
						 </div>
					  </div>
				   </div>
				   <div class="card back">
					 
					  <table>
						 <tr class="btn-primary-table">
							 <td id="td1">
								<b id="font1"> Zone </b>
							 </td>
							 <td id="td1" colspan="3">
								<b id="font1"> State
								</b>
							 </td>
						 </tr>
						 
						 <tr>
							<td rowspan="2" id="td4">
							   Western/WRO
							</td>
							<td id="td2">
							   Goa
							</td>
							<td id="td2">
							   Maharashtra
							</td>
						 </tr>
						 <tr >
							<td id="td2">
							   Dadra and Nagar Haveli
							</td>
							<td id="td2">
							   Daman and Diu
							</td>
						 </tr>
						 <tr>
							<td colspan="3" style="padding:0">
								<a class="emailText" href="mailto:wro.iic.mic@aicte-india.org">wro.iic.mic@aicte-india.org</a>
							</td>
						</tr>
						<tr>
							<td colspan="3" style="padding:0">
								   <a class="phoneText" href="tel:011 2958 1235">011 2958 1337</a>
							</td>
						</tr>
					  </table>
					 
				   </div>
				</div>
			 </div>

			 <div class="col-xl-3 col-md-3 mt-4 card-container">
				<div class="card-flip">
				   <div class="card front implementCardBox">
					  <div class="card profile-card-5">
						 <div class="card-img-block">
							<h5 id="spacc2">Eastern/ERO</h5>
							<div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/udyan.jpg" alt="Mr. Udyan Maurya"></div>
						 </div>
						 <div class="card-body pt-0">
							<h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Udyan Maurya</h5>
							<p class="text-center cardSmallFont" style="color:#c71e23;">
							   <b>	ero.iic.mic@aicte-india.org<br>
							   011 2958 1240
							   </b>
							</p>
						 </div>
					  </div>

				   </div>
				   <div class="card back">
					  <table>
						 <tr class="btn-primary-table">
						 <td id="td1">
							<b id="font1"> Zone </b>
						 </td>
						 <td id="td1" colspan="3">
							<b id="font1"> State
							</b>
						 </td>
						 </tr>
						 <tr>
								<td height="258" rowspan="6" id="td4">
								   Eastern/ERO
								</td>
								<td id="td2">
								   Andaman and Nicobar Islands
								</td>
								<td id="td2">
								   Arunachal Pradesh
								</td>
							 </tr>
							 <tr >
								<td id="td2">
								   Assam
								</td>
								<td id="td2">
								   Jharkhand
								</td>
							 </tr>
							 <tr>
								<td id="td2">
								   Manipur
								</td>
								<td id="td2">
								   Meghalaya
								</td>
							 </tr>
							 <tr>
								<td id="td2">
								   Mizoram
								</td>
								<td id="td2">
								   Nagaland
								</td>
							 </tr>
							 <tr>
								<td id="td2">
								   Odisha/Orissa
								</td>
								<td id="td2">
								   Sikkim
								</td>
							 </tr>
							 <tr >
								<td id="td2">
								   Tripura
								</td>
								<td id="td2">
								   West Bengal
								</td>
							 </tr>
							<tr>
								<td colspan="3" style="padding:0">
									<a class="emailText" href="mailto:ero.iic.mic@aicte-india.org">ero.iic.mic@aicte-india.org</a>
								</td>
							</tr>
							<tr>
								<td colspan="3" style="padding:0">
									   <a class="phoneText" href="tel:011 2958 1240">011 2958 1240</a>
								</td>
							</tr>
					  </table>
				   </div>
				</div>
			 </div>


			 <div class="col-md-3 mt-4 card-container">
				<div class="card-flip">
				   <div class="card front implementCardBox">
					  <div class="card profile-card-5">
						 <div class="card-img-block">
							<h5 id="spacc2">South-Central/SCRO</h5>
							<div class="imgCenterRound"><img class="card-img-top" src="assets/assets/images/imp-team/SurendarR.jpg" alt="Mr. Surendar R"></div>
						 </div>
						 <div class="card-body pt-0">
							<h5 class="card-title text-center cardTitleImplementation" style="color: #012e5b">Mr. Surendar R</h5>
							<p class="text-center cardSmallFont" style="color:#c71e23;">
							   <b>	scro.iic.mic@aicte-india.org <br>
							   011 2958 1337
							   </b>
							</p>
						 </div>
					  </div>

				   </div>
				   <div class="card back">
					 
					  <table>
						 <tr class="btn-primary-table">
							 <td id="td1">
								<b id="font1"> Zone </b>
							 </td>
							 <td id="td1" colspan="2">
								<b id="font1"> State
								</b>
							 </td>
						 </tr>
						 
						 <tr>
							<td>
							   South-Central/SCRO 
							</td>
							<td>
							    Andhra Pradesh
							</td>
							<td>
							   Telangana
							</td>
						 </tr>
						
						 <tr>
							<td colspan="3" style="padding:0">
								<a class="emailText" href="mailto:scro.iic.mic@aicte-india.org">scro.iic.mic@aicte-india.org</a>
							</td>
						</tr>
						<tr>
							<td colspan="3" style="padding:0">
								   <a class="phoneText" href="tel:011 2958 1326">011 2958 1337</a>
							</td>
						</tr>
					  </table>
					 
				   </div>
				</div>
			</div>

		</div>
	</div>
</section> -->

<div class="cbp-row wh-content iicTeamBox">
	<div class="cbp-container wh-padding">
		<div class="vc_row innovationAmbassadorBox ambPadding1">
			<div class="vc_col-md-12">
				<div class="headingAmbassador text-center">
					<h2>IT Team</h2>
				</div>
			</div>
		</div>
	</div>
</div>


<section class="zonalCordinaterBox">
	<div class="container">
		<div class="row">
			<!-- pic1 -->
			<div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/selva-rani.jpg" alt="Prof. Anil Sahasrabudhe"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Ms. Selvarani</h5>
					<p class="impTeamFirstPara">Innovation Officer</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
					<!-- <p class="text-center cardSmallFont" style="color:#c71e23;">
					  <b>	dipan.sahu@aicte-india.org <br>
						011 2958 1226
					</b>
					</p> -->

				  </div>
				</div>
				<!-- <p class="mt-3 w-100 float-left text-center"><strong><h2 style="text-align: center; color: #000;">National Coordinator</h2></strong></p> -->
			</div>

			<!-- <div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/rangnath.jpg" alt="Dr. Abhay Jere"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Mr. Rangnath Ojha</h5>
					<p class="impTeamFirstPara">Software Developer</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
				  </div>
				</div>
			</div> -->

			<div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/sujeet.jpg" alt="Dr. Abhay Jere"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Mr. Sujeet Kumar</h5>
					<p class="impTeamFirstPara">UI/UX Designer</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
					<!-- <p class="text-center cardSmallFont" style="color:#c71e23;">
					  <b>	dipan.sahu@aicte-india.org <br>
						011 2958 1226
					</b>
					</p> -->

				  </div>
				</div>
				<!-- <p class="mt-3 w-100 float-left text-center"><strong><h2 style="text-align: center; color: #000;">National Coordinator</h2></strong></p> -->
			</div>
			<div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/sandip.jpg" alt="Sandip Patil"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Mr. Sandip Patil</h5>
					<p class="impTeamFirstPara">UI/UX Designer</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
				  </div>
				</div>
			</div>
			<div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/Ankit.png" alt="Ankit Kumar"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Mr. Ankit Kumar</h5>
					<p class="impTeamFirstPara">Sr.Software Engineer</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
				  </div>
				</div>
			</div>
			<div class="col-xl-4 col-md-4 mt-4 center ">
				<div class="card profile-card-5 implementCardBox">
					<div class="card-img-block">
						<div class="imgCenterRound"> <img class="card-img-top" src="assets/assets/images/imp-team/SurendarR.jpg" alt="Mr. SurendarR"> </div>
					</div>
					<div class="card-body pt-0">
					<h5 class="card-title text-center cardTitleImplementation cardImpleTitleTop" style="color: #012e5b">Mr. SurendarR</h5>
					<p class="impTeamFirstPara">Regional Consultant</p>
					<p class="impTeamFirstPara">MoE's Innovation Cell</p>
				  </div>
				</div>
			</div>
		</div>

	</div>
</section>

<!-- <div id="content" class="content container"> -->
	<!-- <div class="panel-body table-responsive"> -->
		<!-- <table cellspacing="0" border="0" width="95%" style="margin:auto;"> -->
	<!-- <colgroup width="184"></colgroup> -->
	<!-- <colgroup width="186"></colgroup> -->
	<!-- <colgroup width="249"></colgroup> -->
	<!-- <colgroup width="255"></colgroup> -->

	<!-- <tr class="btn-primary-table table_design"> -->
		<!-- <td colspan="3" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;" height="48" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Mr. Dipan Sahu <br>National Coordinator<br>dipan.sahu@aicte-india.org<br>011 2958 1226</font></b></td> -->

	<!-- </tr> -->
	<!-- <tr class="btn-primary-table table_design"> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="42" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Zone</font></b></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">State</font></b></td> -->

		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><b><font face="Arial" color="#000000">Zone Incharge</font></b></td> -->
	<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Southern/SRO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Puducherry</font></td> -->

		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=14 align="center" valign=middle bgcolor="#F2F2F2"><font face="Arial" color="#000000">Ms. Selvarani<br>selva.rani@aicte-india.org<br>011 2958 1513</font></td> -->
	<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Tamil Nadu</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=12 height="258" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Eastern/ERO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Andaman and Nicobar Islands</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Arunachal Pradesh</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Assam</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Jharkhand</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Manipur</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Meghalaya</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Mizoram</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Nagaland</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Odisha/Orissa</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Sikkim</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">Tripura</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#F2F2F2"><font face="Arial" color="#000000">West Bengal</font></td> -->

		<!-- </tr> -->
		<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="60" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Northern/NRO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Bihar</font></td> -->

		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=10 align="center" valign=middle bgcolor="#FFFFFF"><font face="Arial" color="#000000">Mr. Ankush Sharma<br>ankush.sharma@aicte-india.org<br>011 2958 1223<br></font></td> -->
	<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttar Pradesh</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Uttarakhand</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=7 height="140" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#222222">North-West/NWRO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Chandigarh</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Delhi</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Haryana</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Himachal Pradesh</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Jammu and Kashmir</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Punjab</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Rajasthan</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=3 height="60" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Central/CRO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Chhattisgarh</font></td> -->

		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=5 align="center" valign=middle bgcolor="#E7E6E6"><font face="Arial" color="#000000">Mr. Sunny Atwal<br>yp.mic@aicte-india.org<br>011 2958 1223</font></td> -->
	<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Gujarat</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Madhya Pradesh</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">South-Central/SCRO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Andhra Pradesh</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#E7E6E6"><font face="Arial" color="#000000">Telangana</font></td> -->

		<!-- </tr> -->


	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=2 height="40" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">South-West/SWRO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Karnataka</font></td> -->

		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=6 align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Mr. Pankaj Pandey<br>pankajpandey@aicte-india.org<br>011 2958 1239<br></font></td> -->
	<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Kerala</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan=4 height="80" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Western/WRO</font></td> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Dadra and Nagar Haveli</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Daman and Diu</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Goa</font></td> -->

		<!-- </tr> -->
	<!-- <tr> -->
		<!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle" bgcolor="#FFFFFF"><font face="Arial" color="#000000">Maharashtra</font></td> -->

		<!-- </tr> -->
<!-- </table><br><br>								 -->
								<!-- </div>	 -->
	<!-- </div> -->

<!-- IIC Website Footr -->
<app-iic-web-footer></app-iic-web-footer>
