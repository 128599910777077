import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InstituteMyCouncilService } from 'src/app/Services/institute-my-council.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { JarwisService } from 'src/app/Services/jarwis.service';
import { emailValidator } from 'src/app/app.component';
import { InstituteDashboardService } from 'src/app/Services/institute-dashboard.service';
import { DashboardDialogComponent } from '../../institute-dashboard/institute-dashboard.component';

@Component({
  selector: 'app-hoi-details',
  templateUrl: './hoi-details.component.html',
  styleUrls: ['./hoi-details.component.css'],
})
export class HoiDetailsComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'email',
    'mobile_number',
    'CURRENT_INSTITUTE_ADDRESS',
  ];

  dataSource: MatTableDataSource<any>;
  liveData: any;
  email: any;
  names: any;
  mobile_number: any;

  hoiForm: FormGroup;
  public show: boolean = false;
  public buttonName: any = 'Show';
  institute_name: any;
  institute_address: string;
  institute_city: any;
  institute_state: any;
  aishe_code: any;
  commentFlag: boolean = false;
  commentFlag1: boolean = false;
  name: any;
  ss: any;
  alternate_email: any;
  alternate_phone: any;
  ratingValue: any;
  buttonShow: boolean;
  ForWhom: any;

  constructor(public dialog: MatDialog,
    private dashboardService: InstituteDashboardService,
    private formBuilder: FormBuilder,
    private instituteMyCouncilService: InstituteMyCouncilService,
    private toastr: ToastrService,
    private jarwis: JarwisService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  headElements = ['NAME', 'EMAIL', 'PHONE', 'EDIT'];

  ngOnInit(): void {
    this.hoiForm = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])],
      // aishe_code: [null],
      mobile_number: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      alternate_email: [null, Validators.compose([Validators.required, emailValidator])],
      alternate_phone: [null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
          Validators.pattern('^[0-9]*$'),
        ]),
      ]
    });

    this.getHoiDetails();
    this.getRatingStatus();
	this.ForWhom="";
	this.ForWhom=localStorage.getItem('ForWhom');

  }




getRatingStatus(){
  this.instituteMyCouncilService.getRating().subscribe(
    data => this.setRatingStatus(data));
}

setRatingStatus(data){
  this.ratingValue=data['rating']['aishe_popup_status'];
  if(this.ratingValue==2){
    this.buttonShow=true;
  }
}




  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = 'Hide';
    } else {
      this.buttonName = 'Show';
    }
  }

  getHoiDetails() {
    this.instituteMyCouncilService
      .getHOI('')
      .subscribe((data) => this.setData(data));
  }

  getAisheDetails(data) {
    this.jarwis
      .getAisheDetails(data)
      .subscribe((data) => this.getInstituteDetails(data));
  }

  getInstituteDetails(data) {
    this.institute_name = data['aisheDetails']['0']['name'];
    this.institute_address =
      data['aisheDetails']['0']['address_line1'] +
      ' ' +
      data['aisheDetails']['0']['address_line2'];
    this.institute_city = data['aisheDetails']['0']['city'];
    this.institute_state = data['aisheDetails']['0']['state'];

  }

  getAishe(data) {
    this.jarwis
      .getAisheDetails(data)
      .subscribe((data) => this.getInstituteDetails(data));
  }

  setData(data) {  
    this.liveData = data;

    this.hoiForm.get('name').setValue(data['hoiDetails']['name']);
    this.hoiForm.get('mobile_number').setValue(data['hoiDetails']['phone']);
    this.hoiForm.get('alternate_email').setValue(data['hoiDetails']['alternate_email']);
    this.hoiForm.get('alternate_phone').setValue(data['hoiDetails']['alternate_phone']);

    this.names = data['hoiDetails']['name'];
    this.email = data['hoiDetails']['email'];
    this.mobile_number = data['hoiDetails']['phone'];
    this.alternate_email = data['hoiDetails']['alternate_email'];
    this.alternate_phone = data['hoiDetails']['alternate_phone'];
    this.aishe_code = data['hoiDetails']['aishe_code'];

    this.name = this.names;
    if (
      this.liveData['hoiDetails']['aishe_code'] != 'null' ||
      this.liveData['hoiDetails']['aishe_code'] != '' || this.liveData['hoiDetails']['aishe_code'] != null
    ) {
      this.commentFlag1 = true;
      this.getAishe(this.aishe_code);
    } else {
      this.commentFlag = true;
    }
  }

  onSubmit(values) {
    this.instituteMyCouncilService
      .updateHOI(values)
      .subscribe((data) => this.handleResponse(data));
    this.getHoiDetails();

    this.show = false;
  }

  handleResponse(data) {

    if (data['1'] == 'Success') {
      this.toastr.success('Updated Successfully !');
      // this.setData(data);
      this.getHoiDetails();
    } else if (data['message'] == "Error") {
      this.toastr.error(data['reason']);
    }
  }

  backPage() {
    this.show = false;
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return true;

    return false;
  }

  isSpace(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }

  isCharKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode >= 48 && charCode <= 57) return false;

    return true;
  }





// Dialog


openDialog() {
  const dialogRef = this.dialog.open(DashboardDialogComponent, { disableClose: true });
  dialogRef.afterClosed().subscribe((result) => {
  });
}



}
