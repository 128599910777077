<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css">

<link rel="stylesheet" href="/assets/institute/css/style.css" type="text/css">
<link rel="stylesheet" href="/assets/institute/css/login_style.css" type="text/css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/util_new.css">
<link rel="stylesheet" type="text/css" href="/assets/institute/css/main_new.css">

<style>
  .navbar-nav>li {
    float: none !important;
    text-align: center !important;
    line-height: 24px;
  }
</style>

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <span class="login100-form-title p-b-20">
        <img src="assets/institute/images/logoiic.png" class="loginIICLogo signupIICLogo">
      </span>
      <span class="login100-form-title loginInstiTopContent">
        <h2>Sign Up</h2>
      </span>
      <form id="signup-form" class="form-horizontal" [formGroup]="signupForm" (submit)="onSubmit(signupForm.value)"
        style="color: black !important;">
        <div [hidden]="!error">
          {{error}}
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Name of the Head of Institute</mat-label>
            <input matInput placeholder="Name of the Head of Institute" formControlName="name" required>
            <mat-error *ngIf="signupForm.controls.name.errors?.required">Name of the Head of Institute is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
            <mat-label>Email Id of the Head of Institute</mat-label>
            <input matInput placeholder="Email Id of the Head of Institute" formControlName="email" required
              (keypress)="isSpace($event)">
            <mat-error *ngIf="signupForm.controls.email.errors?.required">Email Id of the Head of Institute is required
            </mat-error>
            <mat-error *ngIf="signupForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stay_current_portrait</mat-icon>
            <mat-label>Mobile Number of the Head of Institute</mat-label>
            <input matInput placeholder="Mobile Number of the Head of Institute" formControlName="mobile_number"
              required minlength="10" maxlength="10" (keypress)="isNumKey($event)">
            <mat-error *ngIf="signupForm.controls.mobile_number.errors?.required">Mobile Number of the Head of Institute
              is
              required
            </mat-error>
            <mat-error *ngIf="signupForm.controls.mobile_number.errors?.minlength">Mobile Number should be of 10 digits
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-label>AICTE Approved Programme</mat-label>
            <mat-select formControlName="aicte_approved_program" required>
              <mat-option value="Yes">Yes</mat-option>
              <mat-option value="No">No</mat-option>
            </mat-select>
            <mat-error *ngIf="signupForm.controls.aicte_approved_program.errors?.required">AICTE
              Approved Programme is
              required
            </mat-error>
          </mat-form-field>
        </div> -->
        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-label>Approval Type</mat-label>
            <mat-select formControlName="approval_type" required (selectionChange)="getSelectedApprovalType()">
              <mat-option  *ngFor="let approvalType of approvalType" value="{{ approvalType?.approvals }}">{{ approvalType?.approvals }}</mat-option>
              <!-- <mat-option value="No">No</mat-option> -->
            </mat-select>
            <mat-error *ngIf="signupForm.controls.approval_type.errors?.required">Approval Type is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group" *ngIf="uploadDiv">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">person</mat-icon>
            <mat-label>Institute Permanent ID/ Application Number</mat-label>
            <input matInput placeholder="Institute Permanent ID" formControlName="pid">
            <mat-error *ngIf="signupForm.controls.pid.errors?.required">Institute Permanent ID is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <div>
            <mat-form-field class="w-100 ml-3" appearance="outline">
              <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">stars</mat-icon>
              <mat-label>Enter AISHE Code <span *ngIf="!signupForm.controls.aishe_code.errors?.required">(Optional)</span></mat-label>
              <input matInput placeholder="Enter AISHE Code" formControlName="aishe_code" #aishe id="aishe_code1" [required]="isConditionIsTrue">
              <mat-error *ngIf="signupForm.controls.aishe_code.errors?.required">AISHE Code is required
              </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" (click)="getAisheDetails(aishe.value)" type="button"
              class="getInstiDetails">Get Institute Details</button>
            <a href="http://aishe.gov.in/aishe/aisheCode" target="_blank" class="getInstiDetailsAISHE">Know Your AISHE
              Code</a>
          </div>

        </div>



        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">account_balance</mat-icon>
            <mat-label>Name of Institute</mat-label>
            <input matInput placeholder="Name of Institute" formControlName="institute_name" [value]="institute_name"
              required>
            <mat-error *ngIf="signupForm.controls.institute_name.errors?.required">Name of Institute is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">confirmation_number</mat-icon>
            <mat-label>City of Institute</mat-label>
            <input matInput placeholder="City of Institute" formControlName="institute_city" [value]="institute_city"
              required (keypress)="isCharKey($event)">
            <mat-error *ngIf="signupForm.controls.institute_city.errors?.required">City of Institute is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">location_on</mat-icon>
            <mat-label>State of Institute</mat-label>
            <input matInput placeholder="State of Institute" formControlName="institute_state" [value]="institute_state"
              required (keypress)="isCharKey($event)">
            <mat-error *ngIf="signupForm.controls.institute_state.errors?.required">State of Institute is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">article</mat-icon>
            <mat-label>Address</mat-label>
            <input matInput placeholder="Enter Address" formControlName="institute_address" [value]="institute_address"
              required>
            <mat-error *ngIf="signupForm.controls.institute_address.errors?.required">Enter Address is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-label>Institute Category</mat-label>
            <mat-select formControlName="institute_type" required>
              <mat-option value="Institute of National Importance & Central Universities / CFTIs">Institute of National Importance & Central Universities / CFTIs</mat-option>
              <mat-option value="University & Deemed to be University (Govt. & Govt. Aided)">University & Deemed to be University (Govt. & Govt. Aided)</mat-option>
              <mat-option value="University & Deemed to be University (Private / Self Financed)">University & Deemed to be University (Private / Self Financed)</mat-option>
              <mat-option value="Colleges / Institutes (Govt. & Govt. Aided)">Colleges / Institutes (Govt. & Govt. Aided)</mat-option>
              <mat-option value="Colleges / Institutes (Private / Self Financed)">Colleges / Institutes (Private / Self Financed)</mat-option>
              <mat-option value="An Affiliating University">An Affiliating University</mat-option>
              <mat-option value="Open University">Open University</mat-option>
            </mat-select>
            <mat-error *ngIf="signupForm.controls.institute_type.errors?.required">
              Institute Category is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-radio-group formControlName="institute_subtype" required aria-label="Type of Courses Offered by the Institute/University:">
            <mat-radio-button value="Technical / Non Technical Both" class="radioButSignUp">Technical / Non Technical : HEI falls under any one of the five sub-categories is offering any technical programs and also non-technical programs from the campus at Diploma/Polytechnic and UG and/or PG and or PhD level</mat-radio-button>
            <mat-radio-button value="Non Technical Only" class="radioButSignUp">Non Technical Only : HEI falls under any one of the five sub-categories is offering only non-technical programs from the campus at UG and/or PG and/or PhD level.</mat-radio-button>
            <mat-error *ngIf="signupForm.controls.institute_subtype.errors?.required">
              Type of Courses Offered by the Institute/University is required
            </mat-error>
          </mat-radio-group>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
            <mat-label>Enter Password</mat-label>
            <input matInput placeholder="Enter Password" formControlName="password" type="password" required>
            <!-- <mat-error>Password must contain one uppercase, one lowercase and minimum length should be 8 characters</mat-error> -->
            <mat-error *ngIf="signupForm.controls.password.errors?.required">Enter Password is required
            </mat-error>
          </mat-form-field>
          <mat-error style="margin-left: 15px;
      ">Password must contain one uppercase, one lowercase,one special characters(@!$#%*%&) and minimum length should be 8 characters</mat-error>
        </div>

        <div class="form-group">
          <mat-form-field class="w-100 ml-3" appearance="outline">
            <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">vpn_key</mat-icon>
            <mat-label>Enter Confirm Password</mat-label>
            <input matInput placeholder="Enter Confirm Password" formControlName="confirm_password" type="password"
              required>
            <mat-error *ngIf="signupForm.controls.confirm_password.errors?.required">Confirm Password is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mb-4" id="captcha-box">
          <re-captcha (resolved)="resolved($event)" siteKey="6Lc0dcoZAAAAAGVDDIpIcXdxnFmpl_8iCDhv56hT">
          </re-captcha>
        </div>

        <div class="col-md-6">
          <button class="login100-form-btnSignUp" mat-raised-button type="submit"
            [disabled]="signupForm.invalid">Submit</button>
        </div>
        <div class="col-md-6" style="margin-top: 2%;">
          <a [routerLink]="['/login']" routerLinkActive="router-link-active" style="float:right;color:#1a73e8;">Sign in instead</a>
        </div>

      </form>
    </div>
  </div>
</div>
