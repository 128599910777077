<div class="container">
    <div class="row" color="primary">
        <h2 class="ss"
            style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px; margin: 0 0 20px 0;">
            List of Verifier</h2>
    </div>
<!--
	<div class="card">
        <div class="card-title"><p>Details of e-Learning Sessions Organized.</p></div>
	</div> -->
<div class="refrredBox">
 <div class="row rws-listpage">
	<div class="col-md-12">
  <p><a class="btn btn-primary referralBut" routerLink='/institute/evaluator-add/add'>Add New Verifier</a></p>

  <div class="tableBody">
	<div class="table-responsive">
  <table class="table table-hover">
    <thead class="facultyTable">
        <tr>
            <th scope="col">S.No.</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Contact</th>
            <th scope="col">Verifier Type</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let nomination of iicevaluator; index as i" >
            <td>{{i+1}}</td>
            <td>{{nomination?.first_name}}</td>
            <td>{{nomination?.email}}</td>
            <td *ngIf="nomination?.status=='1'">{{nomination?.contact}}</td>
            <td *ngIf="nomination?.status!='1'"></td>
            <td >{{nomination?.userType}}</td>
            <td *ngIf="nomination?.status=='1'" class="alert alert-success">Active</td>
            <td *ngIf="nomination?.status=='2'" class="alert alert-primary">Pending</td>
            <td *ngIf="nomination?.status=='0'" class="alert alert-danger">Blocked</td>
            <td>
                <div *ngIf="nomination?.status=='2'">
                    <!-- <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit"> -->
                    <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Edit')"   color="warn" class="btn-tbl-edit-sq">
                      <mat-icon>edit</mat-icon>
                       Edit
                      </button>
                </div>
                <div *ngIf="nomination?.status=='2'">
                    <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Delete')"   color="warn" class="btn-tbl-delete-sq">
                      <mat-icon>delete</mat-icon>
                      Remove
                      </button>
                </div>
                <div *ngIf="nomination?.status=='2'">
                    <button mat-raised-button (click)="onClickEvent(nomination?.id, 'Email')"   color="warn" class="btn-tbl-mail-sq">
                      <mat-icon> email</mat-icon>
                       Send Email
                      </button>
                </div>
            </td>
        </tr>
</table>
</div>
</div>



 </div>
</div>
</div>
</div>
