<div class="col-12">
  <div class="alert alalert alert-info">
    Guidelines : <a href="https://yukti.mic.gov.in/assets/assets/images/innovation-dowloads/Submission-Guideline-YUKTI-NIR-2022.pdf" target="_blank">Application Submission - FAQ</a> | <a href="https://yukti.mic.gov.in/assets/assets/images/innovation-dowloads/YUKTI-NIR-Buuiding-Guideline-YUKTI-NIR-2022.pdf" target="_blank">YUKTI National Innovaiton Repository - FAQ</a> | <a href="https://www.youtube.com/watch?v=G2iX9O525Ik" target="_blank">YUKTI-NIR Orientation Session</a>
  </div>
</div>

<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Registration Details">
        <app-institute-yukti-registrations></app-institute-yukti-registrations>
    </mat-tab>

    <mat-tab label="Idea / PoC Repository">
        <app-institute-idea-submission-21></app-institute-idea-submission-21>
    </mat-tab>
    <!-- <mat-tab label="POC Submission">
        <app-institute-poc-submission-21></app-institute-poc-submission-21>
    </mat-tab> -->

     <mat-tab label="Innovation / Prototype Repository">
        <app-institute-prototype-submission-21></app-institute-prototype-submission-21>
    </mat-tab>
    <!--<mat-tab label="Nominations">
        <app-institute-nomination></app-institute-nomination>
    </mat-tab>
    <mat-tab label="Mentoring Session">
        <app-institute-mentoring-session></app-institute-mentoring-session>
    </mat-tab>-->
    <mat-tab label="Business Model / Startup">
      <app-institute-stage-i-evaluation></app-institute-stage-i-evaluation>
    </mat-tab>
    <mat-tab label="Add / Manage Verifier">
        <app-evaluator></app-evaluator>
    </mat-tab>

</mat-tab-group>

