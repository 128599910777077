<mat-card class="mat-elevation-z6 m-4 instiNominiCard" style="box-shadow: none !important;">

  <div class="row m-4 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">

    <div class="col">
      <div class="instIdeaTotalCount">
          <p>Total Individuals Registered:</p>
          <span>{{counts?.totalRegistration}}</span>
      </div>
  </div>

      <div class="col">
        <div class="instIdeaTotalCount">
            <p>Total Startups Submitted:</p>
            <!-- <span>-</span> -->
            <span>{{counts?.pending_count}}</span>
        </div>
    </div>
    <div class="col">
      <div class="instIdeaTotalCount">
          <p>Total Startups Assigned:</p>
          <span>{{counts?.assigned_count}}</span>
      </div>
  </div>
  <div class="col">
      <div class="instIdeaTotalCount">
          <p>Total Startups Verified:</p>
          <span>{{counts?.verified_count}}</span>
      </div>
  </div>
  <div class="col">
      <div class="instIdeaTotalCount">
          <p>Total Startups Recommended:</p>
          <span>{{counts?.approved_count}}</span>
      </div>
  </div>
    </div>

    <!--<div class="row">
            <div class="col-8 mt-2" style="text-align: center;">
                <h2>Nominate Best 5 Prototype</h2>
            </div>
            <div class="col-2" style="text-align: end;">
                <div *ngIf="!firstPage">
                    <button type="button" class="mr-4" (click)="previousPage()" class="btn btn-success">
                        Previous
                    </button>
                </div>
            </div>
            <div class="col-2">
                <div *ngIf="!lastPage">
                    <button type="button" class="ml-4" (click)="nextPage()" class="btn btn-success">
                        Next
                    </button>
                </div>
            </div>
        </div>-->
<div class="container">
  <div class="ro">
    <div class="col-md-12">
      <div class="form-group" style="box-shadow:0 0px 10px rgb(0 0 0 / 20%) !important">
        <div class="mt-4 table-responsive">
          <table class="table table-hover ideaTableHover table-bordered">
            <thead class="facultyTable">
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Startup ID</th>
                <th scope="col">Date of Submission</th>
                <th scope="col">Startup Title</th>
                <th scope="col">Startup Registration Type</th>
                <th scope="col">GST/CIN No</th>
                <th scope="col">FY of Registration and Development</th>
                <th scope="col">Key Innovation Name</th>
                <!-- <th scope="col">FY of Development</th> -->
                <th scope="col">Developed as part of</th>
                <!-- <th scope="col">TRL Stage</th> -->
                <th scope="col">Team Lead Details</th>
                <!-- <th scope="col">Team Lead Type</th> -->
                <th scope="col">View Startup Details</th>
                <th scope="col">Verification</th>
                <th scope="col">Avg. Score</th>
                <th scope="col">Status</th>
                <th scope="col">Nominate</th>
                <!-- <th scope="col">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let idea of prototypeSubmission; let i = index">
                <td>{{ i+1 }}</td>
                <td>{{ idea?.team_id }}</td>
                <td>{{ idea?.created_date }}</td>
                <td>{{ idea?.idea_title }}<br><b>TRL Title - {{ idea?.trl_level }}</b></td>
                <td>{{ idea?.startup_registered_as }}</td>
                <td>
                  <p class="tbl-txt" *ngIf="idea?.startup_registered_as=='SME Registered Unit (Valid GST No.)'">{{ idea?.sme_reg_unit }}</p>

                  <p *ngIf="idea?.startup_registered_as!='SME Registered Unit (Valid GST No.)'">{{ idea?.cin }}</p>
                </td>
                <td>{{ idea?.year_of_establishment }}<br>{{ idea?.academic_year }}</td>
                <td>{{ idea?.idea_title }}</td>
               
                <td>{{ idea?.developed_as_part_of }}</td>
                <!-- <td>{{ idea?.trl_level }}</td> -->

                <td>{{ idea?.team_lead_name }}<br/>
                  {{ idea?.team_lead_email }}<br/>
                  {{ idea?.team_lead_phone }}<br/>
                  {{ idea?.user_type }}<br>
                  {{ idea?.enrollment_id }}
                  
                  <!-- <br>
                  <button type="button" (click)="openEvaluationDialogTeamData(idea)" class="btn btn-success viewBtn">
                    View & Verify ID
                  </button> -->
                </td>
                <!-- <td>{{ idea?.user_type }}<br>
                  {{ idea?.enrollment_id }}<br>
                  <a class="viewidinfo" href="{{ idea?.enrollment_copy }}" target="_blank" title="">View ID</a>
                </td> -->

                <td>
                  <button type="button" mat-raised-button color="primary" (click)="openFileToDownload(idea?.idea_id_enc)" class="btn btn-success btnDownSuccess" >
                    View Details
                  </button>
                </td>
                <td>

                  <p *ngIf="!idea?.eval_count"><button type="button" (click)="openEvaluationDialog(idea)" mat-raised-button color="primary" class="btn btn-success irAssign">
                    Assign - 0
                  </button></p>

                  <p *ngIf="idea?.eval_count>0 && idea?.eval_count<=2"><button type="button" (click)="openEvaluationDialog(idea)" mat-raised-button color="primary" class="btn btn-success irAssign irassignblue">
                    Assigned - {{idea?.eval_count}}
                  </button></p>

                  <p  *ngIf="idea?.eval_count>2"><button type="button" mat-raised-button color="primary" class="btn btn-success irAssign irassignblue">
                    Assigned - {{idea?.eval_count}}
                  </button></p>

                  <p *ngIf="idea?.eval_count>0">
                    <button type="button" (click)="openEvaluationViewDialog(idea)" class="btn btn-success verify-btn">
                      Verification Details
                    </button>
                </p>

                  <!-- Code to Display the Evaluation Data  -->

                  <!-- Modal -->
                  <div class="modal fade" id="exampleModal_i" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title"  id="exampleModalLabel">Verification Details</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">

                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Score</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let eval of idea?.Eval; let j = index">
                                <th scope="row">{{j+1}}</th>
                                <td>{{eval.first_name}} {{eval.first_lastname}}</td>
                                <td>{{eval.totalScore}}</td>
                                <td>{{eval.recommend_status}}</td>
                              </tr>
                            </tbody>
                          </table>

                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>




              </td>

              <td>
                {{idea?.totalScoreAvg}}
              </td>
              <td>
                {{idea?.mRecommendStatus}}
              </td>
              <td>
                <form [formGroup]="startUpNomantion" (ngSubmit)="onSubmit(startUpNomantion.value)">
                  <input [hidden]="true" [ngModel]="idea?.id" formControlName="innovation_id">
                  <mat-form-field class="instiIdeaSelectBox">
                    <mat-label>Nominate</mat-label>
                    <mat-select formControlName="challenge_id">
                      <mat-option *ngFor="let value of yukti_challenges" [value]="value.id">{{ value.title }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button type="submit" class="btn btn-primary">Nominate</button>
                </form>
              </td>

                <!-- <td>
                  <p *ngIf="!idea?.eval_count"><button type="button" (click)="DeleteIdeaFromVerification(idea?.idea_id_enc,idea?.idea_title)" mat-raised-button color="primary" class="btn btn-success irAssign irDeleteBtn">
                    Delete
                  </button></p>

                  <p><mat-form-field class="instiIdeaSelectBox">
                    <mat-label>Action</mat-label>
                    <mat-select>
                      <mat-option value="One-to-one Mentoring">One-to-one Mentoring</mat-option>
                      <mat-option value="Link with an Incubator">Link with an Incubator</mat-option>
                      <mat-option value="Connect with Angel/VC Investor">Connect with Angel/VC Investor</mat-option>
                      <mat-option value="Nominate for a Challange">Nominate for a Challange</mat-option>
                      <mat-option value="Technology Transfer">Technology Transfer</mat-option>
                    </mat-select>
                  </mat-form-field></p>
                </td> -->

              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
                <div class="col-md-12">
                    <mat-paginator #paginator
                                    showFirstLastButtons
                                    (page)="nextPage($event)"
                                    [length]="total_pages"
                                    [pageIndex]="frontCurrentPageNo"
                                    [pageSizeOptions]="pageSizeOptions" class="NewMatPagination">
                                </mat-paginator>

                </div>
            </div>
      </div>
    </div>
  </div>
</div>


    <!-- <div *ngIf="nominationArray.length <= 0">
            <h3 style="text-align: center">prototype for nomination closed</h3>
        </div> -->
</mat-card>

<!-- <mat-card class="mat-elevation-z6 m-4 instiNominiCard">

        <div class="row" color="primary">
            <h2 class="ss" style="text-align: center;background-color: #f08b04;margin: 0;padding: 10px 0;color: #fff;border-radius: 4px;"> List of Prototype Nominated </h2>
        </div>

        <div class="form-group">
            <div class="m-4">
                <table class="table table-hover">
                    <thead class="facultyTable">
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Theme</th>
                            <th scope="col">Lead Name</th>
                            <th scope="col">Withdraw Nomination</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let nomination of acceptedNomination; let i = index">
                            <td>{{nomination?.idea_title}}</td>
                            <td>{{nomination?.theamName}}</td>
                            <td>{{nomination?.team_lead_name}}</td>
                            <td>

                              <button mat-raised-button color="primary"
                              class="btn btn-success btnDownSuccess" disabled>Withdraw</button>
                </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </mat-card> -->
