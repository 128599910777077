import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InstituteActivityService } from '../../../../../Services/institute-activity.service';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JarwisService } from 'src/app/Services/jarwis.service';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-collaboration-add',
  templateUrl: './collaboration-add.component.html',
  styleUrls: ['./collaboration-add.component.css']
})
export class CollaborationAddComponent implements OnInit {

  public iicImpactQuestions: FormGroup;

  baseUrl = myGlobals.baseUrl1;
  
  totalcount = 0;
  ipr_document: FileList | null;
  document:any;
  requireField:any;
  programTheme:any;
  iicActivity:any;
  rid:any;
  activity_id:any;

  minDate = "2018-01-01";
  maxDate = "2025-03-31";

  constructor(private jarwis: JarwisService, private fb: FormBuilder, private route: ActivatedRoute, private instituteMaster: InstituteInnovationContestService, private instituteActivityService :InstituteActivityService,private toastr: ToastrService)
  {
    this.rid = "add";
    this.route.params.subscribe((params) => {
      this.rid = params.rid;
    });
  }

  ngOnInit(): void {

    this.iicImpactQuestions = this.fb.group({
      'agency_name': [null, Validators.required],
      'dateofmou':[null, Validators.required,],
      'agency_type': [null, Validators.required],
      'period_of_collaboration': [null, Validators.required],
      'collaboration_status': [null, Validators.required]
    });

    this.jarwis.dataCollaborationdata(this.rid).subscribe(
      (data) => this.handleResponse(data)
    );    
  }

  handleResponseForAutoPopulate(data) {

    this.programTheme = "";

  }

  changeDate(values) {
    var value = '';
    value = value + new Date(values).getFullYear().toString();
    value = value + '-' + (new Date(values).getMonth() + 1);
    value = value + '-' + new Date(values).getDate().toString();

    values = value;

    return values;
  }


  handleResponse(data) {
  
    if(data['totalcount'] > 0){
    this.iicImpactQuestions.patchValue({
      agency_name: data['name'],
      dateofmou: data['date_of_mou'],
      agency_type: data['type'],
      period_of_collaboration: data['period'],
      collaboration_status: data['status']
    });

    this.document = data['file'];

  }
    this.totalcount = data['totalcount'];
    this.document = '';
  }

  handleResponseList(data) {
    this.iicActivity = data['data'];
    this.programTheme = data['theme'];
  }

  onSubmit(data) {

    var formData = new FormData();

    formData.append('rid', this.rid);
    formData.append('agency_name', this.iicImpactQuestions.get('agency_name').value);
    formData.append('agency_type', this.iicImpactQuestions.get('agency_type').value);
    formData.append('period_of_collaboration', this.changeDate(this.iicImpactQuestions.get('period_of_collaboration').value));
    formData.append('dateofmou', this.changeDate(this.iicImpactQuestions.get('dateofmou').value));
    formData.append('collaboration_status', this.iicImpactQuestions.get('collaboration_status').value);
    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    var cvFile = this.ipr_document && this.ipr_document.length ? this.ipr_document[0] : null;

    if(this.totalcount>0){

      formData.append('document', cvFile);
      formData.append('document_old', this.document);

    }
    else
    {
      if(this.ipr_document==null && this.ipr_document==undefined) { alert("Please upload the document."); return false; }
      formData.append('document', cvFile);
    }

    this.jarwis.collaborationdata1(formData).subscribe(
      data => this.handleResponseData(data));

  }

  handleResponseData(data){
    if(data['status']=="1"){
      alert(data['msg']);
      window.location.href='/institute/collaboration';
    }
    else{
      this.toastr.error(data['msg']);

    }
  }

}
