import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-institute-yic2021',
  templateUrl: './institute-yic2021.component.html',
  styleUrls: ['./institute-yic2021.component.css']
})
export class InstituteYic2021Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
