import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { InstituteInnovationContestService } from 'src/app/Services/institute-innovation-contest.service';
import * as myGlobals from 'src/app/app.component';

@Component({
  selector: 'app-institute-team-lead-verify-dialog',
  templateUrl: './institute-team-lead-verify-dialog.component.html',
  styleUrls: ['./institute-team-lead-verify-dialog.component.css']
})
export class InstituteTeamLeadVerifyDialogComponent implements OnInit {

  public ideaForm: FormGroup;
  baseUrl = myGlobals.baseUrl1;
  pocScore: any;
  commentFlag: boolean = false;
  ideaScoreFlag: boolean = false;
  evalList:any;
  aCount:any;
  aCountRemain:any;
  dataShow:any;

  constructor(private fb: FormBuilder,
    private innovationService: InstituteInnovationContestService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<InstituteTeamLeadVerifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { this.dataShow=data; }


  ngOnInit(): void {
    this.ideaForm = this.fb.group({
      'verify_status': [null,Validators.required],
      'remarks': [],
    });
    this.innovationService.getEvaluatorsList(this.data?.id).subscribe(
      data => this.handleResponse(data)
    );

  }

  onSubmit(values) {

    values['team_lead_email'] = this.data?.team_lead_email;

    this.innovationService.submitYuktiUserList(values).subscribe(
      data => this.handleResponse2(data)
    );

  }

  handleResponse(data) {

    this.evalList = data['data'];

    this.aCount = data['aCount'];

    this.aCountRemain = data['aCountRemain'];

    // if (data['status'] == '1') {
    //   this.toastr.success(data['msg']);
    //   this.dialogRef.close();
    // } else {
    //   this.toastr.error(data['msg']);
    // }

  }

  handleResponse2(data) {

    if (data['status'] == '1') {
      this.toastr.success("Status has been updated successfully.");
      this.dialogRef.close();
    } else {
      this.toastr.error("Unable to process");
    }


  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode >= 48 && charCode <= 57) || charCode == 46) return true;

    return false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}